import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { Container } from "react-bootstrap";
import axios from "axios";

export default function Compposisi({ title }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [modalShow, setModalShow] = React.useState(false);
  const [posisi, setPosisi] = useState("");
  const [loading, setLoading] = useState(false);
  const [sukses, setSukses] = useState(false);
  const [gagal, setGagal] = useState(false);

  const addPosisi = async () => {
    await axios
      .post(urlapi + "Cekposisi", {
        id_auth: localStorage.getItem("id"),
        posisi: posisi,
      })
      .then((response) => {
        if (response.data.status == "berhasil") {
          setLoading(true);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <div>
      <Button
        variant="primary"
        className="btn w-100 rounded-pill my-4"
        style={{ backgroundColor: "#2b2e5a", color: "white" }}
        onClick={() => setModalShow(true)}
      >
        {title}
      </Button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Atur posisi anda
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className={loading == false ? "" : "d-none"}>
              <div className="row">
                <div className="col-sm-6 col-6">
                  <div className="form-check">
                    <input
                      onClick={() => setPosisi("Penyerang")}
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      Penyerang
                    </label>
                  </div>
                  <hr />
                </div>

                <div className="col-sm-6 col-6">
                  <div className="form-check">
                    <input
                      onClick={() => setPosisi("Gelandang serang")}
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      Gelandang serang
                    </label>
                  </div>
                  <hr />
                </div>

                <div className="col-sm-6 col-6">
                  <div className="form-check">
                    <input
                      onClick={() => setPosisi("Gelandang sayap")}
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      Gelandang sayap
                    </label>
                  </div>
                  <hr />
                </div>

                <div className="col-sm-6 col-6">
                  <div className="form-check">
                    <input
                      onClick={() => setPosisi("Gelandang tengah")}
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      Gelandang tengah
                    </label>
                  </div>
                  <hr />
                </div>

                <div className="col-sm-6 col-6">
                  <div className="form-check">
                    <input
                      onClick={() => setPosisi("Sayap")}
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      Sayap
                    </label>
                  </div>
                  <hr />
                </div>

                <div className="col-sm-6 col-6">
                  <div className="form-check">
                    <input
                      onClick={() => setPosisi("Bek sayap")}
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      Bek sayap
                    </label>
                  </div>
                  <hr />
                </div>

                <div className="col-sm-6 col-6">
                  <div className="form-check">
                    <input
                      onClick={() => setPosisi("Bek tangah")}
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      Bek tengah
                    </label>
                  </div>
                  <hr />
                </div>

                <div className="col-sm-6 col-6">
                  <div className="form-check">
                    <input
                      onClick={() => setPosisi("Penjaga gawang")}
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      Penjaga gawang
                    </label>
                  </div>
                  <hr />
                </div>
                <button
                  onClick={() => addPosisi()}
                  className={
                    posisi == ""
                      ? "btn w-100 rounded-pill my-4 disabled"
                      : "btn w-100 rounded-pill my-4"
                  }
                  style={{ backgroundColor: "#2b2e5a", color: "white" }}
                >
                  Submit
                </button>
              </div>
            </div>

            <div className={loading == true ? "" : "d-none"}>
              <center>
                <img
                  src="/sukses.png"
                  className="img-fluid"
                  alt=""
                  style={{ height: "100px" }}
                ></img>
                <p className="mt-2 text`-success">
                  {" "}
                  Hello {localStorage.getItem("nama")}, Posisi bermain anda
                  berhasil di update{" "}
                </p>
                <a
                  href={"/memberkarirbaru"}
                  className="btn mt-1"
                  style={{
                    backgroundColor: "#2b2e5a",
                    color: "white",
                  }}
                >
                  Tutup popup
                </a>
              </center>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}
