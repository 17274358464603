import React from "react";
import Navbar from "../componenst/Navbar";
import Compbookingrankteamall from "../componenst/Membarkarirteam/Compbookingrankteamall";

export default function Bookingrankteamanda() {
  return (
    <div>
      <Navbar judul="Booking rank team anda" />
      <Compbookingrankteamall />
    </div>
  );
}
