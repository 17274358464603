import axios from "axios";
import React, { useEffect, useState } from "react";
import Compnama from "./Compnama";

export default function Comppoint() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [point, setPoint] = useState([]);
  const [page, setPage] = useState(true);
  const [jml, setJml] = useState(10);

  const getdata = async () => {
    try {
      const response = await axios.get(urlapi + "Point_memberkarir");
      setPoint(response.data);
    } catch (error) {}
  };

  const handleShow = (status) => {
    if (status == false) {
      setPage(false);
      setJml(5000);
    } else {
      setPage(true);
      setJml(10);
    }
  };

  useEffect(() => {
    getdata();
  }, []);
  return (
    <div>
      <div>
        <hr />
        {page == true ? (
          <>
            {" "}
            <small className="text-secondary">Posisi ranking 10 besar</small>
          </>
        ) : (
          <small className="text-secondary">Posisi ranking rank solo</small>
        )}

        <div className="table-responsive">
          <table class="table table-striped" style={{ fontSize: "12px" }}>
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Nama</th>
                <th className="text-warning" scope="col">
                  P
                </th>
                <th className="text-success" scope="col">
                  W
                </th>
                <th className="text-info" scope="col">
                  D
                </th>
                <th className="text-danger" scope="col">
                  L
                </th>
                <th className="text-primary" scope="col">
                  Pts
                </th>
              </tr>
            </thead>
            <tbody>
              {point.slice(0, 500).map((data, index) => {
                return (
                  <tr
                    key={index}
                    className={
                      localStorage.getItem("id") == data.id_user
                        ? "table-primary fw-bold"
                        : index + 1 > jml
                        ? "d-none"
                        : ""
                    }
                  >
                    <th scope="row">{index + 1}</th>
                    <td>
                      <Compnama iduser={data.id_user} />
                    </td>
                    {/* <td>{data.persen.substr(0, 5)}%</td> */}
                    <td className="table-warning">{data.play}</td>
                    <td className="table-success">{data.win}</td>
                    <td className="table-info">{data.draw}</td>
                    <td className="table-danger">{data.lost}</td>
                    <td className="table-primary">{data.point}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {page == true ? (
            <>
              {" "}
              <small
                style={{ cursor: "pointer" }}
                onClick={() => handleShow(false)}
              >
                Lihat semuanya <i class="fa-solid fa-angle-right"></i>
              </small>
            </>
          ) : (
            <>
              {" "}
              <small
                className="text-secondary"
                style={{ cursor: "pointer" }}
                onClick={() => handleShow(true)}
              >
                Tutup ranking <i class="fa-solid fa-angle-right"></i>
              </small>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
