import axios from "axios";
import React, { useEffect, useState } from "react";
import Complogoteam from "../Membarkarirteam/Complogoteam";
import { Link } from "react-router-dom";

export default function Compmaindatateam() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [datateam, setDatateam] = useState([]);
  const [pool, setPool] = useState("A");

  const getdata = async (pool) => {
    try {
      const response = await axios.get(
        urlapi + "Datateamrankteam?pool=" + pool
      );
      console.log(response.data);
      setDatateam(response.data);
    } catch (error) {}
  };

  const handlepool = (e) => {
    setPool(e);
  };

  useEffect(() => {
    getdata(pool);
  }, [pool]);

  return (
    <div>
      <br />
      <div className="container mt-5">
        <div className="card border-0" id="bggradient1">
          <div className="card-body">
            <h5 className="text-white fw-bold">Data team rank team</h5>
            <small className="text-white">
              Data team rank team medan mini soccer
            </small>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4 col-4">
            <div
              className={
                pool == "A"
                  ? "card mt-2 border-primary shadow"
                  : "card mt-2 shadow"
              }
              onClick={() => handlepool("A")}
            >
              <div className="card-body">
                <span class="badge bg-dark"> Pool A</span>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-4">
            <div
              className={
                pool == "B"
                  ? "card mt-2 border-primary shadow"
                  : "card mt-2 shadow"
              }
              onClick={() => handlepool("B")}
            >
              <div className="card-body">
                <span class="badge bg-dark"> Pool B</span>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-4">
            <div
              className={
                pool == "All"
                  ? "card mt-2 border-primary shadow"
                  : "card mt-2 shadow"
              }
              onClick={() => handlepool("All")}
            >
              <div className="card-body">
                <span class="badge bg-dark"> Pool All</span>
              </div>
            </div>
          </div>
        </div>

        {/* <img
          src="img/bennerrankteam3.png"
          className="img-fluid mt-2"
          alt="Responsive image"
          style={{ borderRadius: "5px" }}
        ></img> */}
        <div className="card mt-3 mb-3">
          <div className="card-body">
            {datateam.map((data, index) => {
              return (
                <div key={index}>
                  <div className="row">
                    <div className="col-sm-6 col-6">
                      {" "}
                      {/* <small className="fw-bold">{index + 1}</small> */}
                      {"   "}
                      <Complogoteam team={data.nama_team} />
                      <center>
                        <small className="fw-bold">
                          {data.nama_team.toUpperCase()}
                        </small>
                      </center>
                    </div>
                    <div className="col-sm-6 col-6">
                      <div style={{ textAlign: "right" }}>
                        <Link
                          to={"/statistikteam/" + data.nama_team}
                          style={{ textDecoration: "none" }}
                        >
                          <small
                            className="text-secondary fw-bold"
                            style={{ fontSize: "12px" }}
                          >
                            <span class="badge text-bg-success">
                              Lihat Statistik{" "}
                              <i className="fas fa-chart-simple"></i>
                            </span>
                          </small>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <hr />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
