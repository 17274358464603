import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import Comphasil from "./Comphasil";

export default function Compmain() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [game, setGame] = useState([]);
  const [cekgame, setCekgame] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(urlapi + "Game");
      setGame(response.data);
      // console.log(response.data);
    } catch (error) {}
  };

  const cekmainmember = async () => {
    try {
      const response = await axios.get(urlapi + "Mainmemberkarir2");
      setCekgame(response.data);
    } catch (error) {}
  };

  const resvonsive = {
    0: {
      items: 1.5,
    },
    600: {
      items: 1.5,
    },
    1000: {
      items: 1.5,
    },
  };

  useEffect(() => {
    cekmainmember();
    getdata();
  }, []);
  return (
    <div className="mt-5">
      <div className=" container mt-4">
        <br></br>
        <OwlCarousel
          className="owl-theme"
          loop
          margin={4}
          nav={false}
          responsive={resvonsive}
          dotsEach
          autoplay
        >
          <img
            src="/img/bennerbooking.png"
            className="img-fluid"
            alt=""
            style={{ borderRadius: "5px" }}
          ></img>
          <img
            src="/img/dp.png"
            className="img-fluid"
            alt=""
            style={{ borderRadius: "5px" }}
          ></img>
        </OwlCarousel>
      </div>

      <div className="card mt-1" style={{ border: "none" }}>
        <div className="card-body">
          <div
            className="card shadow mb-2 text-white"
            id="bglistpemain"
            style={{ border: "none" }}
          >
            <div className="card-body">
              <label className="fw-bold" style={{ fontSize: "10px" }}>
                Hasil pertandingan rank solo
              </label>
              <p className="text-white" style={{ fontSize: "10px" }}>
                Ayoo tingkatkan main anda sekarang juga
              </p>
            </div>
          </div>

          <div className={cekgame == false ? "card" : "d-none"}>
            <div className="card-body">
              <center>
                <img
                  src="/img/trash.png"
                  className="img-fluid"
                  alt=""
                  style={{
                    borderRadius: "5px",
                    height: "100px",
                  }}
                ></img>
                <h4 className="fw-bold mt-2 text-success">
                  Hello {localStorage.getItem("nama")}
                </h4>
                <label className="text-secondary">
                  Untuk saat ini hasil pertandingna belum tersedia
                </label>
              </center>
            </div>
          </div>

          {cekgame != null ? (
            <>
              {game.map((data, index) => {
                return (
                  <div key={index}>
                    <Comphasil kodemain={data.kode_main} />
                  </div>
                );
              })}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
