import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Compteampemain from "./Compteampemain";

export default function Comptopscore({ pool }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [topscore, setTopscore] = useState([]);
  const [page, setPage] = useState(false);
  const [jml, setJml] = useState(10);

  const getTopscore = async () => {
    try {
      const response = await axios.get(
        urlapi + "Topscorerankteam?pool=" + pool
      );
      //   console.log(response.data);
      setTopscore(response.data);
    } catch (error) {}
  };

  const handleShow = (status) => {
    if (status == false) {
      setPage(false);
      setJml(5000);
    } else {
      setPage(true);
      setJml(10);
    }
  };

  useEffect(() => {
    getTopscore();
  }, [pool]);

  return (
    <div>
      {page == false ? (
        <>
          {" "}
          <small className="text-secondary">Posisi top score 10 besar</small>
        </>
      ) : (
        <small className="text-secondary">Posisi top score rank team</small>
      )}

      <div className="table-responsive">
        <table class="table table-striped" style={{ fontSize: "12px" }}>
          <thead>
            <tr>
              <th
                scope="col"
                className="text-center table-info"
                style={{ borderTopLeftRadius: "10px" }}
              >
                No
              </th>
              <th scope="col" className="fw-bold table-info">
                Nama
              </th>
              <th scope="col" className="fw-bold table-info">
                Team
              </th>
              <th
                scope="col"
                className="text-center table-info"
                style={{ borderTopRightRadius: "10px" }}
              >
                Goal
              </th>
            </tr>
          </thead>
          <tbody>
            {topscore.map((data, index) => {
              return (
                <tr
                  key={index}
                  className={
                    localStorage.getItem("id") == data.id_user
                      ? ""
                      : index + 1 > jml
                      ? "d-none"
                      : ""
                  }
                >
                  <th
                    scope="row"
                    className="text-center text-white"
                    id={index % 2 == 0 ? "genap" : "ganjil"}
                  >
                    {index + 1}
                  </th>
                  <td
                    className="text-white"
                    id={index % 2 == 0 ? "genap" : "ganjil"}
                  >
                    {data.nama.toUpperCase()}
                  </td>
                  <td
                    className="text-white"
                    id={index % 2 == 0 ? "genap" : "ganjil"}
                  >
                    <Compteampemain id_pemain={data.id_pemain} />
                  </td>
                  <td
                    className=" text-center text-white"
                    id={index % 2 == 0 ? "genap" : "ganjil"}
                  >
                    {data.jml}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {page == false ? (
          <>
            {" "}
            <small
              className="text-secondary"
              style={{ cursor: "pointer" }}
              onClick={() => handleShow(true)}
            >
              Lihat semuanya <i class="fa-solid fa-angle-right"></i>
            </small>
          </>
        ) : (
          <>
            {" "}
            <small
              className="text-secondary"
              style={{ cursor: "pointer" }}
              onClick={() => handleShow(false)}
            >
              Tutup ranking <i class="fa-solid fa-angle-right"></i>
            </small>
          </>
        )}
      </div>
    </div>
  );
}
