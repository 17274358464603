import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Compdekstop from "./Compdekstop";
import Compmobile from "./Compmobile";
import axios from "axios";
import Compwinrate from "./Compwinrate";
import Compprofil from "./Compprofil";
import Loadlabel from "../../skeleton/Loadlabel";
import Loadcard from "../../skeleton/Loadcard";
import Comphasilranksolo from "./Comphasilranksolo";
import Compjmlpemain from "./Compjmlpemain";
import Compmatchrankteam from "./Compmatchrankteam";

export default function Compmain() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const { pagerankteam } = useParams();
  const [solo, setSolo] = useState(true);
  const [team, setTeam] = useState(false);
  const [rank, setRank] = useState([]);
  const [rankteam, setRankteam] = useState([]);
  const [game, setGame] = useState([]);
  const [syarat, setSyarat] = useState(false);
  const [gamerankteam, setGamerankteam] = useState([]);
  const [teamrank, setTeamrank] = useState([]);

  const date = new Date();
  let tgl = new Date();
  let format_tgl =
    tgl.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + tgl.getDate()).slice(-2);

  const resvonsive = {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 2,
    },
  };

  const resvonsive2 = {
    0: {
      items: 2.5,
    },
    600: {
      items: 2.5,
    },
    1000: {
      items: 2.5,
    },
  };

  const handleklik = (page) => {
    if (page == "solo") {
      setTeam(false);
      setSolo(true);
    } else if (page == "team") {
      setTeam(true);
      setSolo(false);
    }
  };

  const notify = () =>
    toast.warning("Untuk saat ini fitur belum tersedia !", {
      position: toast.POSITION.TOP_CENTER,
    });

  const getdata = async () => {
    try {
      const response = await axios.get(urlapi + "Point_memberkarir?rank=true");
      setRank(response.data);
    } catch (error) {}
  };

  const cekrankteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Datateam?id_user=" + localStorage.getItem("id")
      );
      // console.log(response.data);
      setRankteam(response.data);
    } catch (error) {}
  };

  const ranksolo = async () => {
    try {
      const response = await axios.get(urlapi + "Game?limit=1");
      setGame(response.data);
    } catch (error) {}
  };

  const getGame = async () => {
    try {
      const response = await axios.get(urlapi + "Fungamerankteam");

      setGamerankteam(response.data);
      // console.log(response.data);
    } catch (error) {
      setGamerankteam("");
    }
  };

  const getteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Datateam?id_user=" + localStorage.getItem("id")
      );
      setTeamrank(response.data);
      // console.log(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getGame();
    getteam();
    if (pagerankteam != null) {
      setTeam(true);
    }
    setTimeout(() => {
      cekrankteam();
      getdata();
      ranksolo();
    }, 500);
  }, []);

  return (
    <div className="mt-5" style={{ height: "800px" }}>
      <div className="card" id="bgrank" style={{ border: "none" }}>
        <div className="card-body">
          <img
            src="./img/bennerbaru.png"
            className="img-fluid"
            style={{ borderRadius: "10px" }}
          ></img>

          <div className="card mt-2 shadow">
            <div className="card-body">
              <h5>Rank League</h5>
              <label style={{ fontSize: "12px" }}>
                Hello {localStorage.getItem("nama")}, anda berada dalam halaman
                Rank League, mainkan sekarang juga
              </label>
            </div>
          </div>
          <div
            className="mt-3 card shadow"
            id="bglistpemain"
            style={{ border: "none" }}
          >
            <div className="card-body text-white">
              <h5 className="fw-bold">Hello {localStorage.getItem("nama")}</h5>
              <label style={{ fontSize: "12px" }}>
                Selamat datang di Rank League, pilih rank league yang anda
                inginkan
              </label>
            </div>
          </div>

          <div className="mt-2">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={4}
              nav={false}
              responsive={resvonsive2}
              dotsEach={false}
              dots={false}
              autoplay={false}
            >
              <div className="card shadow" id="menurank">
                <div className="card-body">
                  <Link
                    to={"/history"}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <div class="fw-bold" style={{ fontSize: "8px" }}>
                      History rank anda <i className="fas fa-angle-right"></i>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="card shadow" id="menurank">
                <div className="card-body">
                  <Link
                    to={"/hasilranksolo"}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <div className="fw-bold" style={{ fontSize: "8px" }}>
                      Hasil rank solo <i className="fas fa-angle-right"></i>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="card shadow" id="menurank">
                <Link
                  to="/hasilrankteam"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <div className="card-body">
                    <div className="fw-bold" style={{ fontSize: "8px" }}>
                      Hasil rank team <i className="fas fa-angle-right"></i>
                    </div>
                  </div>
                </Link>
              </div>
            </OwlCarousel>
          </div>

          {/* <div className="card mt-3 shadow" style={{ border: "none" }}>
            <div className="mx-2 my-2">
              <div className="row ">
                <div className="col-sm-4 col-4">
                  <div className="card shadow" id="menurank">
                    <div className="card-body">
                      <Link
                        to={"/history"}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        <div class="fw-bold" style={{ fontSize: "8px" }}>
                          History rank anda{" "}
                          <i className="fas fa-angle-right"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 col-4">
                  <div className="card shadow" id="menurank">
                    <div className="card-body">
                      <Link
                        to={"/hasilranksolo"}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        <div className="fw-bold" style={{ fontSize: "8px" }}>
                          Hasil rank solo <i className="fas fa-angle-right"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 col-4">
                  <Link to="/hasilrankteam" style={{ textDecoration: "none" }}>
                    <div className="card shadow" id="menurank">
                      <div className="card-body">
                        <div className="fw-bold" style={{ fontSize: "8px" }}>
                          Hasil rank team <i className="fas fa-angle-right"></i>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div> */}

          <div className="row mt-3">
            <div className="col-sm-6 col-6">
              <div
                className={
                  solo == true ? "card shadow border-primary" : "card shadow"
                }
                style={{ cursor: "pointer" }}
                onClick={() => handleklik("solo")}
                id="bglistpemain"
              >
                <div className="card-body">
                  <p className="fw-bold text-white">Rank Solo </p>
                  <h4>
                    <i className="fas fa-user text-white"></i>
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-6">
              <div
                className={
                  team == true ? "card shadow border-primary" : "card shadow"
                }
                style={{ cursor: "pointer" }}
                onClick={() => handleklik("team")}
                id="bglistpemain"
              >
                <div className="card-body">
                  <p className="fw-bold text-white">Rank Team </p>
                  <h4>
                    <i className="fas fa-users text-white"></i>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className={solo == true ? "mt-3" : "d-none"}>
            {game.map((gg, index) => {
              return (
                <div className="card mb-2" key={index}>
                  <div className="card-body">
                    <label>Match Rank Solo</label>
                    <br />
                    <small className="fw-bold text-success">
                      Hello {localStorage.getItem("nama")}, Match rank solo
                      medan mini soccer{" "}
                      {gg.status == "1" ? <>dibuka</> : <>ditutup</>}
                    </small>
                    <br></br>

                    <div className={gg.status == "1" ? "row mt-3" : "d-none"}>
                      <div className="col-sm-6 col-6">
                        <div className="card border-info">
                          <div className="card-body text-info fw-bold">
                            <small> Sisa slot</small>
                            <br />
                            <small style={{ fontSize: "12px" }}>
                              {gg.status == 1 ? (
                                <>
                                  <Compjmlpemain
                                    kode_main={gg.kode_main}
                                    jml={gg.jml_pemain}
                                  />
                                </>
                              ) : (
                                <div>Penuh</div>
                              )}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-6">
                        <div className="card text-info fw-bold border-info">
                          <div className="card-body">
                            <small styele={{ fontSize: "10px" }}>
                              Tanggal main
                            </small>
                            <br />
                            <small style={{ fontSize: "12px" }}>
                              <i className="fas fa-calendar-days"></i>{" "}
                              {gg.tgl_main}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>

                    {gg.status == 1 ? (
                      <>
                        <hr />
                        <Comphasilranksolo kode_main={gg.kode_main} />
                      </>
                    ) : (
                      <> </>
                    )}
                  </div>
                </div>
              );
            })}

            {rank != false ? (
              <div>
                <OwlCarousel
                  className="owl-theme"
                  loop
                  margin={4}
                  nav={false}
                  responsive={resvonsive}
                  dotsEach={false}
                  dots={true}
                  autoplay
                >
                  {rank.slice(0, 5).map((data, index) => {
                    return (
                      <div key={index}>
                        <div
                          class="card shadow"
                          key={index}
                          style={{ border: "3px solid #2b2e5a" }}
                        >
                          <Compprofil id_user={data.id_user} />

                          <div
                            className="card-body"
                            style={{
                              backgroundColor: "#2b2e5a",
                              color: "white",
                            }}
                          >
                            <div>
                              <h5 className="card-title fw-bold">
                                {data.nama.length > 15 ? (
                                  <>
                                    {data.nama.toUpperCase().substring(0, 15)}{" "}
                                    ...
                                  </>
                                ) : (
                                  <> {data.nama.toUpperCase()}</>
                                )}
                                <div></div>
                                <label
                                  style={{
                                    lineHeight: "2px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Position : Player
                                </label>{" "}
                              </h5>
                            </div>

                            <div className="d-flex justify-content-between">
                              <span className="badge bg-warning text-dark fw-bold">
                                Play : {data.play}
                              </span>
                              <span className="badge bg-warning text-dark fw-bold ml-2">
                                Win : {data.win}
                              </span>
                              <span className="badge bg-warning text-dark fw-bold ml-2">
                                Draw : {data.draw}
                              </span>
                              <span className="badge bg-warning text-dark fw-bold  ml-2">
                                Lost : {data.lost}
                              </span>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-sm-6 col-6">
                                <div
                                  className="card"
                                  style={{
                                    borderColor: "",
                                    border: "2px solid  yellow",
                                    backgroundColor: "#2b2e5a",
                                    color: "white",
                                  }}
                                >
                                  <div className="card-body">
                                    <h5>Rank</h5>
                                    <p>
                                      {index + 1} ({data.point} Point)
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6 col-6">
                                <div
                                  className="card"
                                  style={{
                                    borderColor: "",
                                    border: "2px solid yellow",
                                    backgroundColor: "#2b2e5a",
                                    color: "white",
                                  }}
                                >
                                  <div className="card-body">
                                    <h5>Winrate</h5>
                                    <Compwinrate id_user={data.id_user} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
            ) : (
              <>
                <div className="card shadow  mt-2" style={{ border: "none" }}>
                  <div className="card-body">
                    <small className="text-secondary">
                      Main dengan teman barumu di medan mini soccer, Ayooo
                      buruan mainkan rank solo anda sekarang{" "}
                    </small>
                  </div>
                </div>

                <div className="card mt-2" id="bgranksolo">
                  <div className="card-body"></div>
                </div>
              </>
            )}
          </div>

          {solo == true ? (
            <>
              <center>
                <Link
                  to="/memberkarirbaru"
                  className="btn mt-3 w-100  text-white"
                  style={{
                    backgroundColor: "#2b2e5a",
                    borderRadius: "20px",
                  }}
                  onClick={notify}
                >
                  Bermain rank solo sekarang
                </Link>
              </center>
            </>
          ) : (
            <></>
          )}

          <div className={team ? "" : "d-none"}>
            <div className="">
              <small
                className={gamerankteam == false ? "d-none" : "text-secondary"}
              ></small>
              {gamerankteam.map((data, index) => {
                return (
                  <div key={index}>
                    <Compmatchrankteam
                      kode_main={data.kode_main}
                      lapangan={data.lapangan}
                      tglmain={data.tgl_main}
                      jammain={data.jam_main}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <div className={team == true ? "mt-3" : "d-none"}>
            {rankteam != "" || rankteam != false ? (
              <>
                <div className="row">
                  <div className="col-sm-6 col-6">
                    <div className="card my-2 shadow border-0" id="bggradient1">
                      <div className="my-2 mx-3 text-primary d-flex justify-content-between">
                        {" "}
                        <Link
                          to="/pemainteam"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          <div>
                            <small>
                              <i className="fas fa-user"></i> Lihat pemain team
                              anda ?{" "}
                            </small>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-6">
                    <div className="card my-2 shadow border-0" id="bggradient1">
                      <div className="my-2 mx-3 text-white">
                        <Link
                          to="/profilteam"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          <div>
                            <small>
                              <i className="fas fa-shield-halved"></i>
                              {} Lihat profil team anda ?{" "}
                            </small>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card shadow mb-3" style={{ border: "0" }}>
                  <div className="card-body text-secondary">
                    <label
                      class="fw-bold"
                      style={{ color: "#2b2e5a", fontWeight: "bold" }}
                    >
                      <i className="fas fa-shield-halved"></i> Hello team{" "}
                      {teamrank.nama_team}
                    </label>
                    <br />
                    <div className="text-success">
                      Team anda sudah terdaftar silahkan bermain rank team dan
                      temukan lawan bermain team anda
                    </div>
                  </div>
                </div>
                {/* <div className="card shadow border-success mt-3">
                  <div className="card-body text-success">
                    <label class="fw-bold">
                      Hello {localStorage.getItem("nama")}
                    </label>
                    <br />
                    Rank team medan mini soccer sudah dibuka, buruan daftar
                    sekarang juga
                  </div>
                </div> */}
                <div></div>

                {/* <div className="row">
                  <div className="col-sm-6 col-6">
                    <div className="card my-2 shadow">
                      <div className="my-2 mx-3 text-primary d-flex justify-content-between">
                        {" "}
                        <Link
                          to="/pemainteam"
                          style={{ textDecoration: "none" }}
                        >
                          <div>Lihat pemain team anda ? </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-6">
                    <div className="card my-2 shadow">
                      <div className="my-2 mx-3 text-primary d-flex justify-content-between">
                        {" "}
                        <Link
                          to="/pemainteam"
                          style={{ textDecoration: "none" }}
                        >
                          <div>Lihat pemain team anda ? </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div> */}

                <img
                  src="img/posterrankteam.png"
                  className="img-fluid"
                  alt="..."
                  style={{ borderRadius: "5px" }}
                ></img>

                <Link
                  to="/memberkarirteam"
                  className="btn mt-3 w-100 mb-2  text-white"
                  style={{
                    backgroundColor: "#2b2e5a",
                    borderRadius: "20px",
                  }}
                >
                  Bermain rank team sekarang
                </Link>
              </>
            ) : (
              <>
                <div className="card shadow" style={{ border: "none" }}>
                  <div className="card-body">
                    <label class="fw-bold">
                      Hello {localStorage.getItem("nama")}
                    </label>
                    <br />
                    <div className="text-secondary">
                      Anda belum mempunyai team, daftarkan team anda untuk
                      bermain rank team
                    </div>
                  </div>
                </div>
                <div className="card shadow mt-2" style={{ border: "none" }}>
                  <div className="card-body">
                    <label class="fw-bold">Rank team medan mini soccer</label>
                    <br />
                    <div className="text-secondary">
                      Daftarkan team anda untuk bermain secara acak dengan team
                      lawan
                    </div>
                  </div>
                </div>
                <div className="mt-3 text-secondary">
                  <div className="card mt-3 shadow" style={{ border: "none" }}>
                    <div className="card-body">
                      <div
                        className="d-flex justify-content-between"
                        onClick={() => setSyarat(!syarat)}
                      >
                        <p className="fw-bold text-secon">Syarat rank team</p>
                        <i
                          className={
                            syarat == true
                              ? "fas fa-angle-down"
                              : "fas fa-angle-right"
                          }
                        ></i>
                      </div>

                      <div class={syarat == true ? "text-secondary" : "d-none"}>
                        Untuk mendaftarkan team anda di fitur rank team, team
                        anda harus mempunyai syarat den ketentuan.
                      </div>
                      <div className={syarat == true ? "" : "d-none"}>
                        <div className="d-flex justify-content-between mt-3">
                          <small className="text-secondary">
                            <i class="fas fa-shield"></i> Logo team
                          </small>
                          <small className="text-secondary">
                            {" "}
                            <i class="fas fa-shirt"></i> Jersy team
                          </small>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                          <small className="text-secondary">
                            <i class="fas fa-users"></i> Pemain team
                          </small>
                          <small className="text-secondary">
                            {" "}
                            <i class="fas fa-user"></i> Official
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Link
                    to="/daftarteamrankteam"
                    className="btn mt-3 w-100 mb-2  text-white"
                    style={{
                      backgroundColor: "#2b2e5a",
                      borderRadius: "20px",
                    }}
                  >
                    Daftarkan team anda sekarang
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
