import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Compdatapemainrankteam({ team, lawan, kode }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [datateam, setDatateam] = useState([]);
  const [datalawan, setDatalawan] = useState([]);
  const [video, setVideo] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi + "Listpemainrankteam?team=" + team + "&&lawan=" + lawan
      );
      setDatateam(response.data.team);
      setDatalawan(response.data.lawan);
    } catch (error) {}
  };

  const getvideo = async () => {
    try {
      const response = await axios.get(urlapi + "Videorankteam?kode=" + kode);
      // console.log(response.data);
      setVideo(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getdata();
    getvideo();
  }, []);

  return (
    <div className="mt-5">
      <img
        src="/img/mms1.png"
        className="img-fluid"
        alt="Responsive image"
      ></img>

      <div className="my-2">
        <div className="container">
          {/* <div className="card shadow" style={{ border: "none" }}>
            <div className="card-body">
              <h5>Halaman Pemain</h5>
              <small>Halaman pemain rank team medan mini soccer</small>
            </div>
          </div> */}

          <div className="card my-3 shadow" style={{ border: "none" }}>
            <div className="card-body">
              <p className="fw-bold text-secondary">
                <i className="fas fa-video"></i> Video match rank team
              </p>
              {/* <small className="text-secondary">
                Untuk saat ini hasil video pertandingan belum tersedia
              </small> */}
              {video == null || video == "" ? (
                <>
                  <small>
                    {" "}
                    Untuk saat ini hasil video pertandingan belum tersedia
                  </small>
                </>
              ) : (
                <>
                  <div class="ratio ratio-16x9">
                    <iframe
                      className="embed-responsive-item"
                      src={"https://www.youtube.com/embed/" + video.video}
                    ></iframe>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6 col-6">
                  <div className="fw-bold">
                    <small style={{ fontSize: "12px" }}>
                      <i className="fas fa-futbol"></i> {team.toUpperCase()}
                    </small>
                  </div>
                  {}
                  <hr />
                  {datateam.map((data, index) => {
                    return (
                      <div key={index}>
                        <div className="mb-2">
                          <small className="text-secondary">
                            <i className="fas fa-user"></i> {data.nama}
                          </small>

                          <div>
                            <small
                              className="text-danger"
                              style={{ fontSize: "10px" }}
                            >
                              {data.posisi}
                            </small>
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })}
                </div>
                <div className="col-sm-6 col-6">
                  <div className="fw-bold">
                    <small style={{ fontSize: "12px" }}>
                      <i className="fas fa-futbol"></i> {lawan.toUpperCase()}
                    </small>
                  </div>

                  <hr />
                  {datalawan.map((data, index) => {
                    return (
                      <div key={index}>
                        <div className="mb-2">
                          <small className="text-secondary">
                            <i className="fas fa-user"></i> {data.nama}
                          </small>

                          <div>
                            <small
                              className="text-danger"
                              style={{ fontSize: "10px" }}
                            >
                              {data.posisi}
                            </small>
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
