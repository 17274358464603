import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Compnama from "../Memberkarir/Compnama";

export default function Compassist({ kode_main, team }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [assist, setAssist] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi + "Assistranksolo?kode_main=" + kode_main + "&&team=" + team
      );
      setAssist(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getdata();
  }, []);
  return (
    <div className="my-1">
      {assist.map((data, index) => {
        return (
          <div
            key={index}
            className="text-warning "
            style={{ fontSize: "5px" }}
          >
            {data.id_user == "" ? (
              <>({data.menit})</>
            ) : (
              <>
                <Compnama iduser={data.id_user} /> ({data.menit})
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}
