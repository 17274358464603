import React from "react";
import Navbar from "../componenst/Navbar";
import Comppemain from "../componenst/History/Comppemain";
import { useParams } from "react-router-dom";

export default function Pemainmemberkariranda() {
  const { kodemain, team, lawan } = useParams();
  return (
    <div>
      <Navbar judul="Peserta" aicon="true" />
      <Comppemain kodemain2={kodemain} team2={team} lawan2={lawan} />
    </div>
  );
}
