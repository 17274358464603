import React from "react";
import Navbar from "../componenst/Navbar";
import Comphistoryanda from "../componenst/Membarkarirteam/Comphistoryanda";

export default function Historyrankteam() {
  return (
    <div>
      <Navbar judul="History anda" aicon="true" />
      <Comphistoryanda />
    </div>
  );
}
