import axios from 'axios';
import React, { useEffect, useState } from 'react'

export default function Bookingvoucher({ kode_voucher }) {
    const urlapi = process.env.REACT_APP_BASE_URL;
    const [booking, setBooking] = useState([]);
    const [page, setPage] = useState(false);
    
    const bookingvoucher = async () => {
        try {
            const response = await axios.get(urlapi + "Bookingvoucher?kode=" + kode_voucher);
            // console.log(response.data);
            setBooking(response.data);
            if (response.data.voucher != null || response.data != false) {
                setPage(true);
            } else {
                setPage(false)
            }
        } catch (error) {
            console.log(error.message);
            setPage(false);
        }
    }

    useEffect(() => {
        bookingvoucher();
    }, [])


  return (
      <div>
          
          <div className={page == false ? 'd-none' : ''}>
              <hr />
              <div className="d-flex justify-content-between fw-bold">
                  <small>Jam booking</small>
                  <small>Tanggal</small>
              </div>
              <div className="d-flex justify-content-between fw-bold text-primary">
                 <small><i className='fas fa-clock'></i> { booking.jam_booking }</small>
                 <small><i className='fas fa-calendar-days'></i> { booking.tgl }</small>
              </div>
                <hr />
               <div className="d-flex justify-content-between fw-bold">
                  <small><i className='fas fa-user'></i> Status</small>

                  {booking.status_pembayaran == 201 ? <><span class="badge rounded-pill bg-warning"><small>Menunggu</small> </span></> : booking.status_pembayaran == 200 ? <span class="badge rounded-pill bg-success"><small>Disetujui</small> </span> : <span class="badge rounded-pill bg-danger"><small>Ditolak</small> </span> }
                
              </div>
        </div>
    </div>
  )
}
