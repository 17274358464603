import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Compdatagoal({ goal2 }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [goal, setGoal] = useState(false);
  const [datagoal, setDatagoal] = useState([]);

  const getData = async () => {
    try {
      const response = await axios.get(
        urlapi + "Datagoal2?id_user=" + localStorage.getItem("id")
      );
      setDatagoal(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <div
        className={`d-flex justify-content-between ${
          goal ? "text-primary" : "text-secondary"
        }`}
        onClick={() => setGoal(!goal)}
        style={{ cursor: "pointer" }}
      >
        <p>Goal</p>
        <p>
          <a style={{ textDecoration: "none" }}>
            {goal2 !== "" ? goal2 : 0} Goal{" "}
            <i
              className={goal ? "fas fa-chevron-down" : "fas fa-chevron-right"}
            ></i>
          </a>
        </p>
      </div>
      {goal ? (
        <div className="card border-primary">
          <div className="card card-body">
            {datagoal == false ? (
              <p className="text-center text-secondary">
                <i className="fas fa-trash"></i> Data Kosong
              </p>
            ) : (
              ""
            )}

            {datagoal.map((goal) => {
              return (
                <div key={goal.id}>
                  <div className="d-flex justify-content-between text-primary">
                    <small>
                      <i className="fas fa-user"></i> {goal.team}
                    </small>
                    <small>
                      <i className="fas fa-shield"></i> {goal.team}
                    </small>

                    <small>
                      {goal.score_team} VS {goal.score_lawan}
                    </small>

                    <small>
                      <i className="fas fa-shield"></i> {goal.lawan}
                    </small>
                  </div>
                  <hr />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
