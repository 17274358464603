import React from "react";

export default function Compheader() {
  return (
    <div>
      <div
        className="card bg-danger text-white"
        style={{ borderRadius: "0px", height: "200px" }}
      >
        <div className="container mt-5">
          <br></br>
          <h5 className="card-title"></h5>
          <p className="card-text">
            Tingkatkan main anda untuk menaikan statistik anda
          </p>
        </div>
      </div>
    </div>
  );
}
