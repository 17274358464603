import React from "react";
import Navbar from "../componenst/Navbar";
import { useParams } from "react-router-dom";
import Compdatapemainrankteam from "../componenst/Membarkarirteam/Compdatapemainrankteam";

export default function Pemainrankteam() {
  const { team, lawan, kode } = useParams();
  return (
    <div>
      <Navbar judul="Pemain rank team" aicon="true" />
      <Compdatapemainrankteam team={team} lawan={lawan} kode={kode} />
    </div>
  );
}
