import axios from "axios";
import React, { useEffect, useState } from "react";
import Jambookingspesial from "./Jambookingspesial";
import Navbar from "../componenst/Navbar";
import Pembayaran from "./Pembayaran";
import { ToastContainer, toast } from "react-toastify";
import Pembayaranbookingspesial from "./Pembayaranbookingspesial";
import { Link } from "react-router-dom";
import Databookingspesial from "./Databookingspesial";
import Jambookingspesialranksolo from "./Jambookingspesialranksolo";

export default function Bookingspesial() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [datarank, setDatarank] = useState([]);
  const [idjadwal, setIdjadwal] = useState(null);
  const [lapangan, setLapangan] = useState("");
  const [harga, setHarga] = useState(0);
  const [namateam, setNamateam] = useState("");
  const [wa, setWa] = useState("");
  const [tglmain, setTglmain] = useState("");
  const [idlapangan, setIdlapangan] = useState("");
  const [idjambooking, setIdjambooking] = useState("");
  const [booking, setBooking] = useState("null");
  const [databooking, setDatabooking] = useState([]);
  const [ranksolo, setRanksolo] = useState([]);
  const [page, setPage] = useState(false);
  const [pesan, setPesan] = useState("");
  const [pesan2, setPesan2] = useState("");

  const rankteam = async () => {
    try {
      const response = await axios.get(urlapi + "Getrankteam2");
      setDatarank(response.data);
      // console.log(response.data);
    } catch (error) { }
  };

  const handlebooking = (
    id,
    idlap,
    jam_mulai,
    jam_selesai,
    lapangan,
    tglmain
  ) => {
    setIdjadwal(id);
    getlapangan(id, idlap, jam_mulai, jam_selesai, tglmain);
    setLapangan(lapangan);
    setTglmain(tglmain);
    setIdlapangan(idlap);
    Cekbookingspesial(id);
  };

  const getlapangan = async (id, idlap, jam_mulai, jam_selesai, tglmain) => {
    try {
      const response = await axios.get(
        urlapi +
        "Getjambookingspesial?idlap=" +
        idlap +
        "&&jam_mulai=" +
        jam_mulai +
        "&&jam_selesai=" +
        jam_selesai +
        "&&tglmain=" +
        tglmain
      );
      // console.log(response.data);
      setHarga(response.data.harga_diskon);
      setIdjambooking(response.data.id);
    } catch (error) { }
  };

  const Cekbookingspesial = async (id) => {
    try {
      const response = await axios.get(urlapi + "Cekbookingspesial?id=" + id);
      // console.log(response.data);
      setDatabooking(response.data);
      if (response.data != false) {
        setBooking("true");
      } else {
        setBooking("null");
      }
    } catch (error) { }
  };

  const cekpesanbooking = async () => {
    try {
      const response = await axios.get(urlapi + "Pesanbookingspesial");
      // console.log(response.data);
      setPesan(response.data.pesan);
    } catch (error) { }
  };

  const cekpesanbooking2 = async () => {
    try {
      const response = await axios.get(urlapi + "Pesanbookingspesial2");
      // console.log(response.data);
      setPesan2(response.data.pesan);
    } catch (error) { }
  };

  const getdataranksolo = async () => {
    try {
      const response = await axios.get(urlapi + "Getranksolospesialbooking");
      // console.log(response.data);
      setRanksolo(response.data);
    } catch (error) { }
  };

  useEffect(() => {
    rankteam();
    cekpesanbooking();
    getdataranksolo();
    cekpesanbooking2();
  }, []);
  return (
    <div>
      <Navbar judul="Booking spesial" aicon="true" />
      <div className="container">
        <br />
        <br />
        <br />
        <img
          src="img/bookingspesial.png"
          style={{ borderRadius: "5px" }}
          className="img-fluid"
          alt="Responsive image"
        ></img>
      </div>

      <div className="container">
        <div className="card mt-2">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <small
                className={page == false ? "fw-bold text-primary" : "fw-bold"}
                onClick={() => setPage(!page)}
                style={{ cursor: "pointer" }}
              >
                <i className="fas fa-futbol"></i> Booking spesial{" "}
              </small>
              <small
                className={page == true ? "fw-bold text-primary" : "fw-bold"}
                style={{ cursor: "pointer" }}
                onClick={() => setPage(!page)}
              >
                Booking anda <i className="fas fa-angle-right"></i>{" "}
              </small>
            </div>

            <hr />

            {page == true ? (
              <>
                <p
                  className="fw-bold mb-2 text-secondary"
                  style={{ fontSize: "12px" }}
                >
                  Data booking spesial anda
                </p>

                <Databookingspesial className="mt-2" />
              </>
            ) : (
              <>
                {pesan == null && pesan2 == null ? (
                  <>
                    {" "}
                    <div class="alert alert-danger" role="alert">
                      <center>
                        <small className="text-danger fw-bold">
                          <h3>
                            <i className="fas fa-triangle-exclamation"></i>
                          </h3>
                          Jadwal booking spesial belum tersedia
                        </small>{" "}
                      </center>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {pesan == "kosong" && pesan2 == "kosong" ? (
                  <>
                    <div class="alert alert-danger" role="alert">
                      <center>
                        <small className="text-danger fw-bold">
                          <h3>
                            <i className="fas fa-triangle-exclamation"></i>
                          </h3>
                          Jadwal booking spesial belum tersedia
                        </small>{" "}
                      </center>
                    </div>
                  </>
                ) : pesan == "tersedia" || pesan2 == "tersedia" ? (
                  <>
                    <div class="alert alert-success" role="alert">
                      <small className="text-success fw-bold">
                        <i className="fas fa-circle-info"></i> Jadwal booking
                        spesial tersedia
                      </small>{" "}
                    </div>
                  </>
                ) : (
                  ""
                )}
                {datarank.map((data, index) => {
                  return (
                    <div
                      className=""
                      key={index}
                      onClick={() =>
                        handlebooking(
                          data.id,
                          data.id_lapangan,
                          data.jam_mulai,
                          data.jam_selesai,
                          data.lapangan,
                          data.tgl_main
                        )
                      }
                    >
                      <Jambookingspesial
                        kode_main={data.kode_main}
                        tgl_main={data.tgl_main}
                        jam_mulai={data.jam_mulai}
                        jam_selesai={data.jam_selesai}
                        lapangan={data.lapangan}
                        tgl={data.tgl_main}
                        idlap={data.id_lapangan}
                        id={idjadwal}
                      />
                    </div>
                  );
                })}

                {/* //rank solo */}

                {ranksolo.map((rs, index) => {
                  return (
                    <div
                      onClick={() =>
                        handlebooking(
                          rs.id,
                          rs.id_lapangan,
                          rs.jam_mulai,
                          rs.jam_selesai,
                          rs.lapangan,
                          rs.tgl_main
                        )
                      }
                      key={index}
                    >
                      {
                        <Jambookingspesialranksolo
                          kode_main={rs.kode_main}
                          lapangan={rs.lapangan}
                          id={idjadwal}
                        />
                      }
                    </div>
                  );
                })}
              </>
            )}
          </div>

          {page == true ? (
            <></>
          ) : (
            <>
              <div className="container">
                <hr />

                <div className="card ">
                  <div className="card-body">
                    <small className="fw-bold">Pembayaran</small>
                    <br />
                    <small className="text-secondary">
                      Pembayaran booking lapangan hanya dapat di
                      lakukan dengan BANK TRANSFER dengan nomor rekening tujuan
                      REK BCA : 6475383951 a/n Pendy Or Handoko , Pembayaran
                      Booking Lapangan Wajib DP 50% dari Harga yang sudah di
                      tentukan, dengan syarat pelunasan (H-1) 1 hari sebelum
                      jadwal main
                    </small>
                  </div>
                </div>

                <div className="card mt-2 mb-3">
                  <div className="card-body">
                    <div className="row mb-3 text-secondary">
                      <div className="form-group mt-3 col-md-6">
                        <label>Lapangan </label>
                        <input
                          type="text"
                          value={lapangan}
                          className="form-control mt-3 fw-bold"
                        />
                      </div>
                      <div className="form-group mt-3 col-md-6">
                        <label>Harga Lapangan</label>
                        <input
                          type="text"
                          value={harga / 2}
                          className="form-control mt-3 fw-bold"
                        />
                      </div>

                      <div className="form-group mt-3 col-md-6">
                        <label>Transfer</label>
                        <input
                          type="text"
                          value={harga / 2}
                          className="form-control mt-3 fw-bold"
                        />
                      </div>

                      <div className="form-group mt-3 col-md-6">
                        <label>Tanggal Main</label>
                        <input
                          type="text"
                          value={tglmain}
                          className="form-control mt-3 fw-bold"
                        />
                      </div>
                      <div className="form-group mt-3 col-md-6">
                        <label>Nama Team</label>
                        <input
                          type="text"
                          value={namateam}
                          className="form-control mt-3 fw-bold"
                          onChange={(e) => setNamateam(e.target.value)}
                        />
                      </div>
                      <div className="form-group mt-3 col-md-6">
                        <label>No Whatsapp</label>
                        <input
                          type="number"
                          value={wa}
                          className="form-control mt-3 fw-bold"
                          onChange={(e) => setWa(e.target.value)}
                        />
                        <small>Masukan nomor whatsapp anda dengan benar</small>
                      </div>
                    </div>
                  </div>

                  {booking == "null" || databooking.status_pembayaran == 201 ? (
                    <>
        
                     {/* <Pembayaran
                  idlapangan={idlapangan}
                  lapangan={lapangan}
                  harga={harga}
                  namateam={namateam}
                  jambooking={idjambooking}
                  wa={wa}
                  tgl={tglmain}
                  totalharga={harga}
                  sistempembayaran="Lunas"
                  booking="spesial"
                />  */}
                      <Pembayaranbookingspesial
                        idlapangan={idlapangan}
                        lapangan={lapangan}
                        harga={harga}
                        namateam={namateam}
                        jambooking={idjambooking}
                        wa={wa}
                        tgl={tglmain}
                        totalharga={harga}
                        sistempembayaran="Lunas"
                        booking="spesial"
                        id={idjadwal}
                      />
                    </>
                  ) : (
                    <>
                      <button
                        className="btn w-100 rounded-pill mb-2"
                        disabled
                        style={{ backgroundColor: "#2b2e5a", color: "white" }}
                      >
                        Booking sekarang
                      </button>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <ToastContainer />
      <br />
    </div>
  );
}
