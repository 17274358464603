import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import CompKlasemen from "./CompKlasemen";
import CompScore from "./CompScore";
import CompeAsist from "./CompeAsist";
import CompKiper from "./CompKiper";
import Play from "./Play";
import Comphasilpertandingan from "./Comphasilpertandingan";
import Compkartu from "../Comphasilranksolo/Compkartu";

export default function CompLiga() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const no = 1;
  const [pol, setPol] = useState("All");
  const [klasemen, setKlasemen] = useState(true);
  const [dataklasemen, setDataklasemen] = useState([]);
  const [score, setScore] = useState(false);
  const [asist, setAsist] = useState(false);
  const [kiper, setKiper] = useState(false);
  const [topscore, setTopscore] = useState([]);
  const [topasist, setTopasist] = useState([]);
  const [topkiper, setTopkiper] = useState([]);
  const [hasilpertandingan, setHasilpertandingan] = useState([]);
  const [kodemain, setKodemain] = useState([]);
  const [page, setPage] = useState(false);
  const [pagepertandingan, setPagepertandingan] = useState(false);

  const handleMenu = (val) => {
    if (val == "klasemen") {
      setKlasemen(true);
      setScore(false);
      setAsist(false);
      setKiper(false);
    } else if (val == "score") {
      setKlasemen(false);
      setScore(true);
      setAsist(false);
      setKiper(false);
    } else if (val == "asist") {
      setKlasemen(false);
      setScore(false);
      setAsist(true);
      setKiper(false);
    } else if (val == "kiper") {
      setKlasemen(false);
      setScore(false);
      setAsist(false);
      setKiper(true);
    }
  };

  const handlePol = (pol) => {
    setPol(pol);
    getKlasemen(pol);
    getTopscore(pol);
    getTopasist(pol);
    getTopkiper(pol);
  };

  const getKlasemen = async (pol) => {
    try {
      const response = await axios.get(urlapi + "Klasemen?pot=" + pol);
      setDataklasemen(response.data);
      console.log(response.data);
    } catch (error) {}
  };

  const getTopscore = async (pol) => {
    try {
      const response = await axios.get(urlapi + "Topscore2?pot=" + pol);
      setTopscore(response.data);
    } catch (error) {}
  };

  const getTopasist = async (pol) => {
    try {
      const response = await axios.get(urlapi + "Topasist2?pot=" + pol);
      setTopasist(response.data);
    } catch (error) {}
  };

  const getTopkiper = async (pol) => {
    try {
      const response = await axios.get(urlapi + "Topkiper?pot=" + pol);
      setTopkiper(response.data);
    } catch (error) {}
  };

  const pertandingan = async (page) => {
    try {
      const response = await axios.get(
        urlapi + "Hasilpertandingan?page=" + page
      );
      setHasilpertandingan(response.data);
      console.log(response.data);
    } catch (error) {}
  };

  const handlePertandingan = (page) => {
    setPage(page);
    pertandingan(page);
  };

  useEffect(() => {
    getKlasemen(pol);
    getTopscore(pol);
    getTopasist(pol);
    getTopkiper(pol);
    pertandingan(page);
  }, []);

  return (
    <div>
      <div className="card" style={{ borderRadius: "0px" }}>
        <Container>
          <div>
            <div className="row mt-3 mb-4 ">
              <div className="container  mb-2">
                <div className="card">
                  <div className="card-body">
                    <div class="d-flex justify-content-between mb-3">
                      <small
                        className={
                          pagepertandingan == true
                            ? "fw-bold text-primary"
                            : "fw-bold "
                        }
                        onClick={() => setPagepertandingan(!pagepertandingan)}
                        style={{ cursor: "pointer" }}
                      >
                        Lihat hasil liga EOF{" "}
                        <i
                          className={
                            pagepertandingan == true
                              ? "fas fa-angle-down"
                              : "fas fa-angle-right"
                          }
                        ></i>
                      </small>
                      {/* <small
                        className={
                          page == false ? "fw-bold text-primary" : "fw-bold"
                        }
                        onClick={() => handlePertandingan(!page)}
                      >
                        Lihat semua{" "}
                        <i
                          className={
                            page == false
                              ? "fas fa-angle-down"
                              : "fas fa-angle-right"
                          }
                        ></i>
                      </small> */}
                    </div>
                    <div className={pagepertandingan ? "row" : "d-none"}>
                      {hasilpertandingan.map((hs, index) => {
                        return (
                          <div className="col-sm-12 col-12 mb-2" key={index}>
                            <div
                              className="card shadow text-white"
                              id="bglistpemain"
                              style={{ border: "0px" }}
                            >
                              <div className="card-body">
                                <div className="row">
                                  <Comphasilpertandingan kode={hs.kode_main} />
                                  {/* <Compkartu kode={hs.kode} /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-4">
                <div
                  className={
                    pol == "All" ? "card shadow border-primary" : "card shadow"
                  }
                  onClick={() => handlePol("All")}
                >
                  <div className="card-body">
                    <p className="fw-bold">
                      {" "}
                      <i className="fas fa-futbol"></i> Pool All
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-4">
                <div
                  className={
                    pol == "A" ? "card shadow border-primary" : "card shadow"
                  }
                  onClick={() => handlePol("A")}
                >
                  <div className="card-body">
                    <p className="fw-bold">
                      {" "}
                      <i className="fas fa-futbol"></i> Pool A
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-4">
                <div
                  className={
                    pol == "B" ? "card shadow border-primary" : "card shadow"
                  }
                  onClick={() => handlePol("B")}
                >
                  <div className="card-body">
                    <p className="fw-bold">
                      {" "}
                      <i className="fas fa-futbol"></i> Pool B
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between my-3">
              <small
                className={klasemen ? "fw-bold text-primary" : "fw-bold"}
                onClick={() => handleMenu("klasemen")}
                style={{ cursor: "pointer" }}
              >
                Klasemen
              </small>
              <small
                className={score ? "fw-bold text-primary" : "fw-bold"}
                onClick={() => handleMenu("score")}
                style={{ cursor: "pointer" }}
              >
                Top score
              </small>
              {/* <small
                className={asist ? "fw-bold text-primary" : "fw-bold"}
                onClick={() => handleMenu("asist")}
                style={{ cursor: "pointer" }}
              >
                Top assist
              </small>
              <small
                className={kiper ? "fw-bold text-primary" : "fw-bold"}
                onClick={() => handleMenu("kiper")}
                style={{ cursor: "pointer" }}
              >
                Top kiper
              </small> */}
            </div>
            <hr />
          </div>
          {klasemen ? (
            <>
              <div class="table-responsive">
                <table class="table table-striped text-center">
                  <thead style={{ fontSize: "10px" }}>
                    <tr>
                      <th scope="col" width="20">
                        Pos
                      </th>
                      <th
                        scope="col"
                        width="50"
                        className="text-primary text-center"
                      >
                        TM
                      </th>
                      <th scope="col" width="2" className="text-success">
                        P
                      </th>
                      <th scope="col" width="2" className="text-info">
                        W
                      </th>
                      <th scope="col" width="2" className="text-warning">
                        D
                      </th>
                      <th scope="col" width="2" className="text-danger">
                        L
                      </th>

                      <th scope="col" className="text-info" width="2">
                        Pts
                      </th>
                      <th scope="col" className="text-success" width="2">
                        GF
                      </th>
                      <th scope="col" className="text-warning" width="2">
                        GA
                      </th>
                      <th scope="col" className="text-danger" width="2">
                        GD
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataklasemen.map((data, index) => {
                      return (
                        <tr
                          className="fw-bold"
                          key={index}
                          style={{ fontSize: "10px" }}
                        >
                          <td>{index + 1}</td>
                          <td>
                            <center>
                              <img
                                src={
                                  "https://ratumerak.id/adminmmsc/assets/berkas/" +
                                  data.logo
                                }
                                class="img-fluid "
                                alt="Responsive image"
                                style={{
                                  height: "60px",
                                  width: "60px",
                                  borderRadius: "100%",
                                }}
                              ></img>{" "}
                              <br />
                              {data.nama_team}
                            </center>
                          </td>
                          <td>{data.play}</td>
                          <td>{data.win}</td>
                          <td>{data.draw}</td>
                          <td>{data.lost}</td>
                          <td className="text-center">{data.point}</td>
                          <td className="text-center">{data.goal}</td>
                          <td className="text-center">{data.kebobolan}</td>
                          <td className="text-center">{data.selisih_goal}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : score ? (
            <>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead style={{ fontSize: "12px" }}>
                    <tr>
                      <th scope="col">Pos</th>
                      <th scope="col" className="text-center">
                        Nama
                      </th>
                      <th scope="col">Team</th>
                      <th scope="col">Goal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topscore.map((tp, index) => {
                      return (
                        <tr
                          className="fw-bold"
                          key={index}
                          style={{ fontSize: "12px" }}
                        >
                          <td>{index + 1}</td>
                          <td className="text-center">
                            <img
                              src={
                                "https://ratumerak.id/adminmmsc/assets/berkas/" +
                                tp.foto
                              }
                              class="img-fluid "
                              alt="Responsive image"
                              style={{
                                height: "60px",
                                width: "60px",
                                borderRadius: "100%",
                              }}
                            ></img>
                            <br />
                            {tp.pemain}
                          </td>
                          <td>{tp.team}</td>
                          <td>{tp.jml}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : asist ? (
            <>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead style={{ fontSize: "12px" }}>
                    <tr>
                      <th scope="col">Pos</th>
                      <th scope="col" className="text-center">
                        Nama
                      </th>
                      <th scope="col">Team</th>
                      <th scope="col">Assist</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topasist.map((as, index) => {
                      return (
                        <tr
                          key={index}
                          className="fw-bold"
                          style={{ fontSize: "12px" }}
                        >
                          <td>{index + 1}</td>

                          <td className="text-center">
                            <img
                              src={
                                "https://ratumerak.id/adminmmsc/assets/berkas/" +
                                as.foto
                              }
                              class="img-fluid "
                              alt="Responsive image"
                              style={{
                                height: "60px",
                                width: "60px",
                                borderRadius: "100%",
                              }}
                            ></img>
                            <br />
                            {as.pemain}
                          </td>

                          <td>{as.team}</td>
                          <td>{as.jml}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : kiper ? (
            <>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead style={{ fontSize: "12px" }}>
                    <tr>
                      <th scope="col">Pos</th>
                      <th scope="col" className="text-center">
                        Nama
                      </th>
                      <th scope="col">Team</th>
                      <th scope="col">Kebobolan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topkiper.map((as, index) => {
                      return (
                        <tr
                          key={index}
                          className="fw-bold"
                          style={{ fontSize: "12px" }}
                        >
                          <td>{index + 1}</td>
                          <td className="text-center">
                            <img
                              src={
                                "https://ratumerak.id/adminmmsc/assets/berkas/" +
                                as.foto
                              }
                              class="img-fluid "
                              alt="Responsive image"
                              style={{
                                height: "60px",
                                width: "60px",
                                borderRadius: "100%",
                              }}
                            ></img>
                            <br />
                            {as.pemain}
                          </td>
                          <td>{as.team}</td>
                          <td>{as.jml}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            ""
          )}
        </Container>
      </div>
    </div>
  );
}
