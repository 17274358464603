import React from "react";
import Navbar from "../componenst/Navbar";
import Compmainranksolo from "../componenst/Rankingsolo/Compmainranksolo";
export default function Rankingsolo() {
  return (
    <div>
      <Navbar judul="Ranking solo" aicon="true" />
      <Compmainranksolo />
    </div>
  );
}
