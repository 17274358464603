import React from "react";
import Compverifikasi from "../componenst/Verifikasi/Compverifikasi";

export default function Verifikasi() {
  return (
    <div className="register">
      <Compverifikasi />
    </div>
  );
}
