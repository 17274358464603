import React from "react";
import Navbar from "../componenst/Navbar";
import Comptambahpemain from "../componenst/Membarkarirteam/Comptambahpemain";

export default function Tambahpemain() {
  return (
    <div>
      <Navbar judul="Tambah pemain" aicon="true" />
      <Comptambahpemain />
    </div>
  );
}
