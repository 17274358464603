import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";

export default function Compmodal() {
  const [step, setStep] = useState(1);
  const [show, setShow] = useState(true);

  const handlepopup = () => {
    localStorage.setItem("popup", "true");
    setShow(false);
  };

  useEffect(() => {
    if (localStorage.getItem("popup") == "true") {
      setShow(false);
    }
  }, []);
  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        animation={true}
        centered={true}
      >
        <Modal.Header>
          <Modal.Title>
            <small className="fw-bold">Mengingat peraturan kembali</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            {step == 1 ? (
              <>
                <center>
                  <img
                    src="img/1.svg"
                    class="img-fluid"
                    alt=""
                    style={{ height: "150px" }}
                  ></img>
                </center>
                <div className="mt-3">
                  <small>
                    Pengguna harus mempunyai nama team dan logo team
                  </small>
                </div>
              </>
            ) : step == 2 ? (
              <>
                <center>
                  <img
                    src="img/2.svg"
                    class="img-fluid"
                    alt=""
                    style={{ height: "150px" }}
                  ></img>
                </center>
                <div className="mt-3">
                  <small>
                    Maksimal jumlah pemain yang didaftarkan sebanyak 25 pemain

                  </small>
                </div>
              </>
            ) : step == 3 ? (
              <>
                <center>
                  <img
                    src="img/3.svg"
                    class="img-fluid"
                    alt=""
                    style={{ height: "150px" }}
                  ></img>
                </center>
                <div className="mt-3">
                  <small>
                    Pemain yang di daftarkan harus pemain nonpro/nonexpro dalam
                    hal sepakbola dan futsal
                  </small>
                </div>
              </>
            ) : step == 4 ? (
              <>
                <center>
                  <img
                    src="img/4.svg"
                    class="img-fluid"
                    alt=""
                    style={{ height: "150px" }}
                  ></img>
                </center>
                <div className="mt-3">
                  <small>
                    Nama pemain dan NIK pemain harus sesuai dengan data diri
                    pemain
                  </small>
                </div>
              </>
            ) : step == 5 ? (
              <>
                <center>
                  <img
                    src="img/5.svg"
                    class="img-fluid"
                    alt=""
                    style={{ height: "150px" }}
                  ></img>
                </center>
                <div className="mt-3">
                  <small>
                    Pengguna wajib membayar sesuai jumlah di aplikasi di saat
                    booking rank team
                  </small>
                </div>
              </>
            ) : step == 6 ? (
              <>
                <center>
                  <img
                    src="img/6.svg"
                    class="img-fluid"
                    alt=""
                    style={{ height: "150px" }}
                  ></img>
                </center>
                <div className="mt-3">
                  <small>
                    Pengguna yang sudah melakukan booking rank team dan di
                    setujui admin wajib datang sesuai tanggal dan jam yang
                    tertera di aplikasi
                  </small>
                </div>
              </>
            ) : step == 7 ? (
              <>
                <center>
                  <img
                    src="img/7.svg"
                    class="img-fluid"
                    alt=""
                    style={{ height: "150px" }}
                  ></img>
                </center>
                <div className="mt-3">
                  <small>
                    {" "}
                    Data Pemain tidak dabat di tambah dan diubah ketika data team anda sudah di setujui admin
                  </small>
                </div>
              </>
            ) : step == 8 ? (
              <>
                <center>
                  <img
                    src="img/8.svg"
                    class="img-fluid"
                    alt=""
                    style={{ height: "150px" }}
                  ></img>
                </center>
                <div className="mt-3">
                  <small>
                    {" "}
                    Pemain tidak dapat di tambah jika jumlah pemain sudah
                    memenuhi kuota
                  </small>
                </div>
              </>
            ) : step == 8 ? (
              <>
                <small>
                  {" "}
                  Pemain tidak dapat di tambah jika jumlah pemain sudah memenuhi
                  kuota
                </small>
              </>
            ) : step == 9 ? (
              <>
                <center>
                  <img
                    src="img/9.svg"
                    class="img-fluid"
                    alt=""
                    style={{ height: "150px" }}
                  ></img>
                </center>
                <div className="mt-3">
                  <small>
                    {" "}
                    Apabila pemain kurang dari 25 pemain dan team sudah
                    melakukan 2 match rank team maka team tidak dapat menambah
                    pemain
                  </small>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <div className="mb-4 mt-3">
          <center>
            {step == 1 ? (
              <>
                {" "}
                <div className="d-flex justify-content-around">
                  <label
                    onClick={() => setStep(step + 1)}
                    className="fw-bold"
                    style={{ color: "#2b2e5a" }}
                  >
                    Next <i className="fas fa-angle-right"></i>
                  </label>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex justify-content-around">
                  {step == 8 ? (
                    <>
                      {" "}
                      <Button
                        variant="primary rounded-pill"
                        onClick={() => handlepopup()}
                        style={{ backgroundColor: "#2b2e5a", color: "#ffff" }}
                      >
                        Close
                      </Button>
                    </>
                  ) : (
                    <>
                      <label
                        onClick={() => setStep(step - 1)}
                        className="fw-bold"
                        style={{ color: "#2b2e5a" }}
                      >
                        <i className="fas fa-angle-left"></i> Back
                      </label>

                      <label
                        onClick={() => setStep(step + 1)}
                        className="fw-bold"
                        style={{ color: "#2b2e5a" }}
                      >
                        Next <i className="fas fa-angle-right"></i>
                      </label>
                    </>
                  )}
                </div>
              </>
            )}{" "}
          </center>
        </div>
      </Modal>
    </div>
  );
}
