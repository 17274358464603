import React from "react";
import Compprofil from "./Compprofil";

export default function Compheaderprofilpemain({ nama, posisi, no, foto }) {
  return (
    <div>
      <div>
        <div
          className="card text-white"
          style={{
            borderRadius: "0px",
            backgroundImage:
              "url('https://1.bp.blogspot.com/-z0fvLvtNaMQ/Xng46Le14bI/AAAAAAAAFzI/q0z-SnM87Bol1_0QQRKV8Ob_eHxB9BqkgCLcBGAsYHQ/s16000/begron-biru.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "containt",
            backgroundPosition: "center",
          }}
        >
          <br />
          <br />
          <div className=" mt-5">
            <div className="row">
              <div className="col-sm-6 col-8">
                {" "}
                <div
                  className="card border-0"
                  style={{
                    position: "relative",
                    top: "50px",
                    right: "1px",
                    borderTopRightRadius: "20px",
                    borderTopLeftRadius: "0px",
                  }}
                >
                  <div className="card-body">
                    <Compprofil foto={foto} />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-4">
                <div className="mt-5">
                  <h3 className="fw-bold">{nama}</h3>
                  <h2 className="fw-bold">
                    <label>{no}</label> {}{" "}
                    <label style={{ fontSize: "12px" }}>{posisi}</label>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
