import React, { useEffect, useState } from "react";

import axios from "axios";
import Loadprofil from "../../skeleton/Loadprofil";
import { Form } from "react-bootstrap";
import { imageDb } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { ToastContainer, toast } from "react-toastify";
import Compheader from "./Compheader";

export default function Compmainrankteam() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [team, setTeam] = useState([]);
  const [logoteam, setLogoteam] = useState("");
  const [namateam, setNamateam] = useState("");
  const [load, setLoad] = useState(false);
  const [formgambar, setFormgambar] = useState(false);
  const [valueimg, setvalueimg] = useState("");
  const [nameImg, setNameImg] = useState("");
  const [img, setImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [sukses, setSukses] = useState(false);
  const [cekbookingteam, setCekbookingteam] = useState("");
  const [point, setPoint] = useState([]);
  const [ranking, setRanking] = useState([]);

  const datarangking = async () => {
    try {
      const response = await axios.get(
        urlapi + "Rangkingrankteam?id_user=" + localStorage.getItem("id")
      );
      setRanking(response.data.data);
    } catch (error) {}
  };

  const notifupdate = () => {
    toast.success("Profil team anda berhasil di update !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const notif = () => {
    toast.error("Anda tidak dapat melakukan ubah data !", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const handleChangeImg = (e) => {
    setImg(e.target.files[0]);
    // console.log(e);
    setvalueimg(e.target.value);
    setNameImg(e.target.files[0].name);
    setSukses(false);
  };

  const getTeam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Datateam?id_user=" + localStorage.getItem("id")
      );
      setTeam(response.data);
      //   console.log(response.data);
      setNamateam(response.data.nama_team);
      setLogoteam(response.data.logo_team);
    } catch (error) {}
  };

  const handleubahdata = () => {
    if (nameImg !== "") {
      setLoading(true);
      setSukses(false);
      const imgRef = ref(imageDb, `files/${nameImg}`);
      uploadBytes(imgRef, img);
      console.log(imgRef._location.path_);

      setTimeout(() => {
        getDownloadURL(ref(imageDb, `files/${nameImg}`)).then((url) => {
          console.log("prosess");

          // setForm(false);
          ubahdata(url);
        });
      }, 15000);
    } else {
      setSukses(false);
      ubahdata("");
    }
  };

  const ubahdata = async (img) => {
    await axios
      .post(urlapi + "Ubahdatateam", {
        id: localStorage.getItem("id"),
        nama_team: namateam,
        img: img,
      })
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        getTeam();
        setImg("");
        setvalueimg("");
        if (img !== "") {
          setSukses(true);
        }

        notifupdate();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const cekteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Cekbookingteam?id_user=" + localStorage.getItem("id")
      );
      setCekbookingteam(response.data.id_user);
      //   console.log(response.data.id_user);
    } catch (error) {}
  };

  const getPoinrankteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Pointrankteam?id_user=" + localStorage.getItem("id")
      );
      console.log(response.data);
      setPoint(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getTeam();
    cekteam();
    getPoinrankteam();
    datarangking();
  }, []);

  return (
    <div>
      <Compheader team={team.nama_team} logo={team.logo_team} />
      <div>
        <div
          className="container"
          style={{ position: "relative", bottom: "50px" }}
        >
          <div className="card shadow">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <p className=" fw-bold" style={{ color: "#2b2e5a" }}>
                  <i className="fas fa-shield-halved"></i> Statistik team anda
                </p>
                <p className="text-primary fw-bold">
                  <i
                    className="fas fa-ellipsis-vertical"
                    style={{ color: "#2b2e5a" }}
                  ></i>
                </p>
              </div>
              <div className="row">
                <div className="col-sm-6 col-6">
                  <div className="card border-0 shadow" id="cardprofilteam">
                    <div className="card-body">
                      <h5 className="fw-bold" style={{ color: "#fff" }}>
                        Play
                      </h5>
                      <p>
                        {point.play == null || point.play == "" ? (
                          "0"
                        ) : (
                          <>{point.play}</>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-6">
                  <div className="card border-0 shadow" id="cardprofilteam">
                    <div className="card-body">
                      <h5 className="fw-bold" style={{ color: "#fff" }}>
                        Win
                      </h5>
                      <p>
                        {point.win == null || point.win == "" ? (
                          "0"
                        ) : (
                          <>{point.win}</>
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-6 mt-2">
                  <div className="card border-0 shadow" id="cardprofilteam">
                    <div className="card-body">
                      <h5 className="fw-bold" style={{ color: "#fff" }}>
                        Draw
                      </h5>
                      <p>
                        {point.draw == null || point.draw == "" ? (
                          "0"
                        ) : (
                          <>{point.draw}</>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-6 mt-2">
                  <div className="card border-0 shadow" id="cardprofilteam">
                    <div className="card-body">
                      <h5 className="fw-bold" style={{ color: "#fff" }}>
                        Lost
                      </h5>
                      <p>
                        {point.lost == null || point.lost == "" ? (
                          "0"
                        ) : (
                          <>{point.lost}</>
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-6 mt-2">
                  <div className="card border-0 shadow" id="cardprofilteam">
                    <div className="card-body">
                      <h5 className="fw-bold" style={{ color: "#fff" }}>
                        Rank
                      </h5>
                      <p>
                        {ranking == false || ranking == "" ? (
                          <>0</>
                        ) : (
                          <>
                            {ranking.map((data, index) => {
                              return (
                                <div key={index}>
                                  {data.kode_team == point.kode_team ? (
                                    <>{index + 1}</>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              );
                            })}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-6 mt-2">
                  <div className="card border-0 shadow" id="cardprofilteam">
                    <div className="card-body">
                      <h6 className="fw-bold" style={{ color: "#fff" }}>
                        Winrate
                      </h6>
                      <p>
                        {point.winrate == null || point.winrate == "" ? (
                          "0"
                        ) : (
                          <>{point.winrate.slice(0, 5)} %</>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <Compdataplay main={datawinrate.play} />
                <hr />
                <Compdataasist assist={datawinrate.assist} />
                <hr />
                <Compdatagoal goal2={datawinrate.goal} /> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className="container"
        style={{ position: "relative", bottom: "30px" }}
      >
        <div className="alert alert-info mt-2 border-0">
          <i className="fas fa-circle-info"></i>{" "}
          <small>
            Team yang sudah pernah bermain rank team tidak dapat merubah data
            team anda
          </small>
        </div>
        <div className="card">
          <div className="card-body">
            <small className="text-secondary fw-bold">Ubah data team</small>

            <div className="form-group mt-3">
              <label className="mb-2 fw-bold">Nama team</label>
              <input
                type="text"
                className="form-control"
                value={namateam}
                onChange={(e) => setNamateam(e.target.value)}
              />
            </div>

            <div className="form-group mt-3">
              <label className="mb-1 fw-bold">Logo team</label>
            </div>
            <div className="mt-2">
              {/* <small
                className="text-secondary"
                onClick={() => setFormgambar(!formgambar)}
              >
                Apakah anda ingin merubah logo team anda ? {formgambar}
              </small> */}
            </div>

            <Form.Group className="mb-3" controlId="formBasicupload">
              <label for="images" class="drop-container" id="dropcontainer">
                <span class="drop-title">
                  {nameImg == "" ? "Upload logo team" : <></>}
                  {loading == true ? (
                    <>
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>{" "}
                      Loading
                    </>
                  ) : (
                    ""
                  )}

                  {sukses == true ? (
                    <>
                      <i className="fas fa-circle-check text-success"></i>{" "}
                      Upload sukses
                    </>
                  ) : (
                    ""
                  )}
                </span>

                <input
                  type="file"
                  id="images"
                  className="form-control"
                  accept="image/*"
                  onChange={(e) => handleChangeImg(e)}
                  required
                />
              </label>
              {/* <small>
                Upload bukti pembayaran dengan format Gambar (JPG, JPEG & PNG)
              </small> */}
            </Form.Group>
            <small className={loading == false ? "d-none" : ""}>
              Mohon untuk menunggu proses ubah logo team ....
            </small>

            {cekbookingteam != null ? (
              <>
                <button
                  className="btn btn-primary w-100 rounded-pill mt-3"
                  onClick={() => notif()}
                  style={{ backgroundColor: "#2b2e5a" }}
                >
                  Ubah data
                </button>
              </>
            ) : (
              <>
                {" "}
                <button
                  className="btn btn-primary w-100 rounded-pill mt-3"
                  disabled={loading == false ? false : true}
                  onClick={() => handleubahdata()}
                  style={{ backgroundColor: "#2b2e5a" }}
                >
                  {loading == false ? "Ubah data" : "Loading ....."}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
