import React from "react";
import Navbar from "../componenst/Navbar";
import Compmainrankteam from "../componenst/Rankingteam/Compmainrankteam";
export default function Rankingrankteam() {
  return (
    <div>
      <Navbar judul="Ranking team" aicon="true" />
      <Compmainrankteam />
    </div>
  );
}
