import React, { useEffect, useState } from "react";
import Comppembayaran from "./Comppembayaran";
import axios from "axios";
import Complawan from "./Complawan";
import Comptopscore2 from "./Comptopscore2";
import Compwinrate from "./Compwinrate";
import Comptopassist from "./Comptopassist";
import { Link, useNavigate } from "react-router-dom";
import Compposisi from "./Compposisi";
import Comptopkiper from "./Comptopkiper";
import Comphistory from "./Comphistory";
import Comppoint from "./Comppoint";
import Compusers from "../History/Compusers";
import { ToastContainer, toast } from "react-toastify";
import Topranksolo from "./Topranksolo";

export default function Compmain2() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [member, setMember] = useState([]);
  const [member2, setMember2] = useState([]);
  const [getfun, setGetfun] = useState([]);
  const [game, setGame] = useState([]);
  const [team, setTeam] = useState("");
  const [team2, setTeam2] = useState([]);
  const [datawinrate, setDatawinrate] = useState([]);
  const [jmlmain, setJmlmain] = useState([]);
  const [pagewin, setPagewin] = useState(false);
  const [pagescore, setPagescore] = useState(false);
  const [pageasist, setPageasist] = useState(false);
  const [pagekiper, setPagekiper] = useState(false);
  const [kuota, setKuota] = useState("");
  const [posisi, setPosisi] = useState([]);
  const [getsesi, setGetsesi] = useState([]);
  const [kuotpenjagagawang, setKuotapenjagagawang] = useState("");
  const [bobol, setBobol] = useState("");
  const [sesimain, setSesimain] = useState("");
  const [kuoatasesi, setKuotasesi] = useState("");
  const [sisapemainsesi1, setSisapemainsesi1] = useState("");
  const [sisapemainsesi2, setSisapemainsesi2] = useState("");
  const [bookspesial, setBookspeisal] = useState([]);
  const [ranksolo, setRanksolo] = useState([]);

  const navigate = useNavigate();

  const date = new Date();
  let tgl = new Date();
  let format_tgl =
    tgl.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + tgl.getDate()).slice(-2);

  const [tglday, setTglday] = useState(format_tgl);

  const cekMemberkarir = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Add_memberkarirbaru?id=" +
          localStorage.getItem("id") +
          "&&sesi=1"
      );
      setMember(response.data);
      setTeam(response.data.team);
      console.log(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const cekMemberkarir2 = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Add_memberkarirbaru?id=" +
          localStorage.getItem("id") +
          "&&sesi=2"
      );
      setMember2(response.data);
      setTeam(response.data.team);
      console.log(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const memberkarir = async () => {
    try {
      const response = await axios.get(urlapi + "Memberkarir");
      setGetfun(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const main = async () => {
    try {
      const response = await axios.get(urlapi + "Getfungame");
      //   console.log(response.data);
      setGame(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const teamanda = async () => {
    try {
      const response = await axios.get(
        urlapi + "Getmemberkarir?id=" + localStorage.getItem("id")
      );

      setTeam2(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const winrate = async () => {
    try {
      const response = await axios.get(
        urlapi + "Winrate?iduser=" + localStorage.getItem("id")
      );
      setDatawinrate(response.data);
      // response.data.persen;
    } catch (error) {
      console.log(error.message);
    }
  };

  const getjmlmain = async () => {
    try {
      const response = await axios.get(
        urlapi + "Getjmlmainmemberkarir?iduser=" + localStorage.getItem("id")
      );
      setJmlmain(response.data.jml);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleshow = (page) => {
    if (page == "winrate") {
      setPagewin(true);
      setPagescore(false);
      setPageasist(false);
      setPagekiper(false);
    } else if (page == "topscore") {
      setPagescore(true);
      setPageasist(false);
      setPagewin(false);
      setPagekiper(false);
    } else if (page == "topassist") {
      setPageasist(true);
      setPagewin(false);
      setPagescore(false);
      setPagekiper(false);
    } else if (page == "topkiper") {
      setPageasist(false);
      setPagewin(false);
      setPagescore(false);
      setPagekiper(true);
    }
  };

  const Cekkuota = async () => {
    try {
      const response = await axios.get(urlapi + "Cekkuotamember");
      setKuota(response.data.message);
      // console.log(response.data.message);
    } catch (error) {}
  };

  const Cekkuotapenjagagawang = async () => {
    try {
      const response = await axios.get(urlapi + "Cekkuotapenjagagawang");
      setKuotapenjagagawang(response.data.message);
      // console.log(response.data.message);
    } catch (error) {}
  };

  const Cekkuotapersesi = async () => {
    try {
      const response = await axios.get(urlapi + "Cekkuotamemberkarirsesi");
      setKuotasesi(response.data);
      // console.log(response.data);
    } catch (error) {}
  };

  const daftar = () => {
    localStorage.removeItem("nama");
    localStorage.removeItem("email");
    localStorage.removeItem("id");
    localStorage.removeItem("tamu");
    navigate("/register");
  };

  const cekposisi = async () => {
    try {
      const response = await axios.get(
        urlapi + "Cekposisi?iduser=" + localStorage.getItem("id")
      );
      // console.log(response.data);
      setPosisi(response.data.posisi);
    } catch (error) {}
  };

  const sesi = async () => {
    try {
      const response = await axios.get(urlapi + "Sesi");
      // console.log(response.data);
      setGetsesi(response.data);
    } catch (error) {}
  };

  const kebobolan = async () => {
    try {
      const response = await axios.get(
        urlapi + "Topkipermemberkarir?id_user=" + localStorage.getItem("id")
      );
      setBobol(response.data.jml);
    } catch (error) {}
  };

  const notify = () =>
    toast.warning("Pilih sesi main anda !", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  const notify2 = () =>
    toast.warning("Sesi yang anda pilih sudah full !", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  const ceksisapemain = async () => {
    try {
      const response = await axios.get(urlapi + "Sisapemainranksolo");
      // console.log(response.data.sesi2);
      setSisapemainsesi1(response.data.sisa1);
      setSisapemainsesi2(response.data.sisa1);
    } catch (error) {}
  };

  const cekbookingspesial = async () => {
    try {
      const response = await axios.get(
        urlapi + "Cekbookingspesial?ranksolo=true"
      );
      console.log(response.data);
      setBookspeisal(response.data);
    } catch (error) {}
  };

  const getpertandinganranksolo = async () => {
    try {
      const response = await axios.get(urlapi + "Pertandinganranksoloterbaru");

      setRanksolo(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    cekMemberkarir();
    cekMemberkarir2();
    main();
    teamanda();
    memberkarir();
    winrate();
    getjmlmain();
    Cekkuota();
    cekposisi();
    sesi();
    Cekkuotapenjagagawang();
    kebobolan();
    Cekkuotapersesi();
    ceksisapemain();
    cekbookingspesial();
    getpertandinganranksolo();
  }, []);

  return (
    <div>
      <div className="container">
        <div
          className="card shadow"
          style={{
            position: "relative",
            bottom: "80px",
            border: "none",
            borderRadius: "5px",
          }}
          id="bglistwinrate"
        >
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6 col-6">
                <div className="card border border-primary" id="bglistpemain">
                  <div className="card-body text-white ">
                    <small>Play</small>
                    <h5>{jmlmain} Play</h5>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-6">
                {" "}
                <div className="card border border-primary" id="bglistpemain">
                  <div className="card-body text-white ">
                    <small>Win</small>
                    {datawinrate.win == null ? (
                      <h5>0 Win</h5>
                    ) : (
                      <h5>{datawinrate.win} Win</h5>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-6 mt-2">
                <div className="card border border-primary" id="bglistpemain">
                  <div className="card-body text-white ">
                    <small>Draw</small>
                    {datawinrate.draw == null ? (
                      <h5>0 Draw</h5>
                    ) : (
                      <h5>{datawinrate.draw} Draw</h5>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-6 mt-2">
                <div className="card border border-primary" id="bglistpemain">
                  <div className="card-body text-white">
                    <small>Lost</small>
                    {datawinrate.lost == null ? (
                      <h5>0 Lost</h5>
                    ) : (
                      <h5>{datawinrate.lost} Lost</h5>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-6 mt-2">
                <div className="card border border-primary" id="bglistpemain">
                  <div className="card-body text-white">
                    <small>Goal</small>
                    {datawinrate.goal == null ? (
                      <h5>0 Goal</h5>
                    ) : (
                      <h5>{datawinrate.goal} Goal</h5>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-6 mt-2">
                <div className="card border border-primary" id="bglistpemain">
                  <div className="card-body text-white">
                    <small>Winrate</small>
                    {datawinrate.persen == null ? (
                      <h5>0 %</h5>
                    ) : (
                      <h5>{datawinrate.persen} %</h5>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-6 mt-2">
                <div className="card border border-primary" id="bglistpemain">
                  <div className="card-body text-white">
                    <small>Posisi</small>
                    {posisi == "" ? <h5>Kosong</h5> : <p>{posisi}</p>}
                  </div>
                </div>
              </div>
              {posisi == "Penjaga gawang" || posisi == "Penjaga Gawang" ? (
                <>
                  <div className="col-md-6 col-6 mt-2">
                    <div
                      className="card border border-primary"
                      id="bglistpemain"
                    >
                      <div className="card-body text-white">
                        <small>Kebobolan</small>
                        {datawinrate.persen == null ? (
                          <h5>0 Goal</h5>
                        ) : (
                          <h5>{bobol} Goal</h5>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container">
        <div
          className="container"
          style={{ position: "relative", bottom: "60px", border: "none" }}
        >
          <div className="row">
            <div className="col-sm-6 col-6">
              <div
                className="card shadow"
                id="menurank"
                style={{ borderColor: "#2b2e5a", borderStyle: "solid" }}
              >
                <Link to="/history" style={{ textDecoration: "none" }}>
                  <div
                    className="card-body fw-bold"
                    style={{ fontSize: "12px", color: "white" }}
                  >
                    History anda <i class="fa-solid fa-angle-right"></i>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-6">
              <div
                className="card shadow text-white"
                id="menurank"
                style={{ borderColor: "#2b2e5a", borderStyle: "solid" }}
              >
                <Link
                  to="/hasilranksolo"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <div
                    className="card-body fw-bold"
                    style={{ fontSize: "12px" }}
                  >
                    Hasil rank solo <i class="fa-solid fa-angle-right"></i>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div
        className=""
        style={{ borderRadius: "0px", position: "relative", bottom: "85px" }}
      >
        <div className="card-body">
          {/* <div className="card shadow" style={{ backgroundColor: "" }}>
            <div className="card-body">
              <div
                className="d-flex justify-content-between"
                style={{ fontSize: "13px" }}
              >
                <div>
                  <small
                    onClick={() => handleshow("winrate")}
                    className={pagewin ? "text-primary" : ""}
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                  >
                    <i className="far fa-star"></i>
                    Rank
                  </small>
                  {pagewin ? (
                    <>
                      <hr className="text-primary" />
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <small
                    onClick={() => handleshow("topscore")}
                    className={pagescore ? "text-primary" : ""}
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                  >
                    <i className="far fa-futbol"></i>Top score
                  </small>
                  {pagescore ? (
                    <>
                      <hr className="text-primary" />
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <div>
                  <small
                    onClick={() => handleshow("topkiper")}
                    className={pagekiper ? "text-primary" : ""}
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                  >
                    {" "}
                    <i className="fas fa-hands"></i>Top kiper
                  </small>
                  {pagekiper ? (
                    <>
                      <hr className="text-primary" />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {pagewin ? (
                <>
                  <Comppoint />
                </>
              ) : pagescore ? (
                <>
                  <Comptopscore2 />
                </>
              ) : pageasist ? (
                <>
                  <Comptopassist />
                </>
              ) : pagekiper ? (
                <>
                  <Comptopkiper />
                </>
              ) : (
                <>
                  <hr />
                  <p className="text-center text-secondary">
                    Bermain sebanyak mungkin untuk meningkatkan rank solo anda
                  </p>
                </>
              )}
            </div>
          </div> */}

          <div className="mt-3">
            {member.status_pembayaran == 201 ? (
              <>
                <div class="alert alert-warning" role="alert">
                  <small className="fw-bold">
                    Pembayaran rank solo anda belum disetujui, mohon untuk
                    menunggu persetujuan pembayaran
                  </small>
                </div>
              </>
            ) : member.status_pembayaran == 400 ? (
              <>
                {" "}
                <div class="alert alert-danger" role="alert">
                  Pembayaran rank solo anda ditolak,
                </div>
              </>
            ) : (
              <></>
            )}
            {game == "" || tglday > game.tgl_main ? (
              <>
                <div class="alert alert-danger  border-0" role="alert">
                  <h5 className="text-center">
                    <i className="fas fa-warning"></i>
                  </h5>
                  <div className="text-center fw-bold">
                    Slot rank solo ditutup, harap menunggu rank solo
                    selanjutnya.
                  </div>
                </div>
              </>
            ) : (
              <>
                {" "}
                <div class="alert alert-primary" role="alert">
                  {kuota == "full" ? (
                    <strong>
                      Hello, Slot rank solo sudah penuh, cobalah di lain hari
                    </strong>
                  ) : (
                    <strong>
                      Slot rank solo dibuka, ayoo daftar sekarang juga
                    </strong>
                  )}
                </div>
              </>
            )}
          </div>
          <div
            className={
              game == "" || tglday > game.tgl_main ? "border-0" : "card mt-2"
            }
            style={{ height: "100%" }}
          >
            {game == "" || tglday > game.tgl_main ? (
              <div className="container my-4"></div>
            ) : (
              <>
                {" "}
                <div className="card-body">
                  <p className="text-secondary"></p>
                  <div className="alert alert-primary" role="alert">
                    <strong>Jadwal rank solo</strong>

                    <hr />
                    <div class="d-flex justify-content-between">
                      <div style={{ fontSize: "11px" }}>
                        Tanggal main <br></br>
                        {getfun.tgl_main}
                      </div>
                      <div style={{ fontSize: "11px", textAlign: "right" }}>
                        Waktu rank solo <br></br>
                        {getsesi.map((ss, index) => {
                          return (
                            <div key={index}>
                              <small>
                                Sesi {ss.sesi} : {ss.jam_mulai} -{" "}
                                {ss.jam_selesai} WIB
                              </small>
                              <br></br>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <hr></hr>
                    <div class="d-flex justify-content-between">
                      <div style={{ fontSize: "11px" }}>
                        Harga booking <br></br>
                        {getfun.harga}
                      </div>

                      <div style={{ fontSize: "11px", textAlign: "right" }}>
                        Lapangan<br></br>
                        {getfun.lapangan}
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      localStorage.getItem("tamu") !== null ? "d-none" : ""
                    }
                  >
                    {member.status_pembayaran == 203 ? (
                      <></>
                    ) : (
                      <>
                        {" "}
                        {kuota == "kosong" || kuota == "full" ? (
                          <>
                            {posisi == "" ? (
                              <>
                                <center>
                                  <img
                                    src="img/posisi.png"
                                    class="img-fluid mb-3"
                                    alt="Responsive image"
                                    style={{ height: "200px" }}
                                  ></img>
                                  <p>
                                    <strong className="text-success">
                                      Hello {localStorage.getItem("nama")}
                                    </strong>
                                    <br />
                                    posisi bermain anda belum di isi, mohon
                                    untuk mengisi posisi bermain anda untuk
                                    mendaftar sebagai rank solo
                                  </p>
                                  <Compposisi
                                    title={"Atur posisi anda sekarang"}
                                  />
                                </center>
                              </>
                            ) : (
                              <>
                                {posisi == "Penjaga gawang" &&
                                kuotpenjagagawang == "penjaga gawang full" &&
                                localStorage.getItem("id") !=
                                  member2.id_user ? (
                                  <>
                                    <center>
                                      <img
                                        src="img/goal.png"
                                        class="img-fluid mb-3"
                                        alt="Responsive image"
                                        style={{ height: "200px" }}
                                      ></img>
                                      <p>
                                        <strong className="text-success">
                                          Hello {localStorage.getItem("nama")}
                                        </strong>
                                        <br />
                                        untuk posisi penjaga gawang sudah penuh,
                                        jika ingin tetap bermain silahkan ubah
                                        posisi anda
                                      </p>
                                      <Compposisi title={"Ubah posisi anda"} />
                                    </center>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="card mb-2 shadow"
                                      style={{ border: "none" }}
                                    >
                                      <div className="card-body">
                                        <small
                                          className="fw-bold"
                                          style={{ textAlign: "left" }}
                                        >
                                          <i className="fas fa-circle-info"></i>{" "}
                                          Hello {localStorage.getItem("nama")},
                                          Pilih sesi bermain rank solo anda
                                          sekarang juga
                                        </small>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        member != "" || member2 != ""
                                          ? "card mb-3"
                                          : "d-none"
                                      }
                                    >
                                      <div className="card-header">
                                        <small className="fw-bold">
                                          Booking rank solo
                                        </small>
                                      </div>
                                      {member != "" && member.sesi == 1 ? (
                                        <>
                                          {" "}
                                          <div className="card-body">
                                            <span className="badge bg-success mb-2">
                                              <small>Rank solo sesi 1</small>
                                            </span>

                                            <div class="d-flex justify-content-between">
                                              <small className="fw-bold">
                                                <i className="far fa-clock"></i>{" "}
                                                {member.jam_mulai} -{" "}
                                                {member.jam_selesai} WIB
                                              </small>

                                              <small className="fw-bold">
                                                <i className="far fa-calendar-days"></i>{" "}
                                                {member.tgl_main}
                                              </small>
                                            </div>

                                            <hr />
                                            {member.status_pembayaran == 201 ? (
                                              <>
                                                <center>
                                                  <small className="text-center text-warning fw-bold">
                                                    Menunggu proses persetujan
                                                    pembayaran
                                                  </small>
                                                </center>
                                              </>
                                            ) : member.status_pembayaran ==
                                              400 ? (
                                              <>
                                                <center>
                                                  <small className="text-danger text-center">
                                                    Pembayaran anda ditolak
                                                  </small>
                                                </center>
                                              </>
                                            ) : member.status_pembayaran ==
                                              200 ? (
                                              <>
                                                {" "}
                                                <div class="row mt-2">
                                                  <div className="col-sm-4 col-4">
                                                    <Link
                                                      to={
                                                        "/pemainmemberkariranda/" +
                                                        member.kode_main +
                                                        "/" +
                                                        member.team +
                                                        "/" +
                                                        member.lawan
                                                      }
                                                      style={{
                                                        textDecoration: "none",
                                                      }}
                                                    >
                                                      <center>
                                                        <img
                                                          src={
                                                            member.team ==
                                                            "Merah"
                                                              ? "/img/merah.png"
                                                              : member.team ==
                                                                "Kuning"
                                                              ? "/img/kuning.png"
                                                              : member.team ==
                                                                "Hitam"
                                                              ? "/img/hitam.png"
                                                              : "/img/putih.png"
                                                          }
                                                          className="img-fluid"
                                                          alt=""
                                                          style={{
                                                            borderRadius: "5px",
                                                            height: "50px",
                                                          }}
                                                        ></img>
                                                        <br />
                                                        <small
                                                          className="fw-bold"
                                                          style={{
                                                            fontSize: "12px",
                                                          }}
                                                        >
                                                          Team Anda
                                                        </small>

                                                        <Compusers />
                                                      </center>
                                                    </Link>
                                                  </div>
                                                  <div className="col-sm-4 col-4">
                                                    <center>
                                                      <img
                                                        src="/img/logomms.png"
                                                        className="img-fluid"
                                                        alt=""
                                                        style={{
                                                          borderRadius: "5px",
                                                          height: "30px",
                                                        }}
                                                      ></img>
                                                    </center>
                                                  </div>
                                                  <div className="col-sm-4 col-4">
                                                    <Link
                                                      to={
                                                        "/pemainmemberkariranda/" +
                                                        member.kode_main +
                                                        "/" +
                                                        member.team +
                                                        "/" +
                                                        member.lawan
                                                      }
                                                      style={{
                                                        textDecoration: "none",
                                                      }}
                                                    >
                                                      <center>
                                                        <img
                                                          src={
                                                            member.lawan ==
                                                            "Merah"
                                                              ? "/img/merah.png"
                                                              : member.lawan ==
                                                                "Kuning"
                                                              ? "/img/kuning.png"
                                                              : member.lawan ==
                                                                "Hitam"
                                                              ? "/img/hitam.png"
                                                              : "/img/putih.png"
                                                          }
                                                          className="img-fluid"
                                                          alt=""
                                                          style={{
                                                            borderRadius: "5px",
                                                            height: "50px",
                                                          }}
                                                        ></img>
                                                        <br />
                                                        <small
                                                          className="fw-bold"
                                                          style={{
                                                            fontSize: "12px",
                                                          }}
                                                        >
                                                          Team lawan
                                                        </small>
                                                        <Compusers />
                                                      </center>
                                                    </Link>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {member2 != "" && member2.sesi == 2 ? (
                                        <>
                                          <div className="card-body">
                                            <span className="badge bg-success mb-2">
                                              <small>Rank solo sesi 2</small>
                                            </span>
                                            <div class="d-flex justify-content-between">
                                              <small className="fw-bold">
                                                <i className="far fa-clock"></i>{" "}
                                                {member2.jam_mulai} -{" "}
                                                {member2.jam_selesai} WIB
                                              </small>

                                              <small className="fw-bold">
                                                <i className="far fa-calendar-days"></i>{" "}
                                                {member2.tgl_main}
                                              </small>
                                            </div>

                                            <hr />
                                            {member2.status_pembayaran ==
                                            201 ? (
                                              <>
                                                <center>
                                                  <small className="text-center text-warning">
                                                    Menunggu proses persetujan
                                                    pembayaran erer
                                                  </small>
                                                </center>
                                              </>
                                            ) : member2.status_pembayaran ==
                                              400 ? (
                                              <>
                                                <center>
                                                  <small className="text-danger text-center">
                                                    Pembayaran anda ditolak
                                                  </small>
                                                </center>
                                              </>
                                            ) : member2.status_pembayaran ==
                                              200 ? (
                                              <>
                                                {" "}
                                                <div class="row mt-2">
                                                  <div className="col-sm-4 col-4">
                                                    <Link
                                                      to={
                                                        "/pemainmemberkariranda/" +
                                                        member2.kode_main +
                                                        "/" +
                                                        member2.team +
                                                        "/" +
                                                        member2.lawan
                                                      }
                                                      style={{
                                                        textDecoration: "none",
                                                      }}
                                                    >
                                                      <center>
                                                        <img
                                                          src={
                                                            member2.team ==
                                                            "Merah"
                                                              ? "/img/merah.png"
                                                              : member2.team ==
                                                                "Kuning"
                                                              ? "/img/kuning.png"
                                                              : member2.team ==
                                                                "Hitam"
                                                              ? "/img/hitam.png"
                                                              : "/img/putih.png"
                                                          }
                                                          className="img-fluid"
                                                          alt=""
                                                          style={{
                                                            borderRadius: "5px",
                                                            height: "50px",
                                                          }}
                                                        ></img>
                                                        <br />
                                                        <small
                                                          className="fw-bold"
                                                          style={{
                                                            fontSize: "12px",
                                                          }}
                                                        >
                                                          Team Anda
                                                        </small>

                                                        <Compusers />
                                                      </center>
                                                    </Link>
                                                  </div>
                                                  <div className="col-sm-4 col-4">
                                                    <center>
                                                      <img
                                                        src="/img/logomms.png"
                                                        className="img-fluid"
                                                        alt=""
                                                        style={{
                                                          borderRadius: "5px",
                                                          height: "30px",
                                                        }}
                                                      ></img>
                                                    </center>
                                                  </div>
                                                  <div className="col-sm-4 col-4">
                                                    <Link
                                                      to={
                                                        "/pemainmemberkariranda/" +
                                                        member2.kode_main +
                                                        "/" +
                                                        member2.team +
                                                        "/" +
                                                        member2.lawan
                                                      }
                                                      style={{
                                                        textDecoration: "none",
                                                      }}
                                                    >
                                                      <center>
                                                        <img
                                                          src={
                                                            member2.lawan ==
                                                            "Merah"
                                                              ? "/img/merah.png"
                                                              : member2.lawan ==
                                                                "Kuning"
                                                              ? "/img/kuning.png"
                                                              : member2.lawan ==
                                                                "Hitam"
                                                              ? "/img/hitam.png"
                                                              : "/img/putih.png"
                                                          }
                                                          className="img-fluid"
                                                          alt=""
                                                          style={{
                                                            borderRadius: "5px",
                                                            height: "50px",
                                                          }}
                                                        ></img>
                                                        <br />
                                                        <small
                                                          className="fw-bold"
                                                          style={{
                                                            fontSize: "12px",
                                                          }}
                                                        >
                                                          Team lawan
                                                        </small>
                                                        <Compusers />
                                                      </center>
                                                    </Link>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>{" "}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>

                                    <div className="row mb-3">
                                      {getsesi.map((ss, index) => {
                                        return (
                                          <div
                                            className="col-sm-6 col-6"
                                            key={index}
                                          >
                                            {ss.sesi == kuoatasesi.sesi_full ||
                                            ss.sesi == bookspesial.jam_mulai ||
                                            kuoatasesi.sesi_full == "all" ||
                                            ss.status == 1 ? (
                                              <>
                                                <div
                                                  className="card shadow"
                                                  onClick={() => notify2()}
                                                  id="bglistwinrate"
                                                >
                                                  <div className="card-body hidden">
                                                    <small className="fw-bold">
                                                      Sesi {ss.sesi}
                                                    </small>
                                                    <br></br>
                                                    <small
                                                      className="text-secondary"
                                                      style={{
                                                        fontSize: "10px",
                                                      }}
                                                    >
                                                      <i className="fas fa-users"></i>{" "}
                                                      Sesi full
                                                    </small>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                {" "}
                                                <div
                                                  className={
                                                    sesimain == ss.sesi
                                                      ? "card shadow border-primary"
                                                      : "card shadow"
                                                  }
                                                  onClick={() =>
                                                    setSesimain(ss.sesi)
                                                  }
                                                  id="bglistwinrate"
                                                >
                                                  <div className="card-body hidden">
                                                    <small className="fw-bold">
                                                      Sesi {ss.sesi}
                                                    </small>
                                                    <br></br>
                                                    <small
                                                      className="text-secondary"
                                                      style={{
                                                        fontSize: "10px",
                                                      }}
                                                    >
                                                      {ss.sesi == 1 ? (
                                                        <>
                                                          {localStorage.getItem(
                                                            "id"
                                                          ) == member.id_user &&
                                                          member.sesi ==
                                                            ss.sesi &&
                                                          ss.kode_main ==
                                                            member.kode_main ? (
                                                            <>Booked</>
                                                          ) : (
                                                            <>
                                                              <i className="fas fa-clock"></i>{" "}
                                                              {ss.jam_mulai} -{" "}
                                                              {ss.jam_selesai}{" "}
                                                              WIB
                                                            </>
                                                          )}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {localStorage.getItem(
                                                            "id"
                                                          ) ==
                                                            member2.id_user &&
                                                          member2.sesi ==
                                                            ss.sesi &&
                                                          ss.kode_main ==
                                                            member2.kode_main ? (
                                                            <>Booked</>
                                                          ) : (
                                                            <>
                                                              <i className="fas fa-clock"></i>{" "}
                                                              {ss.jam_mulai} -{" "}
                                                              {ss.jam_selesai}{" "}
                                                              WIB
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </small>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                    <center>
                                      <img
                                        src="img/medal.png"
                                        class="img-fluid mb-3"
                                        alt="Responsive image"
                                        style={{ height: "200px" }}
                                      ></img>
                                      <p>
                                        <strong className="text-success">
                                          Hello {localStorage.getItem("nama")}
                                        </strong>
                                        <br></br>
                                        sekarang anda dapat melakukan daftar
                                        rank solo
                                      </p>

                                      {sesimain == "" ? (
                                        <>
                                          <button
                                            className="btn w-100 rounded-pill"
                                            onClick={() => notify()}
                                            style={{
                                              backgroundColor: "#2b2e5a",
                                              color: "white",
                                            }}
                                          >
                                            Bergabung sekarang
                                          </button>
                                        </>
                                      ) : sesimain == member.sesi ? (
                                        <>
                                          <button
                                            className="btn w-100 rounded-pill"
                                            onClick={() => notify()}
                                            style={{
                                              backgroundColor: "#2b2e5a",
                                              color: "white",
                                            }}
                                          >
                                            Bergabung sekarang
                                          </button>
                                        </>
                                      ) : sesimain == member2.sesi ? (
                                        <>
                                          <button
                                            className="btn w-100 rounded-pill"
                                            onClick={() => notify()}
                                            style={{
                                              backgroundColor: "#2b2e5a",
                                              color: "white",
                                            }}
                                          >
                                            Bergabung sekarang
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <Comppembayaran
                                            harga={getfun.harga}
                                            posisi={posisi}
                                            sesi={sesimain}
                                          />
                                        </>
                                      )}

                                      <ToastContainer />
                                    </center>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <center>
                              <img
                                src="img/people.png"
                                class="img-fluid mb-3"
                                alt="Responsive image"
                                style={{ height: "200px" }}
                              ></img>

                              <h5 className="text-primary fw-bold">
                                Hello {localStorage.getItem("nama")}, Rank solo
                                penuh !! <br></br>
                                Cobolah di lain hari
                              </h5>
                            </center>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className={localStorage.getItem("tamu") !== null ? "" : "d-none"}
          >
            <div className="container">
              <center>
                <img
                  src="img/document.png"
                  class="img-fluid mb-3"
                  alt="Responsive image"
                  style={{ height: "200px" }}
                ></img>

                <p style={{ color: "#2b2e5a" }}>
                  Anda dalam mode tamu, untuk mendaftar sebagai rank solo anda
                  harus mendaftar akun medan mini soccer anda terlebih dahulu
                </p>

                <button
                  className="btn mt-1"
                  onClick={() => daftar()}
                  style={{
                    backgroundColor: "#2b2e5a",
                    color: "white",
                    borderRadius: "20px",
                  }}
                >
                  Daftar akun medan mini soccer
                </button>
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
