import React from "react";
import { useState } from "react";
import Comppoint from "../Memberkarir/Comppoint";
import Comptopscore2 from "../Memberkarir/Comptopscore2";
import Comptopassist from "../Memberkarir/Comptopassist";
import Comptopkiper from "../Memberkarir/Comptopkiper";

export default function Compmainranksolo() {
  const [pagewin, setPagewin] = useState(true);
  const [pagescore, setPagescore] = useState(false);
  const [pageasist, setPageasist] = useState(false);
  const [pagekiper, setPagekiper] = useState(false);

  const handleshow = (page) => {
    if (page == "winrate") {
      setPagewin(true);
      setPagescore(false);
      setPageasist(false);
      setPagekiper(false);
    } else if (page == "topscore") {
      setPagescore(true);
      setPageasist(false);
      setPagewin(false);
      setPagekiper(false);
    } else if (page == "topassist") {
      setPageasist(true);
      setPagewin(false);
      setPagescore(false);
      setPagekiper(false);
    } else if (page == "topkiper") {
      setPageasist(false);
      setPagewin(false);
      setPagescore(false);
      setPagekiper(true);
    }
  };

  return (
    <div>
      <div className="container">
        <div>
          <br />
          <div className="card mt-5 border-0 mb-2" id="bggradient1">
            <div className="card-body">
              <h5 className="text-white fw-bold">Ranking rank solo</h5>
              <small className="text-white">
                Hasil ranking rank solo medan mini soccer
              </small>
            </div>
          </div>
          <div className="card mb-2">
            <div className="card-body">
              <div
                className="d-flex justify-content-between"
                style={{ fontSize: "13px" }}
              >
                <div>
                  <small
                    onClick={() => handleshow("winrate")}
                    className={pagewin ? "text-primary" : ""}
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                  >
                    <i className="far fa-star"></i>
                    Rank
                  </small>
                  {pagewin ? (
                    <>
                      <hr className="text-primary" />
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <div>
                  <small
                    onClick={() => handleshow("topscore")}
                    className={pagescore ? "text-primary" : ""}
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                  >
                    <i className="far fa-futbol"></i>Top score
                  </small>
                  {pagescore ? (
                    <>
                      <hr className="text-primary" />
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <div>
                  <small
                    onClick={() => handleshow("topkiper")}
                    className={pagekiper ? "text-primary" : ""}
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                  >
                    {" "}
                    <i className="fas fa-hands"></i>Top kiper
                  </small>
                  {pagekiper ? (
                    <>
                      <hr className="text-primary" />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {pagewin ? (
                <>
                  <Comppoint />
                </>
              ) : pagescore ? (
                <>
                  <Comptopscore2 />
                </>
              ) : pageasist ? (
                <>
                  <Comptopassist />
                </>
              ) : pagekiper ? (
                <>
                  <Comptopkiper />
                </>
              ) : (
                <>
                  <hr />
                  <p className="text-center text-secondary">
                    Bermain sebanyak mungkin untuk meningkatkan rank solo anda
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
