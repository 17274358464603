import React from "react";
import Navbar from "../componenst/Navbar";
import Compmain from "../componenst/History/Compmain";

export default function History() {
  return (
    <div>
      <Navbar
        judul="History rank solo
      "
        aicon="true"
      />
      <Compmain />
    </div>
  );
}
