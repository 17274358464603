import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Compassist({ id_user }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [assist, setAssist] = useState(0);

  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi + "Jmlassistmemberkarir?id_user=" + id_user
      );
      setAssist(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getdata();
  }, []);
  return (
    <div>
      <div className="container my-5">
        <center>
          <img
            src="/img/emblem.png"
            className="img-fluid"
            alt="Responsive image"
            style={{ height: "200px" }}
          ></img>
          <h5 className="mt-3 fw-bold">Assist yang di proleh sebanyak</h5>
          <h1 className="fw-bold text-success">{assist.jml} Assist</h1>
        </center>
      </div>
    </div>
  );
}
