import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Complogoteamrank({ team }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [logo, setLogo] = useState("");
  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi + "Logoteamrankteam?team=" + team
      );
      // console.log(response.data);
      setLogo(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    getdata();
  }, [team]);

  return (
    <div>
      {logo.logo_team != null ? (
        <>
          {" "}
          <img
            src={logo.logo_team}
            className="img-fluid rounded-circle"
            alt="logo"
            style={{ height: "50px", width: "50px", border: "5px solid white" }}
          />
        </>
      ) : (
        <>
          <img
            src="/img/club.png"
            className="img-fluid rounded-circle"
            alt="logo"
            style={{ height: "50px", width: "50px", border: "5px solid white" }}
          />
        </>
      )}
    </div>
  );
}
