import React from "react";
import Navbar from "../componenst/Navbar";
import Compmaindatateam from "../componenst/Datateamrankteam/Compmaindatateam";

export default function Datateamrankteam() {
  return (
    <div>
      <Navbar judul="Data team" aicon="true" />
      <Compmaindatateam />
    </div>
  );
}
