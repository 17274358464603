import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function Compaddpemain() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [nama, setNama] = useState("");
  const [nickname, setNickname] = useState("");
  const [nik, setNik] = useState("");
  const [nomorpunggung, setNomorpunggung] = useState("");
  const [posisi, setPosisi] = useState("");
  const empty = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
  ];
  const [idrow, setIdrow] = useState(0);
  const [pemain, setPemain] = useState([]);
  const [jmlPemain, setJmlpemain] = useState(0);
  const [notifnik, setNotifnik] = useState(false);
  const [panjangnik, setPanjangnik] = useState("");
  const [kuotapemain, setKuotapemain] = useState(0);
  const [pageedit, setPageedit] = useState(false);
  const [cekbookingteam, setCekbookingteam] = useState("");
  const [teamrank, setTeamrank] = useState([]);
  const [pointteam, setPointteam] = useState([]);
  const [kuota, setKuota] = useState(0);
  const [statusteam, setStatusteam] = useState(1);
  const [jml, setJml] = useState(25);
  const [addplaysesion2, setAddplaysesion2] = useState(0)

  const { slide } = useParams();

  const notify = () =>
    toast.success("Data pemain berhasil di tambah !", {
      position: toast.POSITION.TOP_CENTER,
    });

  const notify2 = () =>
    toast.error("Isi terlebih dahulu nama team anda", {
      position: toast.POSITION.TOP_CENTER,
    });

  const notifkuota = () =>
    toast.error("Data pemain belum mencukupi untuk bermain rank team !", {
      position: toast.POSITION.TOP_CENTER,
    });
  const notifkuota2 = () =>
    toast.error(
      "Data pemain anda melebih kuota, hapus pemain rank team anda !",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );

  const notify3 = () =>
    toast.error("Data team anda belum disetujui oleh admin !", {
      position: toast.POSITION.TOP_CENTER,
    });

  const notifhapus = () =>
    toast.success("Pemain berhasil di hapus !", {
      position: toast.POSITION.TOP_CENTER,
    });

  const getPemain = async () => {
    try {
      const response = await axios.get(
        urlapi + "Pemainrankteam?id_user=" + localStorage.getItem("id")
      );
      // console.log(response.data);
      setPemain(response.data);
    } catch (error) {}
  };

  const addPemain = async () => {
    await axios
      .post(urlapi + "Pemainrankteam", {
        rowid: idrow,
        id_user: localStorage.getItem("id"),
        nama_pemain: nama,
        posisi: posisi,
        no_punggung: nomorpunggung,
        nik: nik,
        nickname: nickname,
      })
      .then((response) => {
        console.log(response.data);
        notify();
        getPemain();
        cekJmlpemain();
        setIdrow(0);
        setNama("");
        setNickname("");
        setNik("");
        setNomorpunggung("");
        setPosisi("");
        setNotifnik(false);
        setPanjangnik("");
        getjmlpemain();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const editPemain = async () => {};

  const cekJmlpemain = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Pemainrankteam2?jmlpemain=true" +
          "&&id_user=" +
          localStorage.getItem("id")
      );

      console.log(response.data.jml);
      setJmlpemain(response.data.jml);
      setAddplaysesion2(response.data.data.tambah_pemain);

       if (response.data.data.tambah_pemain == 1 ) {
        setJml(response.data.jml + 2);
      } else {
        setJml(25);
      }

      // if (response.data >= kuotapemain) {
      //   setJmlpemain(25);
      // } else {
      //   setJmlpemain(response.data);
      // }
      // console.log(response.data);
      // console.log(response.data);
    } catch (error) {}
  };

  const handlerow = (id) => {
    setIdrow(id);
  };

  const handlenik = (e) => {
    setNik(e);
    ceknik(e);
    if (e.length == 16) {
      setPanjangnik("true");
    } else if (e.length > 16) {
      setPanjangnik("false1");
    } else if (e.length < 16) {
      setPanjangnik("false2");
    }
  };

  const ceknik = async (nik) => {
    try {
      const response = await axios.get(urlapi + "Ceknik?nik=" + nik);
      // console.log(response.data);
      if (response.data == "") {
        setNotifnik(false);
      } else {
        setNotifnik(true);
      }
    } catch (error) {
      setNotifnik(false);
    }
  };

  const cekteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Cekbookingteam?id_user=" + localStorage.getItem("id")
      );
      setCekbookingteam(response.data.id_user);
      // console.log(response.data.id_user);
    } catch (error) {}
  };

  const cekpointeam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Pointrankteam?id_user=" + localStorage.getItem("id")
      );
      setPointteam(response.data);
    } catch (error) {}
  };

  const jmlPememainrankteam = async () => {
    try {
      const response = await axios.get(urlapi + "Jmlpemainrankteam");
      // console.log(response.data);
      setKuotapemain(response.data.jml);
    } catch (error) {}
  };

  const hapuspemain = async (id) => {
    try {
      const response = await axios.get(urlapi + "Hapuspemain?id=" + id);
      // console.log(response.data);
      notifhapus();
      getPemain();
      jmlPememainrankteam();
      cekJmlpemain();
      cekpointeam();
      getjmlpemain();
    } catch (error) {}
  };

  const getteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Datateam?id_user=" + localStorage.getItem("id")
      );
      setTeamrank(response.data);
    } catch (error) {}
  };

  const getjmlpemain = async () => {
    try {
      const response = await axios.get(
        urlapi + "Jmlpemainteam?id=" + localStorage.getItem("id")
      );

      // console.log(response.data);
      if (response.data.jml >= 25) {
        setKuota(response.data.jml);
      } else {
        setKuota(0);
      }
    } catch (error) {}
  };

  const cekstatusteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Cekstatusteam?id=" + localStorage.getItem("id")
      );
      // console.log(response.data);
      setStatusteam(response.data.status);
    } catch (error) {}
  };

  useEffect(() => {
    getPemain();
    cekJmlpemain();
    jmlPememainrankteam();
    cekteam();
    getteam();
    cekpointeam();
    getjmlpemain();
    cekstatusteam();
  }, []);

  return (
    <div>
      <div className="alert alert-primary">
        <small className="fw-bold">
          <i className="fas fa-circle-info"></i> Jumlah maksimal pemain yang di
          daftarkan sebanyak 25 pemain di rank team {addplaysesion2}
        </small>
      </div>
      <div className="alert alert-primary">
        <small className="fw-bold">
          <i className="fas fa-circle-info"></i> Team yang sudah disetujui tidak
          dapat menambah pemain dan mengubah pemain
        </small>
      </div>

      {statusteam == 0 ? (
        <>
          <div className="alert alert-warning text-center">
            <h5>
              <i className="fas fa-warning"></i>
            </h5>
            <small className="fw-bold">
              Team anda belum dapat bermain rank team, mohon untuk menunggu
              persetujuan dari admin
            </small>
          </div>
        </>
      ) : statusteam == 1 ? (
        <>
          <div className="alert alert-success text-center">
            <h5>
              <i className="fas fa-circle-check"></i>
            </h5>
            <small className="fw-bold">
              Data team anda sudah disetujui, anda dapat melakukan bermain rank
              team sekarang
            </small>
          </div>
        </>
      ) : (
        <></>
      )}

      {pemain.map((data, index) => {
        return (
          <div key={index}>
            <div className="d-flex justify-content-between">
              <div>
                <Link
                  to={"/profilpemainteam/" + data.id}
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src="http://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png"
                    style={{ height: "50px" }}
                    class="img-fluid"
                    alt="Responsive image"
                  ></img>
                  {/* <small className="fw-bold">{data.no_punggung}</small> */}
                  {"   "}
                  <label
                    className="text-secondary"
                    style={{ fontSize: "12px" }}
                  >
                    {data.nama}
                  </label>
                  <br></br>
                  {/* <small className="text-secondary">{data.posisi}</small> */}
                  <small
                    className="text-secondary"
                    style={{ fontSize: "10px" }}
                  >
                    NIK : {data.nik} <div>{data.posisi}</div>
                  </small>
                </Link>

                {statusteam == 0 && addplaysesion2 == 0 ? (
                  <>
                    <span
                      class="badge bg-danger"
                      onClick={() => hapuspemain(data.id)}
                    >
                      <i className="fas fa-trash"></i>
                    </span>{" "}
                    <span class="badge bg-primary">
                      <Link
                        to={"/editpemain/" + data.id}
                        style={{ color: "#ffff" }}
                      >
                        <i className="fas fa-pen"></i>
                      </Link>
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div>
                <small>
                  {" "}
                  <i className="fas fa-angle-right text-secondary"></i>
                </small>
              </div>
            </div>

            <div className={pageedit == true ? "row" : "d-none"}>
              <div className="col-sm-6 col-6 mt-2">
                <div className="form-group">
                  <label>Nama</label>
                  <input
                    type="text"
                    value={data.nama}
                    className="form-control form-control-sm"
                    onChange={(e) => setNama(e.target.value)}
                  />
                </div>
              </div>
              {/* <div className="col-sm-6 col-6 mt-2">
                <div className="form-group">
                  <label>Nickname</label>
                  <input
                    type="text"
                    value={nickname}
                    className="form-control form-control-sm"
                    onChange={(e) => setNickname(e.target.value)}
                  />
                </div>
              </div> */}

              <div className="col-sm-6 col-6 mt-2">
                <div className="form-group">
                  <label>NIK</label>
                  <input
                    type="number"
                    value={data.nik}
                    onChange={(e) => handlenik(e.target.value)}
                    className="form-control  form-control-sm"
                  />
                  {notifnik == true ? (
                    <>
                      <small className="text-danger">
                        Nik yang anda masukan sudah terdaftar
                      </small>
                    </>
                  ) : (
                    ""
                  )}

                  {panjangnik == "true" ? (
                    <>
                      <small className="text-success">
                        {" "}
                        Panjang nik sudah benar{" "}
                      </small>
                    </>
                  ) : panjangnik == "false1" ? (
                    <>
                      <small className="text-danger">
                        Nik terlalu panjang{" "}
                      </small>
                    </>
                  ) : panjangnik == "false2" ? (
                    <>
                      <small className="text-danger">Nik terlalu pendek </small>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* <div className="col-sm-6 col-6 mt-2">
                <div className="form-group">
                  <label>No Punggung</label>
                  <input
                    type="number"
                    value={data.no_punggung}
                    className="form-control form-control-sm"
                    onChange={(e) => setNomorpunggung(e.target.value)}
                  />
                </div>
              </div> */}

              <div className="col-sm-6 col-6 mt-2">
                <div className="form-group">
                  <label>Posisi</label>
                  <select
                    className="form-control form-control-sm"
                    onChange={(e) => setPosisi(e.target.value)}
                  >
                    <option>{data.posisi}</option>
                    <option>-- Pilih Posisi --</option>
                    <option>Penyerang</option>
                    <option>Gelandang serang</option>
                    <option>Gelandang sayap</option>
                    <option>Gelandang tengah</option>
                    <option>Bek sayap</option>
                    <option>Bek tangah</option>
                    <option>Penjaga gawang</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-6 col-6">
                <div className="mt-4">
                  <button
                    disabled={
                      notifnik == true ||
                      panjangnik == "false1" ||
                      panjangnik == "false2"
                        ? true
                        : false
                    }
                    className="btn btn-primary btn-sm mt-2"
                    onClick={() => editPemain()}
                  >
                    Edit pemain
                  </button>
                </div>
              </div>
            </div>
            <hr />
          </div>
        );
      })}

      {teamrank == false && slide == null ? (
        <>
          {" "}
          {empty.slice(0, jml - jmlPemain).map((data, index) => {
            return (
              <div key={index}>
                <div className="d-flex justify-content-between">
                  <div onClick={() => notify2()}>
                    <img
                      src="http://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png"
                      style={{ height: "50px" }}
                      class="img-fluid"
                      alt="Responsive image"
                    ></img>
                    <small className="fw-bold"></small>
                    {"   "}
                    <small
                      className="text-secondary"
                      style={{ fontSize: "12px" }}
                    >
                      <i className="far fa-user"></i> Tambah pemain
                    </small>

                    <br></br>
                    {/* <small className="text-secondary">{data.posisi}</small> */}
                    <small
                      className="text-secondary"
                      style={{ fontSize: "10px" }}
                    >
                      NIK : xxxxxxxxxx
                    </small>
                  </div>
                  <div onClick={() => handlerow(0)}>
                    <small>
                      {" "}
                      <i
                        className={
                          data == idrow
                            ? "fas fa-angle-down text-secondary"
                            : "fas fa-angle-right text-secondary"
                        }
                      ></i>
                    </small>
                  </div>
                </div>

                <hr />
              </div>
            );
          })}
        </>
      ) : (
        <>
          {" "}
          {statusteam == 0 ? (
            <>
              {empty.slice(0, jml - jmlPemain).map((data, index) => {
                return (
                  <div key={index}>
                    <div className="d-flex justify-content-between">
                      <div onClick={() => handlerow(data)}>
                        <img
                          src="http://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png"
                          style={{ height: "50px" }}
                          class="img-fluid"
                          alt="Responsive image"
                        ></img>
                        <small className="fw-bold"></small>
                        {"   "}
                        <small
                          className="text-secondary"
                          style={{ fontSize: "12px" }}
                        >
                          <i className="far fa-user"></i> Tambah pemain
                        </small>

                        <br></br>
                        {/* <small className="text-secondary">{data.posisi}</small> */}
                        <small
                          className="text-secondary"
                          style={{ fontSize: "10px" }}
                        >
                          NIK : xxxxxxxxxx
                        </small>
                      </div>
                      <div onClick={() => handlerow(0)}>
                        <small>
                          {" "}
                          <i
                            className={
                              data == idrow
                                ? "fas fa-angle-down text-secondary"
                                : "fas fa-angle-right text-secondary"
                            }
                          ></i>
                        </small>
                      </div>
                    </div>
                    <div>
                      {idrow == data ? (
                        <>
                          {" "}
                          <div className="row">
                            <div className="col-sm-6 col-6 mt-2">
                              <div className="form-group">
                                <label>Nama</label>
                                <input
                                  type="text"
                                  value={nama}
                                  className="form-control form-control-sm"
                                  onChange={(e) => setNama(e.target.value)}
                                />
                              </div>
                            </div>
                            {/* <div className="col-sm-6 col-6 mt-2">
                          <div className="form-group">
                            <label>Nickname</label>
                            <input
                              type="text"
                              value={nickname}
                              className="form-control form-control-sm"
                              onChange={(e) => setNickname(e.target.value)}
                            />
                          </div>
                        </div> */}

                            <div className="col-sm-6 col-6 mt-2">
                              <div className="form-group">
                                <label>NIK</label>
                                <input
                                  type="number"
                                  value={nik}
                                  onChange={(e) => handlenik(e.target.value)}
                                  className="form-control  form-control-sm"
                                />
                                {notifnik == true ? (
                                  <>
                                    <small className="text-danger">
                                      Nik yang anda masukan sudah terdaftar
                                    </small>
                                  </>
                                ) : (
                                  ""
                                )}

                                {panjangnik == "true" ? (
                                  <>
                                    <small className="text-success">
                                      {" "}
                                      Panjang nik sudah benar{" "}
                                    </small>
                                  </>
                                ) : panjangnik == "false1" ? (
                                  <>
                                    <small className="text-danger">
                                      Nik terlalu panjang{" "}
                                    </small>
                                  </>
                                ) : panjangnik == "false2" ? (
                                  <>
                                    <small className="text-danger">
                                      Nik terlalu pendek{" "}
                                    </small>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            {/* <div className="col-sm-6 col-6 mt-2">
                          <div className="form-group">
                            <label>No Punggung</label>
                            <input
                              type="number"
                              value={nomorpunggung}
                              className="form-control form-control-sm"
                              onChange={(e) => setNomorpunggung(e.target.value)}
                            />
                          </div>
                        </div> */}

                            <div className="col-sm-6 col-6 mt-2">
                              <div className="form-group">
                                <label>Posisi</label>
                                <select
                                  className="form-control form-control-sm"
                                  onChange={(e) => setPosisi(e.target.value)}
                                >
                                  <option>-- Pilih Posisi --</option>
                                  <option>Penyerang</option>
                                  <option>Gelandang serang</option>
                                  <option>Gelandang sayap</option>
                                  <option>Gelandang tengah</option>
                                  <option>Bek sayap</option>
                                  <option>Bek tangah</option>
                                  <option>Penjaga gawang</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-6 col-6">
                              <div className="mt-4">
                                <button
                                  disabled={
                                    nama == "" ||
                                    nik == "" ||
                                    notifnik == true ||
                                    panjangnik == "false1" ||
                                    panjangnik == "false2"
                                      ? true
                                      : false
                                  }
                                  className="btn btn-primary btn-sm mt-2 rounded-pill"
                                  style={{
                                    backgroundColor: "#2b2e5a",
                                    color: "#ffff",
                                  }}
                                  onClick={() => addPemain()}
                                >
                                  Tambah pemain
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <hr />
                  </div>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </>
      )}

      <ToastContainer />

      <br></br>
      <br></br>
      <br></br>
      <br></br>

      {statusteam == 0 ? (
        <>
          <div
            className="card fixed-bottom border-0 mt-5 shadow"
            style={{ borderRadius: "0px" }}
          >
            <div className="card-body">
              <div className="">
                <div className="row">
                  <div className="col-sm-3 col-0"></div>
                  <div className="col-sm-6 col-12">
                    {" "}
                    <button
                      className="btn  rounded-pill w-100"
                      onClick={() => notify3()}
                      style={{ backgroundColor: "#2b2e5a", color: "#ffff" }}
                    >
                      Bermain rank team sekarang
                    </button>
                  </div>
                  <div className="col-sm-3 col-0"></div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="card fixed-bottom mt-5 border-0  shadow"
            style={{ borderRadius: "0px" }}
          >
            <div className="card-body">
              <div className="row">
                <div className="col-sm-3 col-0"></div>
                <div className="col-sm-6 col-12">
                  {" "}
                  {kuota > kuotapemain ? (
                    <>
                      <button
                        className="btn  rounded-pill w-100"
                        onClick={() => notifkuota2()}
                        style={{ backgroundColor: "#2b2e5a", color: "#ffff" }}
                      >
                        Bermain rank team sekarang
                      </button>
                    </>
                  ) : (
                    <>
                      {teamrank == false ? (
                        <>
                          {" "}
                          <button
                            className="btn  rounded-pill w-100"
                            onClick={() => notify2()}
                            style={{
                              backgroundColor: "#2b2e5a",
                              color: "#ffff",
                            }}
                          >
                            Bermain rank team sekarang
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <a
                            href="/memberkarirteam"
                            className="btn  rounded-pill w-100"
                            style={{
                              backgroundColor: "#2b2e5a",
                              color: "#ffff",
                            }}
                          >
                            Bermain rank team sekarang
                          </a>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="col-sm-6 col-12"></div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
