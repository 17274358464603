import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Complogoteamrank from "../Rankleague/Complogoteamrank";

export default function Comprank({ pool }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [rank, setRank] = useState([]);
  const [point, setPoint] = useState([]);
  const [page, setPage] = useState(true);
  const [jml, setJml] = useState(10);

  const getPoint = async () => {
    try {
      const response = await axios.get(urlapi + "Pointrankteam?pool=" + pool);
      //   console.log(response.data);
      setPoint(response.data);
    } catch (error) {}
  };

  const handleShow = (status) => {
    if (status == false) {
      setPage(false);
      setJml(5000);
    } else {
      setPage(true);
      setJml(10);
    }
  };

  useEffect(() => {
    getPoint();
  }, [pool]);

  return (
    <div>
      <div>
        {page == true ? (
          <>
            {" "}
            <small className="text-secondary fw-bold mb-2">
              Posisi ranking 10 besar
            </small>
          </>
        ) : (
          <small className="text-secondary fw-bold mb-2">
            Posisi ranking rank team
          </small>
        )}

        <div className="table-responsive">
          <table class="table table-striped" style={{ fontSize: "12px" }}>
            <thead>
              <tr className="table-primary" style={{ fontSize: "10px" }}>
                <th
                  scope="col"
                  className="text-center"
                  style={{
                    borderTopLeftRadius: "10px",
                  }}
                >
                  No
                </th>
                <th scope="col">
                  <center>Team</center>
                </th>
                <th
                  className="text-dark fw-bold text-center"
                  scope="col"
                  style={{ width: "50px" }}
                >
                  P
                </th>
                <th
                  className="text-dark fw-bold text-center"
                  scope="col"
                  style={{ width: "50px" }}
                >
                  W
                </th>
                <th
                  className="text-dark fw-bold text-center"
                  scope="col"
                  style={{ width: "50px" }}
                >
                  D
                </th>
                <th
                  className="text-dark fw-bold text-center"
                  scope="col"
                  style={{ width: "50px" }}
                >
                  L
                </th>

                <th
                  className="text-dark fw-bold text-center"
                  scope="col"
                  style={{ width: "50px" }}
                >
                  GF
                </th>
                <th
                  className="text-dark fw-bold text-center"
                  scope="col"
                  style={{ width: "50px" }}
                >
                  GA
                </th>
                <th
                  className="text-dark fw-bold text-center"
                  scope="col"
                  style={{ width: "50px" }}
                >
                  GD
                </th>
                <th
                  className="text-dark-fw-bold text-center"
                  scope="col"
                  style={{ width: "50px", borderTopRightRadius: "10px" }}
                >
                  Pts
                </th>
              </tr>
            </thead>
            <tbody>
              {point.map((data, index) => {
                return (
                  <tr
                    key={index}
                    className={
                      localStorage.getItem("id") == data.id_user
                        ? "table-primary fw-bold"
                        : index + 1 > jml
                        ? "d-none"
                        : ""
                    }
                    style={{ fontSize: "10px" }}
                  >
                    <th scope="row" id={index % 2 == 0 ? "genap" : "ganjil"}>
                      <div className="mt-4 text-white text-center">
                        {" "}
                        {index + 1}
                      </div>
                    </th>
                    <td id={index % 2 == 0 ? "genap" : "ganjil"}>
                      <center>
                        <Link
                          to={"/statistikteam/" + data.team}
                          style={{ textDecoration: "none" }}
                        >
                          <Complogoteamrank team={data.team} />
                          <div className="text-white"> {data.team}</div>
                        </Link>
                      </center>
                    </td>
                    {/* <td>{data.persen.substr(0, 5)}%</td> */}
                    <td
                      className="text-center fw-bold"
                      id={index % 2 == 0 ? "genap" : "ganjil"}
                    >
                      <div className="mt-4 text-white">{data.play}</div>
                    </td>
                    <td
                      className="text-center fw-bold"
                      id={index % 2 == 0 ? "genap" : "ganjil"}
                    >
                      <div className="mt-4 text-white"> {data.win}</div>
                    </td>
                    <td
                      className="text-center fw-bold"
                      id={index % 2 == 0 ? "genap" : "ganjil"}
                    >
                      <div className="mt-4 text-white">{data.draw}</div>
                    </td>
                    <td
                      className=" text-center fw-bold"
                      id={index % 2 == 0 ? "genap" : "ganjil"}
                    >
                      <div className="mt-4 text-white"> {data.lost}</div>
                    </td>
                    <td
                      className=" text-center fw-bold"
                      id={index % 2 == 0 ? "genap" : "ganjil"}
                    >
                      <div className="mt-4 text-white"> {data.goal}</div>
                    </td>
                    <td
                      className="text-center fw-bold"
                      id={index % 2 == 0 ? "genap" : "ganjil"}
                    >
                      <div className="mt-4 text-white"> {data.kebobolan}</div>
                    </td>
                    <td
                      className="text-center fw-bold"
                      id={index % 2 == 0 ? "genap" : "ganjil"}
                    >
                      <div className="mt-4 text-white">{data.selisih_goal}</div>
                    </td>
                    <td
                      className="text-center fw-bold"
                      id={index % 2 == 0 ? "genap" : "ganjil"}
                    >
                      <div className="mt-4 text-white"> {data.point}</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <thead>
              <tr
                className="table-primary"
                style={{ fontSize: "10px", fontWeight: "bold" }}
              >
                <th
                  scope="col"
                  className="text-center"
                  style={{
                    borderBottomLeftRadius: "10px",
                  }}
                >
                  No
                </th>
                <th scope="col">
                  <center>Team</center>
                </th>
                <th
                  className="text-dark fw-bold text-center"
                  scope="col"
                  style={{ width: "50px" }}
                >
                  P
                </th>
                <th
                  className="text-dark fw-bold text-center"
                  scope="col"
                  style={{ width: "50px" }}
                >
                  W
                </th>
                <th
                  className="text-dark fw-bold text-center"
                  scope="col"
                  style={{ width: "50px" }}
                >
                  D
                </th>
                <th
                  className="text-dark fw-bold text-center"
                  scope="col"
                  style={{ width: "50px" }}
                >
                  L
                </th>

                <th
                  className="text-dark fw-bold text-center"
                  scope="col"
                  style={{ width: "50px" }}
                >
                  GF
                </th>
                <th
                  className="text-dark fw-bold text-center"
                  scope="col"
                  style={{ width: "50px" }}
                >
                  GA
                </th>
                <th
                  className="text-dark fw-bold text-center"
                  scope="col"
                  style={{ width: "50px" }}
                >
                  GD
                </th>
                <th
                  className="text-dark-fw-bold text-center"
                  scope="col"
                  style={{ width: "50px", borderBottomRightRadius: "10px" }}
                >
                  Pts
                </th>
              </tr>
            </thead>
          </table>
          {page == true ? (
            <>
              {" "}
              <small
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => handleShow(false)}
              >
                Lihat semuanya <i class="fa-solid fa-angle-right"></i>
              </small>
            </>
          ) : (
            <>
              {" "}
              <small
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => handleShow(true)}
              >
                Tutup ranking <i class="fa-solid fa-angle-right"></i>
              </small>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
