import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function Comprank({ id_user }) {
  const urlapi = process.env.REACT_APP_BASE_URL;

  const [rank, setRank] = useState([]);
  const getdata = async () => {
    try {
      const response = await axios.get(urlapi + "Point_memberkarir");

      setRank(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <div>
      <div className="container my-5">
        <center>
          <img
            src="/img/trophy.png"
            className="img-fluid"
            alt="Responsive image"
            style={{ height: "200px" }}
          ></img>
          {rank.map((data, index) => {
            return (
              <div className={id_user == data.id_user ? "" : "d-none"}>
                <h5 className="mt-3 fw-bold">Ranking yang di proleh adalah</h5>
                <h1 className="fw-bold text-success">
                  Rank {index + 1} <br />
                </h1>
                <small
                  className="fw-bold text-danger"
                  style={{ fontSize: "20px" }}
                >
                  Dengan point {data.point}
                </small>
              </div>
            );
          })}
        </center>
      </div>
    </div>
  );
}
