import React from "react";
import { useState } from "react";
import Comptopassist from "../Membarkarirteam/Comptopassist";
import Comptopscore from "../Membarkarirteam/Comptopscore";
import Comprank from "../Membarkarirteam/Comprank";

export default function Compmainrankteam() {
  const [pagerank, setPagerank] = useState(true);
  const [pagescore, setPagescore] = useState(false);
  const [pageassist, setPageassist] = useState(false);
  const [pool, setPool] = useState("A");

  const handlmenuGoal = (page) => {
    if (page == "rank") {
      setPagerank(true);
      setPageassist(false);
      setPagescore(false);
    } else if (page == "score") {
      setPagerank(false);
      setPageassist(false);
      setPagescore(true);
    } else if (page == "assist") {
      setPagerank(false);
      setPageassist(true);
      setPagescore(false);
    }
  };

  const handlepool = (val) => {
    setPool(val);
  };
  return (
    <div>
      <div className="container">
        <br />
        <div className="card mt-5 mb-2 border-0" id="bggradient1">
          <div className="card-body">
            <h5 className="fw-bold text-white">Ranking rank team</h5>
            <small className="text-white">
              Hasil ranking rank team medan mini soccer
            </small>
          </div>
        </div>

        <div className="row mb-3">
          <div
            className="col-4"
            onClick={() => handlepool("A")}
            style={{ cursor: "pointer" }}
          >
            <div
              className={pool == "A" ? "card border-primary" : "card shadow"}
            >
              <div className="card-body fw-bold">
                <span class="badge bg-dark">Pool A</span>
              </div>
            </div>
          </div>
          <div
            className="col-4"
            onClick={() => handlepool("B")}
            style={{ cursor: "pointer" }}
          >
            <div
              className={pool == "B" ? "card border-primary" : "card shadow"}
            >
              <div className="card-body fw-bold">
                <span class="badge bg-dark">Pool B</span>
              </div>
            </div>
          </div>
          <div
            className="col-4"
            onClick={() => handlepool("All")}
            style={{ cursor: "pointer" }}
          >
            <div
              className={pool == "All" ? "card border-primary" : "card shadow"}
            >
              <div className="card-body fw-bold">
                <span class="badge bg-dark">Pool All</span>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-body">
            <div className="d-flex justify-content-between fw-bold">
              <small
                className={pagerank == true ? "text-primary" : ""}
                style={{ cursor: "pointer" }}
                onClick={() => handlmenuGoal("rank")}
              >
                <i className="far fa-star"></i> Rank {pool}
              </small>
              <small
                className={pagescore == true ? "text-primary" : ""}
                style={{ cursor: "pointer" }}
                onClick={() => handlmenuGoal("score")}
              >
                <i className="far fa-futbol"></i> Top score {pool}
              </small>

              <small
                className={pageassist == true ? "text-primary" : ""}
                style={{ cursor: "pointer" }}
                onClick={() => handlmenuGoal("assist")}
              >
                <i className="far fa-user"></i> Top assist {pool}
              </small>
            </div>
            <hr></hr>

            {pagerank == true ? (
              <>
                {" "}
                <Comprank pool={pool} />
              </>
            ) : pagescore == true ? (
              <>
                <Comptopscore pool={pool} />
              </>
            ) : pageassist == true ? (
              <>
                {" "}
                <Comptopassist />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
