import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function Compeditpemain() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const [pemain, setPemain] = useState([]);
  const [nama, setNama] = useState("");
  const [nickname, setNickname] = useState("");
  const [posisi, setPosisi] = useState("");
  const [nopunggung, setNopunggung] = useState("");
  const [nik, setNik] = useState("");

  const getPemain = async () => {
    try {
      const response = await axios.get(
        urlapi + "Pemainrankteam?idpemain=" + id
      );
      //   console.log(response.data);
      setPemain(response.data);
      setNama(response.data.nama);
      setPosisi(response.data.posisi);
      setNopunggung(response.data.no_punggung);
      setNickname(response.data.nickname);
      setNik(response.data.nik);
    } catch (error) {}
  };

  const ubahPemain = async () => {
    await axios
      .post(urlapi + "Ubahpemainrankteam", {
        id: id,
        nama: nama,
        posisi: posisi,
        no_punggung: nopunggung,
        nik: nik,
        nickname: nickname,
      })
      .then((response) => {
        // console.log(response.data);
        notify();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const notify = () =>
    toast.success("Data berhasil diubah !", {
      position: toast.POSITION.TOP_CENTER,
    });

  useEffect(() => {
    getPemain();
  }, []);
  return (
    <div className="my-5">
      <br></br>
      <div className="container">
        <div className="card border-0" id="bggradient1">
          <div className="card-body text-white">
            <h5 className="fw-bold">Ubah Pemain rank team anda</h5>
            <small>Ubah pemain rank team anda</small>
          </div>
        </div>
        <div className="card mt-3">
          <div className="card-body">
            <p className="fw-bold text-secondary">
              <i className="fas fa-user"></i> Form ubah data pemain
            </p>
            <hr />
            <div className="mt-3">
              <div className="form-group">
                <label className="mb-1 fw-bold">Nama</label>
                <input
                  className="form-control"
                  onChange={(e) => setNama(e.target.value)}
                  name="nama"
                  value={nama}
                />
              </div>

              <div className="form-group mt-3">
                <label className="mb-1 fw-bold">NIK</label>
                <input
                  className="form-control"
                  onChange={(e) => setNik(e.target.value)}
                  name="nickname"
                  value={nik}
                />
              </div>
              {/* 
              <div className="form-group mt-3">
                <label className="mb-1 fw-bold">No Punggung</label>
                <input
                  className="form-control"
                  type="number"
                  name="no_punggung"
                  onChange={(e) => setNopunggung(e.target.value)}
                  value={nopunggung}
                />
              </div> */}
              <div className="form-group mt-3">
                <label className="mb-1 fw-bold">Posisi</label>
                <select
                  className="form-control"
                  name="posisi"
                  onChange={(e) => setPosisi(e.target.value)}
                >
                  <option>{pemain.posisi}</option>
                  <option value="">-- Pilih Posisi --</option>
                  <option>Penyerang</option>
                  <option>Gelandang serang</option>
                  <option>Gelandang sayap</option>
                  <option>Gelandang tengah</option>
                  <option>Bek sayap</option>
                  <option>Bek tangah</option>
                  <option>Penjaga gawang</option>
                </select>
              </div>
              <div className="d-flex justify-content-between">
                {nama == "" || nik == "" || posisi == "" ? (
                  <>
                    {" "}
                    <button
                      className="btn  mt-4 w-100 border-0 text-white rounded-pill"
                      disabled
                      style={{
                        marginRight: "10px",
                        backgroundColor: "#2b2e5a",
                      }}
                    >
                      Ubah data
                    </button>
                  </>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn  mt-4 w-100 border-0 text-white rounded-pill"
                      style={{
                        marginRight: "10px",
                        backgroundColor: "#2b2e5a",
                      }}
                      onClick={() => ubahPemain()}
                    >
                      Ubah data
                    </button>
                  </>
                )}

                <Link
                  to="/daftarteamrankteam"
                  className="btn  mt-4 w-100 border-0 rounded-pill text-white"
                  style={{ backgroundColor: "#2b2e5a" }}
                >
                  Kembali
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
