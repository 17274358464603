import axios from "axios";
import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function Comppemain() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [pemain, setPemain] = useState([]);
  const [cekbookingteam, setCekbookingteam] = useState("");
  const [statusteam, setStatusteam] = useState(0);

  const getPemain = async () => {
    try {
      const response = await axios.get(
        urlapi + "Pemainrankteam?id_user=" + localStorage.getItem("id")
      );
      //   console.log(response.data);
      setPemain(response.data);
    } catch (error) {}
  };

  const hapusPemain = async (id) => {
    try {
      const response = await axios.get(urlapi + "Hapuspemain?id=" + id);
      console.log(response.data);
      notify();
      getPemain();
    } catch (error) {}
  };

  const notify = () =>
    toast.success("Data berhasil di !", {
      position: toast.POSITION.TOP_CENTER,
    });

  const notify2 = () =>
    toast.error("Data anda belume disetujui oleh admin !", {
      position: toast.POSITION.TOP_CENTER,
    });

  const cekteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Cekbookingteam?id_user=" + localStorage.getItem("id")
      );
      setCekbookingteam(response.data.id_user);
      console.log(response.data.id_user);
    } catch (error) {}
  };

  const cekstatusteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Cekstatusteam?id=" + localStorage.getItem("id")
      );
      // console.log(response.data);
      setStatusteam(response.data.status);
    } catch (error) {}
  };

  useEffect(() => {
    getPemain();
    cekteam();
    cekstatusteam();
  }, []);

  return (
    <div className="my-5">
      <br></br>
      <div className="container">
        <div className="card border-0" id="bggradient1">
          <div className="card-body text-white">
            <h5 className="fw-bold">
              <i className="fas fa-user"></i> Pemain rank team anda
            </h5>
            <small>Pemain yang sudah di daftarkan tidak dapat di ubah</small>
          </div>
        </div>
        <div className="alert alert-info mt-2 border-0">
          <i className="fas fa-circle-info"></i>{" "}
          <small className="fw-bold">
            Team yang sudah disetujui admin tidak dapat menambah, merubah dan
            menghapus pemain
          </small>
        </div>
        {/* <div className="card mt-3 shadow">
          <div className="card-body">
            <Link to="/tambahpemain" style={{ textDecoration: "none" }}>
              <div className="d-flex justify-content-between text-secondary">
                <small className="text-primary fw-bold">
                  <i className="fas fa-plus"></i> Tambah pemain team anda
                </small>
                <small>
                  <i className="fas fa-angle-right"></i>
                </small>
              </div>
            </Link>
          </div>
        </div> */}
        <div className="card shadow mt-3">
          <div className="card-body">
            {pemain == false || pemain == null ? (
              <>
                <div className="my-5">
                  <p className="text-center text-danger">
                    <i className="fas fa-circle-info"></i> Pemain team anda
                    belum tersedia
                  </p>
                </div>
              </>
            ) : (
              ""
            )}
            {pemain.map((data, index) => {
              return (
                <div key={index}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <img
                        src="http://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png"
                        style={{ height: "50px" }}
                        class="img-fluid"
                        alt="Responsive image"
                      ></img>
                      {data.nickname == "" ? (
                        <>
                          <small className="fw-bold">{data.nama}</small>
                        </>
                      ) : (
                        <>
                          <small className="fw-bold">{data.nickname}</small>
                        </>
                      )}

                      <br></br>
                      <small className="text-secondary">{data.posisi}</small>
                      <br></br>
                      <small
                        className="text-secondary"
                        style={{ fontSize: "10px" }}
                      >
                        NIK : {data.nik}
                      </small>
                    </div>

                    {statusteam == 0 ? (
                      <>
                        <div>
                          <label className="text-secondary fw-bold">
                            <i className="fas fa-shirt"></i> {}
                            {data.no_punggung}
                          </label>
                          <br />
                          <Link
                            to={"/editpemain/" + data.id}
                            style={{ textDecoration: "none" }}
                          >
                            <Badge bg="info" style={{ cursor: "pointer" }}>
                              <i className="fas fa-pen"></i>
                            </Badge>{" "}
                          </Link>
                          <Badge
                            bg="danger"
                            style={{ cursor: "pointer" }}
                            onClick={() => hapusPemain(data.id)}
                          >
                            <i className="fas fa-trash"></i>
                          </Badge>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <label className="text-secondary fw-bold">
                            <i className="fas fa-shirt"></i> {}
                            {data.no_punggung}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                  <hr></hr>
                </div>
              );
            })}
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4 ">
          {statusteam == 0 ? (
            <>
              <button
                className="btn  rounded-pill w-100"
                onClick={() => notify2()}
                style={{ backgroundColor: "#2b2e5a", color: "white" }}
              >
                Barmain rank team
              </button>
            </>
          ) : (
            <>
              <Link
                to="/memberkarirteam"
                className="btn btn-primary rounded-pill w-100"
                style={{ backgroundColor: "#2b2e5a" }}
              >
                <i className="fas fa-futbol"></i> Bermain rank team
              </Link>
            </>
          )}
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}
