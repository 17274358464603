import React from "react";
import Navbar from "../componenst/Navbar";
import { useParams } from "react-router-dom";
import Comppemain from "../componenst/Hasilmemberkarir/Comppemain";
export default function Pemainmemberkarir2() {
  const { kodemain, team, lawan, video } = useParams();

  return (
    <div>
      <Navbar judul="Peserta" aicon="true" />
      <Comppemain kodemain={kodemain} team={team} lawan={lawan} />
    </div>
  );
}
