import React, { useEffect, useState } from "react";
import Navbar from "../componenst/Navbar";
import axios from "axios";

export default function Databookinranksolo() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [booking, setBooking] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi + "Databookingranksolo?id=" + localStorage.getItem("id")
      );
      console.log(response.data);
      setBooking(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <div>
      <Navbar judul="Data booking rank solo" aicon="true" />
      <br />
      <br />
      <br />
      <div className="container">
        <div className="card border-0" id="bggradient1">
          <div className="card-body text-white">
            <h5 className="fw-bold">Hello {localStorage.getItem("nama")}</h5>
            <small>Selamat datang di halaman data booking rank solo anda</small>
          </div>
        </div>
        <hr />
        <div
          className="d-flex justify-content-between mt-3"
          style={{ fontSize: "12px" }}
        >
          <small className="fw-bold text-secondary">
            <i className="fas fa-file"></i> Data booking anda
          </small>
          <small className="fw-bold text-secondary">
            <i className="fas fa-angle-right"></i>
          </small>
        </div>
        <div className="card mt-2 mb-5">
          <div className="card-body">
            {booking == false ? (
              <>
                <center>
                  <img
                    src="./img/nulldatabookingrankteam.svg"
                    className="img-fluid"
                    style={{
                      borderRadius: "10px",
                      height: "300px",
                    }}
                  ></img>
                  <div className="my-3" style={{ fontSize: "12px" }}>
                    <small className="text-secondary fw-bold">
                      Data booking rank team anda belum tersedia
                    </small>
                  </div>
                </center>
              </>
            ) : (
              <>
                {booking.map((data, index) => {
                  return (
                    <div key={index}>
                      <div className="card mb-2">
                        <div className="card-body">
                          <div
                            className="d-flex justify-content-between"
                            style={{ fontSize: "12px" }}
                          >
                            <span class="badge bg-primary">
                              Rank solo sesi {data.sesi}
                            </span>
                            <small className="fw-bold text-secondary">
                              <i className="far fa-calendar"></i>{" "}
                              {data.tgl_main}
                            </small>
                          </div>

                          <div
                            className="d-flex justify-content-between mt-3"
                            style={{ fontSize: "12px" }}
                          >
                            <small className="fw-bold text-secondary">
                              <i className="far fa-clock"></i> {data.jam_mulai}
                              {"-"}
                              {data.jam_selesai} {"WIB"}
                            </small>

                            {data.status_pembayaran == 200 ? (
                              <span class="badge bg-success">Disetujui</span>
                            ) : data.status_pembayaran == 201 ? (
                              <span class="badge bg-warning">Ditolak</span>
                            ) : (
                              <span class="badge bg-danger">Ditolak</span>
                            )}
                          </div>
                          <hr />
                          <div className="text-center">
                            {data.status_pembayaran == 200 ? (
                              <>
                                <div className="d-flex justify-content-center">
                                  <div>
                                    {data.team == "Putih" ? (
                                      <>
                                        <img
                                          src="./img/putih.png"
                                          className="img-fluid"
                                          style={{
                                            borderRadius: "10px",
                                            height: "50px",
                                          }}
                                        ></img>
                                        <div className="fw-bold">
                                          <small>Team anda</small>
                                        </div>
                                      </>
                                    ) : data.team == "Hitam" ? (
                                      <>
                                        <img
                                          src="./img/hitam.png"
                                          className="img-fluid"
                                          style={{
                                            borderRadius: "10px",
                                            height: "50px",
                                          }}
                                        ></img>
                                        <div className="fw-bold">
                                          <small>Team anda</small>
                                        </div>
                                      </>
                                    ) : data.team == "Merah" ? (
                                      <>
                                        <img
                                          src="./img/merah.png"
                                          className="img-fluid"
                                          style={{
                                            borderRadius: "10px",
                                            height: "50px",
                                          }}
                                        ></img>
                                        <div className="fw-bold">
                                          <small>Team anda</small>
                                        </div>
                                      </>
                                    ) : data.team == "Kuning" ? (
                                      <>
                                        <img
                                          src="./img/kuning.png"
                                          className="img-fluid"
                                          style={{
                                            borderRadius: "10px",
                                            height: "50px",
                                          }}
                                        ></img>
                                        <div className="fw-bold">
                                          <small>Team anda</small>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="fw-bold mt-3">VS</div>
                                  <div>
                                    {data.lawan == "Putih" ? (
                                      <>
                                        <img
                                          src="./img/putih.png"
                                          className="img-fluid"
                                          style={{
                                            borderRadius: "10px",
                                            height: "50px",
                                          }}
                                        ></img>
                                        <div className="fw-bold">
                                          <small>Team lawan</small>
                                        </div>
                                      </>
                                    ) : data.lawan == "Hitam" ? (
                                      <>
                                        <img
                                          src="./img/hitam.png"
                                          className="img-fluid"
                                          style={{
                                            borderRadius: "10px",
                                            height: "50px",
                                          }}
                                        ></img>
                                        <div className="fw-bold">
                                          <small>Team lawan</small>
                                        </div>
                                      </>
                                    ) : data.lawan == "Merah" ? (
                                      <>
                                        <img
                                          src="./img/merah.png"
                                          className="img-fluid"
                                          style={{
                                            borderRadius: "10px",
                                            height: "50px",
                                          }}
                                        ></img>
                                        <div className="fw-bold">
                                          <small>Team lawan</small>
                                        </div>
                                      </>
                                    ) : data.lawan == "Kuning" ? (
                                      <>
                                        <img
                                          src="./img/kuning.png"
                                          className="img-fluid"
                                          style={{
                                            borderRadius: "10px",
                                            height: "50px",
                                          }}
                                        ></img>
                                        <div className="fw-bold">
                                          <small>Team lawan</small>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className="text-center">
                                <small className="text-center fw-bold text-secondary">
                                  Team dan lawan belum tersedia
                                </small>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
