import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Compdataasist({ assist }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [asist, setAsist] = useState(false);
  const [dataasist, setDataasist] = useState([]);

  const getData = async () => {
    try {
      const response = await axios.get(
        urlapi + "Dataassist?id_user=" + localStorage.getItem("id")
      );
      setDataasist(response.data);
      // console.log(response.data);
    } catch (error) {
      // console.log(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <div
        className={`d-flex justify-content-between ${
          asist ? "text-primary" : "text-secondary"
        }`}
        onClick={() => setAsist(!asist)}
        style={{ cursor: "pointer" }}
      >
        <p>Assist</p>
        <p>
          <a style={{ textDecoration: "none" }}>
            {assist !== "" ? assist : 0} Assist{" "}
            <i
              className={asist ? "fas fa-chevron-down" : "fas fa-chevron-right"}
            ></i>
          </a>
        </p>
      </div>
      {asist ? (
        <div className="card border-primary">
          <div className="card card-body">
            {dataasist == false ? (
              <p className="text-center text-secondary">
                <i className="fas fa-trash"></i> Data Kosong
              </p>
            ) : (
              ""
            )}
            {dataasist.map((asist) => {
              return (
                <div key={asist.id}>
                  <div className="d-flex justify-content-between text-primary">
                    <small>
                      <i className="fas fa-user"></i> {asist.team}
                    </small>
                    <small>
                      <i className="fas fa-shield"></i> {asist.team}
                    </small>

                    <small className="">
                      {asist.score_team} VS {asist.score_lawan}
                    </small>

                    <small>
                      <i className="fas fa-shield"></i> {asist.lawan}
                    </small>
                  </div>
                  <hr />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
