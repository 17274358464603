import axios from "axios";
import React, { useEffect, useState } from "react";
import Compfooter from "./Compfooter";
import { useParams } from "react-router-dom";
import Pembayaran2 from "../../pages/Pembayaran2";
import { Button } from "react-bootstrap";

export default function Compmain() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [wasit, setWasit] = useState([]);
  const [video, setVideo] = useState([]);
  const [foto, setFoto] = useState([]);
  const [bola, setBola] = useState([]);
  const [rompi, setRompi] = useState([]);
  const [idwasit, setIdwasit] = useState("");
  const [idvideo, setIdvideo] = useState("");
  const [idfoto, setIdfoto] = useState("");
  const [idrompi, setIdrompi] = useState("");
  const [idbola, setIdbola] = useState("");
  const [hargawasit, setHargawasit] = useState(0);
  const [hargavideo, setHargavideo] = useState(0);
  const [hargafoto, setHargafoto] = useState(0);
  const [hargarompi, setHargarompi] = useState(0);
  const [hargabola, setHargabola] = useState(0);
  const [jambooking, setJambooking] = useState([]);
  const [hargabooking, setHargabooking] = useState(0);
  const [pagewasit, setPagewasit] = useState(false);
  const [pagevideo, setPagevideo] = useState(false);
  const [pagefoto, setPagefoto] = useState(true);
  const [pagerompi, setPagerompi] = useState(false);
  const [pagebola, setPagebola] = useState(false);
  const [lapangan, setLapangan] = useState("");
  const [sistempembayaran, setSistempembayaran] = useState("");
  const [namateam, setNamateam] = useState("");
  const [wa, setWa] = useState("");
  const [status, setStaus] = useState("false");

  const formatrupiah = (numb) => {
    const format = numb.toString().split("").reverse().join("");
    const convert = format.match(/\d{1,3}/g);
    const rupiah = "Rp " + convert.join(".").split("").reverse().join("");
    return rupiah;
  };

  const totalharga =
    Number(hargabooking) +
    Number(hargavideo) +
    Number(hargawasit) +
    Number(hargafoto) +
    Number(hargarompi) +
    Number(hargabola);

  const { idlap, idjam, tgl } = useParams();

  const getwasit = async () => {
    try {
      const response = await axios.get(urlapi + "Wasit");
      setWasit(response.data);
    } catch (error) {}
  };

  const getbola = async () => {
    try {
      const response = await axios.get(urlapi + "Bola");
      setBola(response.data);
      // console.log(response.data);
    } catch (error) {}
  };

  const getrompi = async () => {
    try {
      const response = await axios.get(urlapi + "Rompi");
      setRompi(response.data);
    } catch (error) {}
  };

  const getvideo = async () => {
    try {
      const response = await axios.get(urlapi + "Videografer");
      // console.log(response.data);
      setVideo(response.data);
    } catch (error) {}
  };

  const getfoto = async () => {
    try {
      const response = await axios.get(urlapi + "Fotografer");
      setFoto(response.data);
    } catch (error) {}
  };

  const getdatajam = async () => {
    try {
      const response = await axios.get(urlapi + "Jambooking?id=" + idjam);
      setJambooking(response.data);
      setHargabooking(response.data.harga_diskon);
    } catch (error) {}
  };

  const getlapangan = async () => {
    try {
      const response = await axios.get(urlapi + "Lapangan?id=" + idlap);
      setLapangan(response.data.lapangan);
    } catch (error) {}
  };

  const handlewasit = async (id) => {
    if (id == 0) {
      setHargawasit(0);
      setIdwasit("");
    } else {
      gethargawasit(id);
      setIdwasit(id);
    }
  };

  const handlevideo = async (id) => {
    if (id == 0) {
      setHargavideo(0);
      setIdvideo("");
    } else {
      // console.log(id);
      gethargavideo(id);
      setIdvideo(id);
    }
  };

  const handlefoto = async (id) => {
    // console.log(id);
    if (id == 0) {
      setHargafoto(0);
      setIdfoto("");
    } else {
      gethargafoto(id);
      setIdfoto(id);
    }
  };

  const handlerompi = async (id) => {
    if (id == 0) {
      setHargarompi(0);
      setIdrompi("");
    } else {
      gethargarompi(id);
      setIdrompi(id);
    }
  };

  const handlebola = async (id) => {
    if (id == 0) {
      setHargabola(0);
      setIdbola("");
    } else {
      gethargabola(id);
      setIdbola(id);
    }
  };

  const gethargawasit = async (id) => {
    try {
      const response = await axios.get(urlapi + "Wasit?id=" + id);
      setHargawasit(response.data.harga);
    } catch (error) {}
  };

  const gethargavideo = async (id) => {
    try {
      const response = await axios.get(urlapi + "Videografer?id=" + id);
      setHargavideo(response.data.harga);
    } catch (error) {}
  };

  const gethargafoto = async (id) => {
    try {
      const response = await axios.get(urlapi + "Fotografer?id=" + id);
      setHargafoto(response.data.harga);
    } catch (error) {}
  };

  const gethargarompi = async (id) => {
    try {
      const response = await axios.get(urlapi + "Rompi?id=" + id);
      setHargarompi(response.data.harga);
    } catch (error) {}
  };

  const gethargabola = async (id) => {
    try {
      const response = await axios.get(urlapi + "Bola?id=" + id);
      setHargabola(response.data.harga);
    } catch (error) {}
  };

  const cekbooking = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Cekbookinglapanganterbaru?idlap=" +
          idlap +
          "&&idjam=" +
          idjam +
          "&&tgl=" +
          tgl
      );
      setStaus("true");
    } catch (error) {
      setStaus("false");
    }
  };

  useEffect(() => {
    getrompi();
    getfoto();
    getwasit();
    getvideo();
    getbola();
    getdatajam();
    getlapangan();
  }, []);
  return (
    <div style={{ height: "100%" }} id="pagecheckout">
      <a href="#pagecheckout" id="buttonklik" style={{ display: "none" }}>
        KLIK
      </a>
      <div className="container">
        <br />
        <div className="card mt-5 mb-3 text-white" id="bglistpemain">
          <div className="card-body">
            <h5>Halaman checkout </h5>
            <small>
              <i className="fas fa-"></i>
              Anda dapat menambahkan layanan wasit, fotografer, videografer,
              rompi dan bola untuk booking lapangan anda
            </small>
          </div>
        </div>
        <hr />

        {status == "true" ? <></> : <></>}

        {/* layanan fotograger */}
        {foto == false ? (
          <></>
        ) : (
          <>
            <div className="card mb-3 mt-3 shadow" style={{ border: "none" }}>
              <div
                className="card-header"
                onClick={() => setPagefoto(!pagefoto)}
              >
                <div className="d-flex justify-content-between">
                  <label className="fw-bold" style={{ color: "#2b2e5a" }}>
                    <i className="fas fa-camera"></i> Fotograper{" "}
                  </label>
                  <label>
                    <span class="badge rounded-pill bg-danger">New</span>{" "}
                    <i
                      className={
                        pagefoto == true
                          ? "fas fa-angle-down"
                          : "fas fa-angle-right"
                      }
                    ></i>
                  </label>
                </div>
              </div>
              <div className="card-body text-secondary">
                <div className={pagefoto == false ? "" : "d-none"}>
                  <div class="d-flex justify-content-between">
                    {foto == false ? (
                      <>
                        {" "}
                        <small className="text-secondary">
                          <i className="fas fa-circle-info"></i> Layanan
                          fotogragper tidak tersedia
                        </small>
                      </>
                    ) : (
                      <>
                        {" "}
                        <small
                          className="text-secondary"
                          onClick={() => setPagefoto(!pagefoto)}
                        >
                          <i className="fas fa-plus"></i> Tambah layanan
                          fotograper
                        </small>
                      </>
                    )}
                    {hargafoto == "" ? (
                      ""
                    ) : (
                      <i class="fas fa-circle-check text-success"></i>
                    )}
                  </div>
                </div>
                <div className={pagefoto == true ? "" : "d-none"}>
                  {foto.map((data, index) => {
                    return (
                      <div key={index}>
                        <div className="d-flex justify-content-between">
                          <div>
                            <label
                              className="fw-bold"
                              style={{ color: "#2b2e5a" }}
                            >
                              {data.nama}
                            </label>
                            <br />
                            {data.harga2 == "" ? (
                              <></>
                            ) : (
                              <>
                                {" "}
                                <small className="fw-bold">
                                  <s> {formatrupiah(data.harga2)}</s>
                                </small>
                              </>
                            )}
                            <br />
                            <small
                              className="fw-bold"
                              style={{ color: "#2b2e5a" }}
                            >
                              <span class="badge bg-success">
                                {formatrupiah(data.harga)}
                              </span>
                            </small>{" "}
                            <small className="text-primary">
                              <span class="badge bg-dark">PROFESSIONAL</span>
                            </small>{" "}
                          </div>
                          <div>
                            <div class="form-check">
                              <img
                                src={
                                  "https://ratumerak.id/adminmmsc/assets/berkas/" +
                                  data.foto
                                }
                                className="img-fluid"
                                alt="Responsive image"
                                style={{ height: "50px" }}
                              ></img>
                              <input
                                class="form-check-input  border border-danger"
                                type="radio"
                                name="foto"
                                id="foto"
                                value="foto"
                                onClick={() => handlefoto(data.id)}
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })}

                  <div className="d-flex justify-content-between">
                    <div>
                      <label className="fw-bold">Tanpa fotograper</label>
                      <br />
                      <small className="">Rp. 0</small>
                    </div>
                    <div>
                      <div class="form-check border-danger">
                        <input
                          class="form-check-input border border-danger"
                          type="radio"
                          name="foto"
                          id="foto"
                          value="foto"
                          onClick={() => handlefoto(0)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* layanan videograger */}
        {video == false ? (
          <></>
        ) : (
          <>
            <div className="card mb-3 mt-3 shadow" style={{ border: "none" }}>
              <div
                className="card-header"
                onClick={() => setPagevideo(!pagevideo)}
              >
                <div className="d-flex justify-content-between">
                  <label className="fw-bold" style={{ color: "#2b2e5a" }}>
                    {" "}
                    <i class="fas fa-video"></i> Videograper
                  </label>
                  <label>
                    <span class="badge rounded-pill bg-danger">New</span>{" "}
                    <i
                      className={
                        pagevideo == true
                          ? "fas fa-angle-down"
                          : "fas fa-angle-right"
                      }
                    ></i>
                  </label>
                </div>
              </div>
              <div className="card-body text-secondary">
                <div className={pagevideo == false ? "" : "d-none"}>
                  <div className="d-flex justify-content-between">
                    {video == false ? (
                      <>
                        {" "}
                        <small className="text-secondary">
                          <i className="fas fa-circle-info"></i> Layanan
                          videograper tidak tersedia
                        </small>
                      </>
                    ) : (
                      <>
                        {" "}
                        <small
                          className="text-secondary"
                          onClick={() => setPagevideo(!pagevideo)}
                        >
                          <i className="fas fa-plus"></i> Tambah layanan
                          videograper
                        </small>
                      </>
                    )}
                    {hargavideo == "" ? (
                      ""
                    ) : (
                      <i className="fas fa-circle-check text-success"></i>
                    )}
                  </div>
                </div>
                <div className={pagevideo == true ? "" : "d-none"}>
                  {video.map((data, index) => {
                    return (
                      <div key={index}>
                        <div className="d-flex justify-content-between ">
                          <div>
                            <label
                              className="fw-bold"
                              style={{ color: "#2b2e5a" }}
                            >
                              {data.nama}
                            </label>
                            {data.harga2 == "" ? (
                              <></>
                            ) : (
                              <>
                                {" "}
                                <small className="fw-bold">
                                  <s> {formatrupiah(data.harga2)}</s>
                                </small>
                              </>
                            )}
                            <br />
                            <small
                              className="fw-bold"
                              style={{ color: "#2b2e5a" }}
                            >
                              <span class="badge bg-success">
                                {formatrupiah(data.harga)}
                              </span>
                            </small>{" "}
                            <small className="text-primary">
                              <span class="badge bg-dark">PROFESSIONAL</span>
                            </small>{" "}
                          </div>
                          <div>
                            <div class="form-check">
                              <img
                                src={
                                  "https://ratumerak.id/adminmmsc/assets/berkas/" +
                                  data.foto
                                }
                                className="img-fluid"
                                alt="Responsive image"
                                style={{ height: "50px" }}
                              ></img>
                              <input
                                class="form-check-input  border border-danger"
                                type="radio"
                                name="video"
                                id="video"
                                value="video"
                                onClick={() => handlevideo(data.id)}
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })}

                  <div className="d-flex justify-content-between">
                    <div>
                      <label className="fw-bold">Tanpa videograper</label>
                      <br />
                      <small className="">Rp. 0</small>
                    </div>
                    <div>
                      <div class="form-check border-danger">
                        <input
                          class="form-check-input border border-danger"
                          type="radio"
                          name="video"
                          id="video"
                          value="video"
                          onClick={() => handlevideo(0)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* layanan wasit */}
        {wasit == false ? (
          <></>
        ) : (
          <>
            <div className="card mb-2 shadow" style={{ border: "none" }}>
              <div
                className="card-header"
                onClick={() => setPagewasit(!pagewasit)}
              >
                <div className="d-flex justify-content-between">
                  <label className="fw-bold" style={{ color: "#2b2e5a" }}>
                    <i className="fas fa-user"></i> Wasit
                  </label>
                  <label>
                    <span class="badge rounded-pill bg-danger">New</span>{" "}
                    <i
                      className={
                        pagewasit == true
                          ? "fas fa-angle-down"
                          : "fas fa-angle-right"
                      }
                    ></i>
                  </label>
                </div>
              </div>

              <div className="card-body text-secondary">
                <div className={pagewasit == false ? "" : "d-none"}>
                  <div className="d-flex justify-content-between">
                    {wasit == false ? (
                      <>
                        {" "}
                        <small className="text-secondary">
                          <i className="fas fa-circle-info"></i> Layanan wasit
                          tidak tersedia
                        </small>
                      </>
                    ) : (
                      <>
                        {" "}
                        <small
                          className="text-secondary"
                          onClick={() => setPagewasit(!pagewasit)}
                        >
                          <i className="fas fa-plus"></i> Tambah layanan wasit
                        </small>
                      </>
                    )}
                    {hargawasit == "" ? (
                      ""
                    ) : (
                      <i className="fas fa-circle-check text-success"></i>
                    )}
                  </div>
                </div>
                <div className={pagewasit == true ? "" : "d-none"}>
                  {wasit.map((wasit, index) => {
                    return (
                      <div key={index}>
                        <div className="d-flex justify-content-between">
                          <div>
                            <label
                              className="fw-bold"
                              style={{ color: "#2b2e5a" }}
                            >
                              {" "}
                              {wasit.nama}
                            </label>

                            {/* <small className="text-primary ">
                              {wasit.lisensi}
                            </small> */}
                            <br />
                            <small className="">
                              <span class="badge bg-success">
                                {formatrupiah(wasit.harga)}
                              </span>
                            </small>
                          </div>
                          <div>
                            <div>
                              <div></div>
                              <div class="form-check">
                                <img
                                  src={
                                    "https://ratumerak.id/adminmmsc/assets/berkas/" +
                                    wasit.foto
                                  }
                                  className="img-fluid"
                                  alt="Responsive image"
                                  style={{ height: "50px" }}
                                ></img>
                                <input
                                  class="form-check-input border border-danger"
                                  type="radio"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  value="option1"
                                  onClick={() => handlewasit(wasit.id)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })}

                  <div className="d-flex justify-content-between">
                    <div>
                      <label className="fw-bold text-secondary">
                        Tanpa wasit
                      </label>{" "}
                      <br />
                      <small className="">Rp. 0</small>
                    </div>
                    <div>
                      <div class="form-check">
                        <input
                          class="form-check-input border border-danger"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          onClick={() => handlewasit(0)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* Bola */}
        {bola == false ? (
          <></>
        ) : (
          <>
            <div className="card mb-3 mt-3 shadow" style={{ border: "none" }}>
              <div
                className="card-header"
                onClick={() => setPagebola(!pagebola)}
              >
                <div className="d-flex justify-content-between">
                  <label className="fw-bold" style={{ color: "#2b2e5a" }}>
                    {" "}
                    <i class="fas fa-futbol"></i> Bola
                  </label>
                  <label>
                    <span class="badge rounded-pill bg-danger">New</span>{" "}
                    <i
                      className={
                        pagebola == true
                          ? "fas fa-angle-down"
                          : "fas fa-angle-right"
                      }
                    ></i>
                  </label>
                </div>
              </div>

              <div className="card-body text-secondary">
                <div className={pagebola == false ? "" : "d-none"}>
                  <div className="d-flex justify-content-between">
                    {bola == false ? (
                      <>
                        {" "}
                        <small className="text-secondary">
                          <i className="fas fa-circle-info"></i> Layanan bola
                          tidak tersedia
                        </small>
                      </>
                    ) : (
                      <>
                        {" "}
                        <small
                          className="text-secondary"
                          onClick={() => setPagebola(!pagebola)}
                        >
                          <i className="fas fa-plus"></i> Tambah layanan bola
                        </small>
                      </>
                    )}

                    {hargabola == "" ? (
                      ""
                    ) : (
                      <i className="fas fa-circle-check text-success"></i>
                    )}
                  </div>
                </div>
                <div className={pagebola == true ? "" : "d-none"}>
                  {bola.map((data, index) => {
                    return (
                      <div key={index}>
                        <div className="d-flex justify-content-between">
                          <div>
                            <label
                              className="fw-bold"
                              style={{ color: "#2b2e5a" }}
                            >
                              {data.bola}
                            </label>

                            <br />
                            <small className="">
                              <span class="badge bg-success">
                                {formatrupiah(data.harga)}
                              </span>
                            </small>
                          </div>
                          <div>
                            <div class="form-check">
                              <img
                                src={
                                  "https://ratumerak.id/adminmmsc/assets/berkas/" +
                                  data.foto
                                }
                                className="img-fluid"
                                alt="Responsive image"
                                style={{ height: "50px" }}
                              ></img>
                              <input
                                class="form-check-input  border border-danger"
                                type="radio"
                                name="bola"
                                id="bola"
                                value="bola"
                                onClick={() => handlebola(data.id)}
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })}

                  <div className="d-flex justify-content-between">
                    <div>
                      <label className="fw-bold">Tanpa bola</label>
                      <br />
                      <small className="">Rp. 0</small>
                    </div>
                    <div>
                      <div class="form-check border-danger">
                        <input
                          class="form-check-input border border-danger"
                          type="radio"
                          name="bola"
                          id="bola"
                          value="bola"
                          onClick={() => handlebola(0)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* layanan rompi */}
        {rompi == false ? (
          <></>
        ) : (
          <>
            <div className="card mb-3 mt-3 shadow" style={{ border: "none" }}>
              <div
                className="card-header"
                onClick={() => setPagerompi(!pagerompi)}
              >
                <div className="d-flex justify-content-between">
                  <label className="fw-bold" style={{ color: "#2b2e5a" }}>
                    <i className="fas fa-shirt"></i> Rompi
                  </label>
                  <label>
                    <span class="badge rounded-pill bg-danger">New</span>{" "}
                    <i
                      className={
                        pagerompi == true
                          ? "fas fa-angle-down"
                          : "fas fa-angle-right"
                      }
                    ></i>
                  </label>
                </div>
              </div>

              <div className="card-body text-secondary">
                <div className={pagerompi == false ? "" : "d-none"}>
                  <div className="d-flex justify-content-between">
                    {rompi == false ? (
                      <>
                        {" "}
                        <small className="text-secondary">
                          <i className="fas fa-circle-info"></i> Layanan rompi
                          tidak tersedia
                        </small>
                      </>
                    ) : (
                      <>
                        {" "}
                        <small
                          className="text-secondary"
                          onClick={() => setPagerompi(!pagerompi)}
                        >
                          <i className="fas fa-plus"></i> Tambah layanan rompi
                        </small>
                      </>
                    )}

                    {hargarompi == "" ? (
                      ""
                    ) : (
                      <i className="fas fa-circle-check text-success"></i>
                    )}
                  </div>
                </div>
                <div className={pagerompi == true ? "" : "d-none"}>
                  {rompi.map((data, index) => {
                    return (
                      <div key={index}>
                        <div className="d-flex justify-content-between">
                          <div>
                            <label
                              className="fw-bold"
                              style={{ color: "#2b2e5a" }}
                            >
                              {data.rompi}
                            </label>

                            <br />
                            <small
                              className="fw-bold"
                              style={{ color: "#2b2e5a" }}
                            >
                              <span class="badge bg-success">
                                {formatrupiah(data.harga)}
                              </span>
                            </small>
                          </div>
                          <div>
                            <div class="form-check">
                              <img
                                src={
                                  "https://ratumerak.id/adminmmsc/assets/berkas/" +
                                  data.foto
                                }
                                className="img-fluid"
                                alt="Responsive image"
                                style={{ height: "50px" }}
                              ></img>
                              <input
                                class="form-check-input  border border-danger"
                                type="radio"
                                name="rompi"
                                id="rompi"
                                value="rompi"
                                onClick={() => handlerompi(data.id)}
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })}

                  <div className="d-flex justify-content-between">
                    <div>
                      <label className="fw-bold">Tanpa rompi</label>
                      <br />
                      <small className="">Rp. 0</small>
                    </div>
                    <div>
                      <div class="form-check border-danger">
                        <input
                          class="form-check-input border border-danger"
                          type="radio"
                          name="rompi"
                          id="rompi"
                          value="rompi"
                          onClick={() => handlerompi(0)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="card mb-3 shadow">
          <div className="card-body">
            <label className="fw-bold">Pembayaran</label>
            <br />
            <small className="text-secondary">
              Pembayaran booking lapangan hanya dapat di lakukan dengan
              <b>
                {" "}
                BANK TRANSFER dengan nomor rekening tujuan REK BCA : 6475383951
                a/n Pendy Or Handoko{" "}
              </b>
              , Pembayaran Booking Lapangan Wajib DP 50% dari Harga yang sudah
              di tentukan, dengan syarat pelunasan (H-1) 1 hari sebelum jadwal
              main
            </small>
          </div>
        </div>
      </div>

      <div className="container">
        <div class="alert alert-primary" role="alert">
          <small className="fw-bold">
            Jadwal booking yang sudah di booking tidak dapat di cancel dan
            diubah, mohon perhatikan waktu dan tanggal saat booking lapangan
          </small>
        </div>
      </div>

      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="row mb-3 text-secondary">
              <div className="form-group mt-3 col-md-6">
                <label>Lapangan</label>
                <input
                  type="text"
                  value={lapangan}
                  className="form-control mt-3 fw-bold"
                />
              </div>
              <div className="form-group  mt-3 col-md-6">
                <label>Harga Lapangan</label>
                <br />
                <input
                  type="text"
                  value={hargabooking}
                  placeholder="xxxxxxx"
                  className="form-control mt-3 fw-bold "
                />
              </div>
            </div>
            <div className="row mb-3 text-secondary">
              <div className="form-group mt-3 col-md-6">
                <p>Pembayaran</p>
                <div class="form-check form-check-inline">
                  <input
                    className="form-check-input border-primary"
                    type="radio"
                    name="inlineRadioOptions"
                    id="lunas"
                    onClick={() => setSistempembayaran("Lunas")}
                    value="Lunas"
                  />

                  <label className="form-check-label" for="inlineRadio1">
                    Lunas
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input border-primary"
                    type="radio"
                    name="inlineRadioOptions"
                    id="dp"
                    onClick={() => setSistempembayaran("Dp")}
                    value="DP"
                  />
                  <label className="form-check-label" for="inlineRadio2">
                    DP 50%
                  </label>
                </div>
              </div>
              <div className="form-group  mt-3 col-md-6">
                <label className="mb-2">Transfer</label>
                <input
                  type="text"
                  className="form-control fw-bold"
                  required=""
                  value={sistempembayaran == "Dp" ? totalharga / 2 : totalharga}
                />
              </div>
            </div>
            <div className="row mb-3 text-secondary">
              <div className=" col-md-6 form-group mt-3 text-secondary">
                <label className="mb-2">Nama Team</label>
                <input
                  type="text"
                  className="form-control"
                  required=""
                  placeholder="Sinar FC"
                  value={namateam}
                  onChange={(e) => setNamateam(e.target.value)}
                />
              </div>

              <div className="form-group col-md-6 mt-3 text-secondary">
                <label className="mb-2">No Whatsapp</label>
                <input
                  type="number"
                  className="form-control"
                  required=""
                  placeholder="08xxxxxxxx"
                  value={wa}
                  onChange={(e) => setWa(e.target.value)}
                />
                <small className="text-primary" style={{ fontSize: "8px" }}>
                  Masukan nomor Whatsapp anda dengan benar untuk mendapatkan
                  notifikasi pembayaran anda
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <div className="mt-5">
        <div className="card fixed-bottom" style={{ border: "none" }}>
          <div className="card-body">
            <div className="container">
              <div className="d-flex justify-content-between">
                <div>
                  <label className="fw-bold" style={{ color: "#2b2e5a" }}>
                    Total : {formatrupiah(totalharga)}{" "}
                  </label>
                </div>
                <div>
                  {wa == "" || sistempembayaran == "" || namateam == "" ? (
                    <>
                      <Button
                        variant="btn rounded-pill disabled"
                        style={{ backgroundColor: "#2b2e5a", color: "white" }}
                      >
                        Bayar sekarang
                      </Button>
                    </>
                  ) : (
                    <>
                      <Pembayaran2
                        idlapangan={idlap}
                        lapangan={lapangan}
                        harga={hargabooking}
                        namateam={namateam}
                        jambooking={idjam}
                        wa={wa}
                        tgl={tgl}
                        totalharga={totalharga}
                        wasit={idwasit}
                        video={idvideo}
                        foto={idfoto}
                        rompi={idrompi}
                        bola={idbola}
                        sistempembayaran={sistempembayaran}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Compfooter
          hargalap={hargabooking}
          wasit={hargawasit}
          video={hargavideo}
          lapangan={lapangan}
          team={namateam}
          wa={wa}
          pembayaran={sistempembayaran}
        /> */}
      </div>
    </div>
  );
}
