import axios from "axios";
import React, { useState, useEffect } from "react";

export default function Compassist({ kode, kode_main, team, kode_team }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [assist, setAssist] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Assistrankteam?kode_main=" +
          kode_main +
          "&&kode_team=" +
          kode_team +
          "&&team=" +
          team +
          "&&kode=" +
          kode
      );

      setAssist(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <div>
      {assist.map((data, index) => {
        return (
          <div className="text-warning" key={index} style={{ fontSize: "5px" }}>
            {data.nama}{" "}
            {data.menit == 0 || data.menit == "" ? <></> : <>({data.menit})</>}
          </div>
        );
      })}
    </div>
  );
}
