import React, { useEffect, useState } from "react";
import Navbar from "../componenst/Navbar";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Compmatchrankteam from "../componenst/Rankleague/Compmatchrankteam";
import Comprank from "../componenst/Membarkarirteam/Comprank";
import Comptopscore from "../componenst/Membarkarirteam/Comptopscore";
import Compassist from "../componenst/Membarkarirteam/Compassist";
import Compbookingrankteam from "../componenst/Membarkarirteam/Compbookingrankteam";

export default function Rankteam() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [gamerankteam, setGamerankteam] = useState([]);
  const [rankteam, setRankteam] = useState([]);
  const [setuju, setSetuju] = useState([]);
  const [pagerank, setPagerank] = useState(false);
  const [pagescore, setPagescore] = useState(false);
  const [pageassist, setPageassist] = useState(false);
  const [pageawal, setPageawal] = useState(true);
  const [handlelistbooking, setHandlelistbooking] = useState(true);
  const [team, setTeam] = useState(false);

  const cekperaturan = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Peraturan?rankteam=true&&id_user=" +
          localStorage.getItem("id")
      );
      console.log(response.data);
      if (response.data !== "") {
        setSetuju(true);
      }
    } catch (error) {}
  };

  const mainrankteam = async () => {
    try {
      const response = await axios.get(urlapi + "Fungamerankteam");
      // console.log(response.data);
      setGamerankteam(response.data);
    } catch (error) {
      setGamerankteam("");
    }
  };

  const getpertandinganrankteam = async () => {
    try {
      const response = await axios.get(urlapi + "Pertandinganrankteamterbaru");
      //   console.log(response.data);
      setRankteam(response.data);
    } catch (error) {}
  };

  const notify = () =>
    toast.warning(
      "Anda dalam mode tamu, silahkan buat akun anda sekarang juga ",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );

  const resvonsive = {
    0: {
      items: 1.5,
    },
    600: {
      items: 2.5,
    },
    1000: {
      items: 2.6,
    },
  };

  const resvonsive2 = {
    0: {
      items: 2.3,
    },
    600: {
      items: 2.3,
    },
    1000: {
      items: 2.3,
    },
  };

  const handlmenuGoal = (page) => {
    if (page == "rank") {
      setPagerank(true);
      setPageassist(false);
      setPagescore(false);
    } else if (page == "score") {
      setPagerank(false);
      setPageassist(false);
      setPagescore(true);
      setPageawal(false);
    } else if (page == "assist") {
      setPagerank(false);
      setPageassist(true);
      setPagescore(false);
      setPageawal(false);
    }
  };

  // const cekteam = async () => {
  //   try {
  //     const response = await axios.get(
  //       urlapi + "Cekteam?id=" + localStorage.getItem(id)
  //     );
  //     console.log(response.data);
  //   } catch (error) {}
  // };

  const cekteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Cekteam?id=" + localStorage.getItem("id")
      );
      //   console.log(response.data);
      setTeam(true);
    } catch (error) {
      setTeam(false);
    }
  };

  useEffect(() => {
    mainrankteam();
    getpertandinganrankteam();
    cekperaturan();
    cekteam();
  }, []);
  return (
    <div>
      <Navbar judul="Rank team" aicon="true" />
      <div className="card">
        <div className="card-body mt-5">
          <img
            src="./img/rt22.svg"
            className="img-fluid"
            style={{ borderRadius: "10px" }}
          ></img>
        </div>

        <div className="container">
          <div className="card border-0" id="bggradient1">
            <div className="card-body text-white">
              <h5 className="fw-bold">Hello {localStorage.getItem("nama")}</h5>
              <small>
                Selamat datang di Rank Team, temukan lawan bermain anda disini
              </small>
            </div>
          </div>
          {gamerankteam != false ? (
            <>
              <div className="alert alert-primary border-0 mt-2">
                <small className="fw-bold">
                  <i className="fas fa-circle-info"></i> Hello{" "}
                  {localStorage.getItem("nama")} match rank team dibuka, buruan
                  daftar sekarang juga{" "}
                </small>
              </div>
            </>
          ) : (
            <>
              <div className="alert alert-danger border-0 mt-2">
                <small className="fw-bold">
                  <i className="fas fa-circle-info"></i> Hello{" "}
                  {localStorage.getItem("nama")} match rank team belum tersedia,
                  cobalah dilain hari
                </small>
              </div>
            </>
          )}

          <div>
            <div className="d-flex justify-content-between fw-bold">
              <small className="fw-bold text-secondary">
                <i className="fas fa-shield-halved"></i> Menu rank team
              </small>
              <i className="fas fa-ellipsis-vertical text-secondary"></i>
            </div>
          </div>
          <div className="card mt-2 mb-2  shadow">
            <div className="card-body">
              <div
                className="row mt-2 fw-bold"
                style={{ fontSize: "10px", color: "#2b2e5a" }}
              >
                <div className="col-sm-4 col-4">
                  <center>
                    {localStorage.getItem("tamu") !== null ? (
                      <>
                        <div onClick={() => notify()}>
                          <img
                            src="./img/menu/mteam1.png"
                            className="img-fluid mb-2"
                            style={{
                              borderRadius: "10px",
                              height: "50px",
                            }}
                          ></img>
                          <br />
                        </div>
                        {team == false ? (
                          <>
                            {" "}
                            <small>Daftar team</small>
                          </>
                        ) : (
                          <>
                            {" "}
                            <small>Main rank team</small>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {setuju == false ? (
                          <>
                            <a
                              href="/peraturanrankteam"
                              style={{ textDecoration: "none" }}
                            >
                              <img
                                src="./img/menu/mteam1.png"
                                className="img-fluid mb-2"
                                style={{
                                  borderRadius: "10px",
                                  height: "50px",
                                }}
                              ></img>
                              <br />
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              href="/daftarteamrankteam"
                              style={{ textDecoration: "none" }}
                            >
                              <img
                                src="./img/menu/mteam1.png"
                                className="img-fluid mb-2"
                                style={{
                                  borderRadius: "10px",
                                  height: "50px",
                                }}
                              ></img>
                              <br />
                            </a>
                          </>
                        )}

                        {team == false ? (
                          <>
                            {" "}
                            <small>Daftar team</small>
                          </>
                        ) : (
                          <>
                            {" "}
                            <small>Main rank team</small>
                          </>
                        )}
                      </>
                    )}
                  </center>
                </div>

                {team == false ? (
                  <>
                    <div className="col-sm-4 col-4">
                      <center>
                        <Link to="/memberkarirteam">
                          <img
                            src="./img/menu/msolo4.png"
                            className="img-fluid mb-2"
                            style={{
                              borderRadius: "10px",
                              height: "50px",
                            }}
                          ></img>
                        </Link>
                        <br />
                        <small>Jadwal rank team</small>
                      </center>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="col-sm-4 col-4">
                  <center>
                    <Link to="/rankingteam">
                      <img
                        src="./img/menu/mteam2.png"
                        className="img-fluid mb-2"
                        style={{
                          borderRadius: "10px",
                          height: "50px",
                        }}
                      ></img>
                    </Link>
                    <br />
                    <small>Ranking team</small>
                  </center>
                </div>

                <div
                  className={
                    team == false ? "col-sm-4 col-4 mt-3" : "col-sm-4 col-4"
                  }
                >
                  <center>
                    <Link to="/hasilrankteam">
                      <img
                        src="./img/menu/msolo3.png"
                        className="img-fluid mb-2"
                        style={{
                          borderRadius: "10px",
                          height: "50px",
                        }}
                      ></img>
                      <br />
                    </Link>
                    <small>Hasil match</small>
                  </center>
                </div>

                {team == false ? (
                  <></>
                ) : (
                  <>
                    <div className="col-sm-4 col-4 mt-3 ">
                      <center>
                        <Link to="/historyrankteam">
                          <img
                            src="./img/menu/msolo4.png"
                            className="img-fluid mb-2"
                            style={{
                              borderRadius: "10px",
                              height: "50px",
                            }}
                          ></img>
                        </Link>
                        <br />
                        <small>History match anda</small>
                      </center>
                    </div>
                  </>
                )}

                <div className="col-sm-4 col-4 mt-3 ">
                  <center>
                    <Link to="/databookingrankteam">
                      <img
                        src="./img/menu/msolo5.png"
                        className="img-fluid mb-2"
                        style={{
                          borderRadius: "10px",
                          height: "50px",
                        }}
                      ></img>
                    </Link>
                    <br />
                    <small>History booking</small>
                  </center>
                </div>

                <div className="col-sm-4 col-4 mt-3 ">
                  <center>
                    <Link to="/datateamrankteam">
                      <img
                        src="./img/menu/mteam3.png"
                        className="img-fluid mb-2"
                        style={{
                          borderRadius: "10px",
                          height: "50px",
                        }}
                      ></img>
                    </Link>
                    <br />
                    <small>Data team</small>
                  </center>
                </div>
              </div>
            </div>
          </div>
          <hr />

          {/* <small
            className="fw-bold text-secondary "
            style={{ fontSize: "12px" }}
          >
            <i className="fas fa-file"></i> Data booking rank team anda
          </small>
          <div className="card mb-2 mt-2">
            <div className="card-body">
              <div
                class="d-flex justify-content-between"
                onClick={() => setHandlelistbooking(!handlelistbooking)}
              ></div>
              {handlelistbooking ? (
                <>
                  <Compbookingrankteam limit="4" />
                </>
              ) : (
                <></>
              )}
            </div>
          </div> */}

          <div className="mt-2">
            <small
              className="fw-bold text-secondary "
              style={{ fontSize: "12px" }}
            >
              <i className="fas fa-shield-halved"></i> Jadwal rank team
            </small>
            <br />
            <div className="mt-2">
              {gamerankteam == null || gamerankteam == false ? (
                <>
                  <div className="card">
                    <div className="card-body">
                      <center>
                        <small className="fw-bold text-secondary">
                          <i className="fas fa-circle-info"></i> Jadwal rank
                          team untuk saat ini belum tersedia{" "}
                        </small>
                      </center>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={4}
                    nav={false}
                    responsive={resvonsive}
                    dotsEach={true}
                    dots={true}
                    autoplay={false}
                  >
                    {gamerankteam.map((data, index) => {
                      return (
                        <div key={index}>
                          <Link
                            to={
                              localStorage.getItem("tamu") !== null
                                ? "#"
                                : "/daftarteamrankteam"
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div className="card" id="bglistwinrate">
                              <div className="card-header">
                                <div
                                  className="d-flex justify-content-between"
                                  style={{ fontSize: "10px" }}
                                >
                                  <small
                                    className="fw-bold"
                                    style={{ fontSize: "10px" }}
                                  >
                                    <i className="fas fa-futbol"></i>{" "}
                                    {data.lapangan}
                                  </small>
                                </div>
                              </div>
                              <div className="card-body">
                                <span
                                  class="badge bg-success mb-2"
                                  style={{ fontSize: "10px" }}
                                >
                                  <small>Rank team {index + 1}</small>
                                </span>
                                <div
                                  className="d-flex justify-content-between"
                                  style={{ fontSize: "10px" }}
                                >
                                  <small className="fw-bold">
                                    <i className="fas fa-calendar-days"></i>{" "}
                                    {data.tgl_main}
                                  </small>
                                  <small className="fw-bold">
                                    <i className="fas fa-clock"></i>{" "}
                                    {data.jam_main}
                                  </small>
                                </div>
                                <hr />
                                <div
                                  className="d-flex justify-content-between text-success"
                                  style={{ fontSize: "10px" }}
                                >
                                  <small className="fw-bold">
                                    <span
                                      class="badge bg-primary mb-2"
                                      style={{ fontSize: "10px" }}
                                    >
                                      Sesi 1 : {data.harga}
                                    </span>
                                  </small>
                                  
                                  <small className={data.jml_team == 2 ? 'd-none': 'fw-bold'}>
                                    <span
                                      class="badge bg-primary mb-2"
                                      style={{ fontSize: "10px" }}
                                    >
                                      Sesi 2 :{" "}
                                      {data.harga2 == "" ? (
                                        <>{data.harga}</>
                                      ) : (
                                        <>{data.harga2}</>
                                      )}
                                    </span>
                                  </small>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </>
              )}
            </div>

            <div>
              <Compmatchrankteam />
            </div>

            <div className="d-flex justify-content-between fw-bold mt-3">
              <small
                className="text-secondary fw-bold"
                style={{ fontSize: "12px" }}
              >
                <i className="fas fa-shield-halved"></i> Hasil rank team terbaru
              </small>

              <small style={{ textDecoration: "none", fontSize: "12px" }}>
                <Link
                  to="/hasilrankteam"
                  className="text-secondary"
                  style={{ textDecoration: "none" }}
                >
                  Lihat semuanya <i className="fas fa-angle-right"></i>
                </Link>
              </small>
            </div>
            {rankteam == false ? (
              <div className="card mt-2 border-0 shadow">
                <div className="card-body text-center">
                  <small className="text-secondary">
                    <i className="fas fa-circle-info"></i> Hasil rank team belum
                    tersedia
                  </small>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="mt-2 mb-5">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={4}
                nav={false}
                responsive={resvonsive2}
                dotsEach={false}
                dots={false}
                autoplay={false}
              >
                {rankteam.slice(0, 4).map((data, index) => {
                  return (
                    <div className="card" key={index}>
                      <div className="card-header">
                        <div
                          className="d-flex justify-content-between fw-bold"
                          style={{ fontSize: "9px" }}
                        >
                          {" "}
                          <small>Rank team</small>
                          <small className="fw-bold">
                            <i className="fas fa-calendar-days"></i>{" "}
                            {data.date.slice(0, 10)}
                          </small>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="d-flex justify-content-between fw-bold">
                          <small style={{ color: "#2b2e5a", fontSize: "12px" }}>
                            {data.team.toUpperCase().substring(0, 8)} ...
                          </small>
                          <span class="badge bg-dark">{data.score_team}</span>
                        </div>
                        <div className="d-flex justify-content-between mt-2 fw-bold">
                          <small style={{ color: "#2b2e5a", fontSize: "12px" }}>
                            {data.lawan.toUpperCase().substring(0, 8)} ...
                          </small>
                          <span class="badge bg-dark">{data.score_lawan}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>

              {/* <div className="mt-3">
                <div className="mb-2">
                  <small
                    className="fw-bold text-secondary"
                    style={{ fontSize: "12px" }}
                  >
                    {" "}
                    <i className="fas fa-shield-halved"></i> Ranking rank team
                  </small>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between fw-bold">
                      <small
                        className={pagerank == true ? "text-primary" : ""}
                        style={{ cursor: "pointer" }}
                        onClick={() => handlmenuGoal("rank")}
                      >
                        <i className="far fa-star"></i> Rank
                      </small>
                      <small
                        className={pagescore == true ? "text-primary" : ""}
                        style={{ cursor: "pointer" }}
                        onClick={() => handlmenuGoal("score")}
                      >
                        <i className="far fa-futbol"></i> Top score
                      </small>

                      <small
                        className={pageassist == true ? "text-primary" : ""}
                        style={{ cursor: "pointer" }}
                        onClick={() => handlmenuGoal("assist")}
                      >
                        <i className="fas fa-repeat"></i> Top assist
                      </small>
                    </div>
                    <hr></hr>
                    {pagerank == true || pageawal == true ? (
                      <>
                        {" "}
                        <Comprank />
                      </>
                    ) : pagescore == true ? (
                      <>
                        <Comptopscore />
                      </>
                    ) : pageassist == true ? (
                      <>
                        {" "}
                        <Compassist />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
