import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Compprofil({ id_user }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [profil, setProfil] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(urlapi + "Profil?id=" + id_user);
      setProfil(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      {profil.image == "" || profil.image == null ? (
        <>
          <div
            id="imagebg"
            style={{
              backgroundImage: `url('https://www.pngitem.com/pimgs/m/35-350426_profile-icon-png-default-profile-picture-png-transparent.png')`,
            }}
          >
            <div className="container mt-2">
              <div className="d-flex justify-content-between">
                <img
                  src="./img/topplayer.png"
                  className="img-fluid"
                  style={{
                    height: "50px",
                    width: "50px",
                  }}
                ></img>

                <div>
                  <img
                    src="./img/logomms.png"
                    className="img-fluid"
                    style={{
                      height: "50px",
                      width: "50px",
                    }}
                  ></img>
                  <img
                    src="./img/indo.png"
                    className="img-fluid mt-2"
                    style={{
                      height: "37px",
                      width: "50px",
                    }}
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            id="imagebg"
            style={{
              backgroundImage: `url(${profil.image})`,
            }}
          >
            <div className="container mt-2">
              <div className="d-flex justify-content-between">
                <img
                  src="./img/topplayer.png"
                  className="img-fluid"
                  style={{
                    height: "50px",
                    width: "50px",
                  }}
                ></img>

                <div>
                  <img
                    src="./img/logomms.png"
                    className="img-fluid"
                    style={{
                      height: "50px",
                      width: "50px",
                    }}
                  ></img>
                  <img
                    src="./img/indo.png"
                    className="img-fluid mt-2"
                    style={{
                      height: "37px",
                      width: "50px",
                    }}
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
