import React from 'react'

export default function Loading() {
  return (
      <div>
          <div className='text-center mt-5'>
                <div class="spinner-grow text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-danger" role="status">
                  <span class="visually-hidden">Loading...</span>
               </div>
              
               <div class="spinner-grow text-warning" role="status">
                 <span class="visually-hidden">Loading...</span>
              </div>
              
              <h5 className='fw-bold'>Loading ....</h5>
          </div>
    </div>
  )
}
