import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";

export default function Comptopassist() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [topassist, setTopassist] = useState([]);
  const [page, setPage] = useState(false);
  const [jml, setJml] = useState(10);

  const getTopassist = async () => {
    try {
      const response = await axios.get(urlapi + "Topassistrankteam");
      console.log(response.data);
      setTopassist(response.data);
    } catch (error) {}
  };

  const handleShow = (status) => {
    if (status == false) {
      setPage(false);
      setJml(5000);
    } else {
      setPage(true);
      setJml(10);
    }
  };

  useEffect(() => {
    getTopassist();
  }, []);
  return (
    <div>
      {page == false ? (
        <>
          {" "}
          <small className="text-secondary">Posisi top assist 10 besar</small>
        </>
      ) : (
        <small className="text-secondary">Posisi top assist rank team</small>
      )}

      <div className="table-responsive">
        <table class="table table-striped" style={{ fontSize: "12px" }}>
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Nama</th>
              <th scope="col">Top assist</th>
            </tr>
          </thead>
          <tbody>
            {topassist.map((data, index) => {
              return (
                <tr
                  key={index}
                  className={
                    localStorage.getItem("id") == data.id_user
                      ? "table-danger"
                      : index + 1 > jml
                      ? "d-none"
                      : "table-danger"
                  }
                >
                  <th scope="row">{index + 1}</th>
                  <td className="table-success">{data.nama}</td>
                  <td className="table-warning">{data.jml} Assist</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {page == false ? (
          <>
            {" "}
            <small
              className="text-secondary"
              style={{ cursor: "pointer" }}
              onClick={() => handleShow(true)}
            >
              Lihat semuanya <i class="fa-solid fa-angle-right"></i>
            </small>
          </>
        ) : (
          <>
            {" "}
            <small
              className="text-secondary"
              style={{ cursor: "pointer" }}
              onClick={() => handleShow(false)}
            >
              Tutup ranking <i class="fa-solid fa-angle-right"></i>
            </small>
          </>
        )}
      </div>
    </div>
  );
}
