import React from "react";

export default function Compheader({ team, logo }) {
  return (
    <div>
      <div>
        <div
          className="card text-white"
          style={{
            borderRadius: "0px",
            backgroundImage:
              "url('https://p4.wallpaperbetter.com/wallpaper/398/874/541/champions-league-stadium-wallpaper-preview.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "containt",
            backgroundPosition: "center",
          }}
        >
          <div className="container my-5">
            <center>
              <img
                src={logo}
                className="img-fluid mt-4"
                alt=""
                style={{
                  height: "150px",
                  width: "150px",
                  borderRadius: "100%",
                  borderStyle: "solid",
                }}
              ></img>
            </center>
            <h4 className="card-title fw-bold text-center mt-3 mb-4">
              Hello Team {team}
            </h4>
            {/* <p className="text-center text-warning">
              Apakah anda sudah melengkapi data profil<br></br>
            </p> */}
            <center></center>
          </div>
        </div>
      </div>
    </div>
  );
}
