import axios from "axios";
import React, { useState, useEffect } from "react";

export default function Compgoal({ kode, kode_main, team, kode_team }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [goal, setGoal] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Goalrankteam?kode_main=" +
          kode_main +
          "&&kode_team=" +
          kode_team +
          "&&team=" +
          team +
          "&&kode=" +
          kode
      );
      //   console.log(response.data);
      setGoal(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <div>
      {goal.map((data, index) => {
        return (
          <div className="text-white" key={index} style={{ fontSize: "5px" }}>
            <i className="fas fa-futbol"></i>{" "}
            {data.nama == "" ? <>Own goal</> : <>{data.nama}</>}
          </div>
        );
      })}
    </div>
  );
}
