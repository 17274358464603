import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useState } from "react";
import { imageDb } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

export default function Compteam() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [namateam, setNamateam] = useState("");
  const [nameImg, setNameImg] = useState("");
  const [valueimg, setvalueimg] = useState("");
  const [img, setImg] = useState("");
  const [teamrank, setTeamrank] = useState([]);
  const [load, setLoad] = useState(false);
  const [ubahteam, setUbahteam] = useState(false);
  const [cekbookingteam, setCekbookingteam] = useState([]);

  const navigate = useNavigate();

  const notify = () =>
    toast.success("Data team berhasil di tambah !", {
      position: toast.POSITION.TOP_CENTER,
    });
  const notifyupdate = () =>
    toast.success("Data team berhasil di ubah !", {
      position: toast.POSITION.TOP_CENTER,
    });

  const handleChangeImg = (e) => {
    setImg(e.target.files[0]);
    // console.log(e);
    setvalueimg(e.target.value);
    setNameImg(e.target.files[0].name);
  };

  const handleDatateam = () => {
    setLoad(true);
    // setLoading(true);
    // setLoadingpage(true);
    const imgRef = ref(imageDb, `files/${nameImg}`);
    uploadBytes(imgRef, img);
    console.log(imgRef._location.path_);

    setTimeout(() => {
      getDownloadURL(ref(imageDb, `files/${nameImg}`)).then((url) => {
        console.log("prosess");

        // setForm(false);
        if (ubahteam == false) {
          addDatateam(url);
        } else {
          ubahDatateam(url);
        }
      });
    }, 10000);
  };

  const addDatateam = async (url) => {
    await axios
      .post(urlapi + "Datateam", {
        id_user: localStorage.getItem("id"),
        namateam: namateam,
        logoteam: url,
      })

      .then((response) => {
        getteam();
        notify();
        navigate("/daftarteamrankteam/add");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const ubahDatateam = async (url) => {
    await axios
      .post(urlapi + "Datateam", {
        id_user: localStorage.getItem("id"),
        namateam: namateam,
        logoteam: url,
        ubah: "1",
      })

      .then((response) => {
        getteam();
        notifyupdate();
        navigate("/daftarteamrankteam/add");
        setUbahteam(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const getteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Datateam?id_user=" + localStorage.getItem("id")
      );
      setTeamrank(response.data);
    } catch (error) {}
  };

  const cekteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Cekbookingteam?id_user=" + localStorage.getItem("id")
      );
      setCekbookingteam(response.data.id_user);
      // console.log(response.data.id_user);
    } catch (error) {}
  };

  useEffect(() => {
    getteam();
    cekteam();
  }, []);

  return (
    <div>
      {teamrank != false && ubahteam == false ? (
        <>
          <center>
            <img
              src={teamrank.logo_team}
              className="img-fluid "
              alt=""
              style={{
                height: "150px",
                width: "150px",
                //   borderRadius: "100%",
                //   borderStyle: "solid",
              }}
            ></img>
            <br />
            <div className="mt-2">
              <small className="fw-bold mt-3">{teamrank.nama_team}</small>
              {cekbookingteam == null || cekbookingteam == "" ? (
                <>
                  <br />
                  <small
                    className="text-primary"
                    onClick={() => setUbahteam(true)}
                    style={{ cursor: "pointer" }}
                  >
                    Ubah team anda ?
                  </small>
                </>
              ) : (
                <>
                  <br />
                  <small
                    className="text-primary"
                    onClick={() => setUbahteam(true)}
                    style={{ cursor: "pointer" }}
                  >
                    Ubah logo team anda ?
                  </small>
                </>
              )}
            </div>
          </center>
        </>
      ) : (
        <>
          {" "}
          <Form.Group className="mb-3" controlId="formBasicupload">
            <label for="images" class="drop-container" id="dropcontainer">
              <span class="drop-title">
                {nameImg == "" ? (
                  "Upload logo"
                ) : (
                  <>{load == true ? <> Loading ....</> : ""}</>
                )}
              </span>

              <input
                type="file"
                id="images"
                className="form-control"
                accept="image/*"
                onChange={(e) => handleChangeImg(e)}
                required
              />
            </label>
            {cekbookingteam == null || cekbookingteam == "" ? (
              <>
                {" "}
                <div className="row">
                  <div className="col-sm-8 col-9">
                    {" "}
                    <input
                      type="text"
                      value={namateam}
                      className="form-control form-control-sm mt-3"
                      placeholder="Masukan nama team"
                      onChange={(e) => setNamateam(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-4 col-3">
                    {" "}
                    <button
                      className="btn btn-primary btn-sm mt-3"
                      disabled={namateam == "" || nameImg == "" ? true : false}
                      onClick={() => handleDatateam()}
                    >
                      {ubahteam == true ? "Ubah" : "Add"}
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                {" "}
                <button
                  className="btn btn-primary w-100 btn-sm mt-3 rounded-pill"
                  disabled={nameImg == "" ? true : false}
                  onClick={() => handleDatateam()}
                >
                  {ubahteam == true ? "Ubah" : "Add"}
                </button>
              </>
            )}

            <ToastContainer />
          </Form.Group>
        </>
      )}
    </div>
  );
}
