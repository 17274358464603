import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Loadmenu from "../skeleton/Loadmenu";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

export default function CardMenu() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [load, setLoad] = useState(false);
  const [pesan, setPesan] = useState("");
  const [pesan2, setPesan2] = useState("");
  const[datavoucher, setDatavoucher] = useState([])

  const notify = () =>
    toast.warning("Untuk saat ini fitur belum tersedia !", {
      position: toast.POSITION.TOP_CENTER,
    });

  const cekpesanbooking = async () => {
    try {
      const response = await axios.get(urlapi + "Pesanbookingspesial");
      // console.log(response.data);
      setPesan(response.data.pesan);
    } catch (error) {}
  };

  const cekpesanbooking2 = async () => {
    try {
      const response = await axios.get(urlapi + "Pesanbookingspesial2");
      // console.log(response.data);
      setPesan2(response.data.pesan);
    } catch (error) {}
  };

  const voucher = async () => {
    try {
      const response = await axios.get(urlapi + "Voucher?iduser=" + localStorage.getItem('id') + "&&notif=1");
      // console.log(response.data);
      setDatavoucher(response.data);
    } catch (error) {
      
    }
  }

  useEffect(() => {
    cekpesanbooking();
    cekpesanbooking2();
    voucher();
    setTimeout(() => {
      setLoad(true);
    }, 500);
  }, []);
  return (
    <div>
      <div className="container">
        {load == false ? <Loadmenu /> : ""}
        <div
          className={load ? "card cardMenu shadow" : "d-none"}
          style={{ border: "none" }}
        >
          <div className="card-body">
            <div className="row">
              <div className="col-sm-3 col-3">
                <Link to={"/home"} style={{ textDecoration: "none" }}>
                  <center>
                    <img
                      src="img/menu/menuhome.webp"
                      className="img-fluid"
                      alt="Responsive image"
                    />
                    <p
                      className="text-primary mt-2 fw-bold"
                      style={{ fontSize: "12px" }}
                    >
                      Home
                    </p>
                  </center>
                </Link>
              </div>
              <div className="col-sm-3 col-3">
                <Link to={"/voucher"} style={{ textDecoration: "none" }}>
                  <center>

                    {datavoucher == '' ? <> <img
                      src="img/menu/menuvc.webp"
                      className="img-fluid"
                      alt="Responsive image"
                    /></> : <> <img
                      src="img/menu/menuvc2.webp"
                      className="img-fluid"
                      alt="Responsive image"
                    /></>}
                   
                    <p
                      className="text-primary fw-bold mt-2"
                      style={{ fontSize: "12px" }}
                    >
                      Voucher
                    </p>
                  </center>
                </Link>
              </div>

              <div className="col-sm-3 col-3">
                <Link to={"/booking"} style={{ textDecoration: "none" }}>
                  <center>
                    <img
                      src="img/menu/mebubooking.webp"
                      class="img-fluid"
                      alt="Responsive image"
                    />
                    <p
                      className="text-primary fw-bold mt-2"
                      style={{ fontSize: "12px" }}
                    >
                      Booking
                    </p>
                  </center>
                </Link>
              </div>

              {/* <div className="col-sm-3 col-3">
                <Link to="/booking/data" style={{ textDecoration: "none" }}>
                  <center>
                    <img
                      src="img/rupiah.png"
                      class="img-fluid"
                      alt="Responsive image"
                    />
                    <p
                      className="text-primary font-weight-bold mt-2"
                      style={{ fontSize: "12px" }}
                    >
                      Pelunasan
                    </p>
                  </center>
                </Link>
              </div> */}

              <div className="col-sm-3 col-3">
                <Link to="/bookingspesial" style={{ textDecoration: "none" }}>
                  <center>
                    {pesan == "kosong" && pesan2 == "kosong" ? (
                      <>
                        {" "}
                        <img
                          src="img/menu/menubooking1jam.webp"
                          class="img-fluid"
                          alt="Responsive image"
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src="img/menu/mebubooking1jam.webp"
                          class="img-fluid"
                          alt="Responsive image"
                        />
                      </>
                    )}

                    <p
                      className="text-primary fw-bold mt-2"
                      style={{ fontSize: "12px" }}
                    >
                      Booking Spesial
                    </p>
                  </center>
                </Link>
              </div>

              <div className="col-sm-3 col-3">
                {/* <Link
                  to={"/listbookingkarir"}
                  style={{ textDecoration: "none" }}
                > */}
                <Link to="/booking/data" style={{ textDecoration: "none" }}>
                  <center>
                    <img
                      src="img/menu/menudatabaokking.webp"
                      class="img-fluid"
                      alt="Responsive image"
                    />
                    <p
                      className="text-primary fw-bold mt-2"
                      style={{ fontSize: "12px" }}
                    >
                      Data Booking
                    </p>
                  </center>
                </Link>
              </div>

              <div className="col-sm-3 col-3">
                {/* <Link to={"/statistik"} style={{ textDecoration: "none" }}> */}
                {/* <Link to="/rankcompetition" style={{ textDecoration: "none" }}> */}
                <Link to="/rankleague" style={{ textDecoration: "none" }}>
                  <center>
                    <img
                      src="img/menu/menurank.webp"
                      class="img-fluid"
                      alt="Responsive image"
                    />
                    <p
                      className="text-primary fw-bold mt-2"
                      style={{ fontSize: "12px" }}
                    >
                      Rank League
                    </p>
                  </center>
                </Link>
                {/* </Link> */}
              </div>

              <div className="col-sm-3 col-3">
                {/* <Link to={"/updatescore"} style={{ textDecoration: "none" }}> */}
                <Link to="/liga" style={{ textDecoration: "none" }}>
                  <center>
                    <img
                      src="img/menu/menuliga.webp"
                      class="img-fluid"
                      alt="Responsive image"
                    />
                    <p
                      className="text-primary fw-bold mt-2"
                      style={{ fontSize: "12px" }}
                    >
                      League EOF
                    </p>
                  </center>
                </Link>
              </div>

              <div className="col-sm-3 col-3">
                <Link to={"/profilanda"} style={{ textDecoration: "none" }}>
                  <center>
                    <img
                      src="img/menu/menuprofil.webp"
                      class="img-fluid"
                      alt="Responsive image"
                    />
                    <p
                      className="text-primary fw-bold mt-2"
                      style={{ fontSize: "12px" }}
                    >
                      Profil
                    </p>
                  </center>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
