import React from "react";
import Navbar from "../componenst/Navbar";
import Compmainhasilrank from "../componenst/Comphasilranksolo/Compmainhasilrank";

export default function Hasilranksolo() {
  return (
    <div>
      <Navbar judul="Hasil rank solo" aicon="true" />
      <Compmainhasilrank />
    </div>
  );
}
