import React from "react";
import Complogin from "../componenst/Login/Complogin";

export default function Login() {
  return (
    <div className="login">
      <Complogin />
    </div>
  );
}
