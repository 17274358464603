import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Complogoteam({ team }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [logo, setLogo] = useState("");
  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi + "Logoteamrankteam?team=" + team
      );
      // console.log(response.data);
      setLogo(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    getdata();
  }, [team]);

  return (
    <div>
      <center>
        <img
          src={logo.logo_team}
          className="img-fluid rounded-circle"
          alt=""
          style={{ height: "100px", width: "100px", border: "5px solid white" }}
        />
      </center>
      <div className="text-center">
        <span class="badge text-bg-dark mt-2">Pool {logo.pool}</span>
      </div>
    </div>
  );
}
