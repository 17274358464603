import React, { useEffect, useState } from "react";
import axios from "axios";
import Compnama from "./Compnama";

export default function Comptopassist() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [assist, setAssist] = useState([]);
  const [jml, setJml] = useState(10);
  const [page, setPage] = useState(false);

  const getAssist = async () => {
    try {
      const response = await axios.get(urlapi + "Gettopassistmemberkarir");
      setAssist(response.data);
    } catch (error) {}
  };

  const handleShow = (status) => {
    if (status == true) {
      setPage(true);
      setJml(500000);
    } else {
      setPage(false);
      setJml(10);
    }
  };

  useEffect(() => {
    getAssist();
  }, []);
  return (
    <div>
      <hr />
      <small className="text-secondary">Posisi top assist anda</small>
      <div className="table-responsive">
        <table class="table table-striped" style={{ fontSize: "12px" }}>
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Nama</th>
              <th scope="col">Top assist</th>
            </tr>
          </thead>
          <tbody>
            {assist.map((data, index) => {
              return (
                <tr
                  key={index}
                  className={
                    localStorage.getItem("id") == data.id_user ? "" : "d-none"
                  }
                >
                  <th className="table-danger" scope="row">
                    {index + 1}
                  </th>
                  <td className="teable-success">
                    <Compnama iduser={data.id_user} />
                  </td>
                  <td className="table-warning">{data.jml} Assist</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {page == false ? (
        <>
          {" "}
          <small className="text-secondary">Posisi top assist 10 besar</small>
        </>
      ) : (
        <small className="text-secondary">Posisi top assist member karir</small>
      )}

      <div className="table-responsive">
        <table class="table table-striped" style={{ fontSize: "12px" }}>
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Nama</th>
              <th scope="col">Top assist</th>
            </tr>
          </thead>
          <tbody>
            {assist.map((data, index) => {
              return (
                <tr
                  key={index}
                  className={
                    localStorage.getItem("id") == data.id_user
                      ? "table-danger fw-bold"
                      : index + 1 > jml
                      ? "d-none"
                      : "table-danger"
                  }
                >
                  <th scope="row">{index + 1}</th>
                  <td className="table-success">
                    <Compnama iduser={data.id_user} />
                  </td>
                  <td className="table-warning">{data.jml} Assist</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {page == false ? (
          <>
            {" "}
            <small
              className="text-secondary"
              style={{ cursor: "pointer" }}
              onClick={() => handleShow(true)}
            >
              Lihat semuanya <i class="fa-solid fa-angle-right"></i>
            </small>
          </>
        ) : (
          <>
            {" "}
            <small
              className="text-secondary"
              style={{ cursor: "pointer" }}
              onClick={() => handleShow(false)}
            >
              Tutup ranking <i class="fa-solid fa-angle-right"></i>
            </small>
          </>
        )}
      </div>
    </div>
  );
}
