import React, { useEffect, useState } from "react";
import Navbar from "../componenst/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

export default function Peraturanrankteam() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [setuju, setSetuju] = useState(false);

  const navigate = useNavigate();

  const handleperaturan = async () => {
    await axios
      .post(urlapi + "Peraturan", {
        id_user: localStorage.getItem("id"),
        peraturan: "rank team",
      })
      .then((response) => {
        console.log(response.data);
        navigate("/daftarteamrankteam");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const cekperaturan = async () => {
    try {
      const response = await axios.get(
        urlapi +
        "Peraturan?rankteam=true&&id_user=" +
        localStorage.getItem("id")
      );
      if (response.data !== "") {
        setSetuju(true);
      }
    } catch (error) { }
  };

  const notify = () =>
    toast.warning("Anda belum menyetujui peraturan ini !", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  useEffect(() => {
    cekperaturan();
  }, []);

  return (
    <div>
      <Navbar judul="Rank team" aicon="true" />
      <br></br>
      <br></br>
      <br></br>
      <div className="container">
        <center>
          <img
            src="./img/regulasi.svg"
            className="img-fluid mt-2"
            style={{ borderRadius: "10px", height: "200px" }}
          ></img>
          <h4 className="mt-4">Peraturan rank team</h4>
        </center>

        <div>
          <ul style={{ fontSize: "12px" }}>
            <li>Pengguna harus mempunyai nama team dan logo team</li>
            <li>Team harus mempunyai pemain dan jersy</li>
            <li>
              Maksimal jumlah pemain yang didaftarkan sebanyak 25 pemain

            </li>
            <li>
              Pemain yang di daftarkan harus pemain nonpro/nonexpro dalam bidang
              sepakbola dan futsal
            </li>
            <li>
              Nama pemain dan NIK pemain harus sesuai dengan data diri pemain
            </li>
            <li>
              Pengguna wajib membayar sesuai jumlah di aplikasi di saat booking
              rank team
            </li>
            <li>
              Pengguna yang sudah melakukan booking rank team dan di setujui
              admin wajib datang sesuai tanggal dan jam yang tertera di aplikasi
            </li>
            <li>
              Data Pemain tidak dabat di tambah dan diubah ketika  data team anda sudah di setujui admin
            </li>
            <li>
              Pemain tidak dapat di tambah jika jumlah pemain sudah memenuhi
              kuota
            </li>
            <li>
              Perhitungan point rank team sebagai berikut :  menang = 3 Point, Draw = 1 Point, Kalah = 0 Point
            </li>
          </ul>
        </div>
        <div className="form-check mb-2">
          <input
            className="form-check-input border-primary"
            type="checkbox"
            checked={setuju == false ? false : true}
            onClick={() => setSetuju(!setuju)}
            id="defaultCheck1"
          />
          <small className="form-check-label" for="defaultCheck1">
            Apakah anda menyetujui peraturan rank team medan mini soccer ini ?
          </small>
        </div>

        {setuju == true ? (
          <>
            <div class="alert alert-success fw-bold" role="alert">
              <small>
                <i className="fas fa-circle-check"></i> Peraturan rank team
                berhasil anda setujui, silahkan bermain rank team sekarang
              </small>
            </div>
          </>
        ) : (
          ""
        )}

        {setuju == false ? (
          <>
            {" "}
            <button
              onClick={() => notify()}
              className="btn  w-100 rounded-pill my-3"
              id="btnmmsc"
            >
              Bermain rank team {setuju}
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => handleperaturan()}
              className="btn  w-100 rounded-pill my-3"
              id="btnmmsc"
            >
              Bermain rank team {setuju}
            </button>
          </>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}
