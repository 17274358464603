import React from "react";
import Navbar from "../componenst/Navbar";
import Compmain from "../componenst/Comprankkompetition/Compmain";
export default function Rankkompetisi() {
  return (
    <div>
      <Navbar judul="Rank league" aicon="true" />
      <Compmain />
    </div>
  );
}
