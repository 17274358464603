import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

export default function Jambookingspesial({
  kode_main,
  tgl_main,
  jam_mulai,
  jam_selesai,
  lapangan,
  tgl,
  id,
}) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [jmlbooking, setJmlbooking] = useState([]);
  const [booking, setBooking] = useState([]);
  const [ket, setKet] = useState(false);

  const getjam = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Getlistjammainbookingspesial?kode_main=" +
          kode_main +
          "&&tglmain=" +
          tgl
      );
      setJmlbooking(response.data);
      // console.log(response.data);
    } catch (error) {}
  };

  const cekBooking = async () => {
    try {
      const response = await axios.get(
        urlapi + "Cekbookingspesial?kode_main=" + kode_main
      );
      // console.log(response.data);
      setBooking(response.data);
    } catch (error) {}
  };

  const notify = () =>
    toast.warning("Maaf. Jam booking sudah digunakan", {
      position: toast.POSITION.TOP_CENTER,
    });

  const handlealert = () => {
    if (booking.status_pembayaran == 200) {
      notify();
      <ToastContainer />;
    }
  };

  useEffect(() => {
    getjam();
    cekBooking();
  }, []);

  return (
    <div onClick={() => handlealert()}>
      {jmlbooking.jml == 2 ? (
        <>
          <div
            className={
              id == jmlbooking.id ? "card mt-2 border-primary" : "card mt-2"
            }
          >
            <div className="card-body">
              <div
                className="d-flex justify-content-between fw-bold"
                style={{ fontSize: "14px" }}
              >
                <small className="fw-bold">{lapangan}</small>
                <small>Booking spesial</small>
              </div>

              <div>
                <small className="fw-bold">
                  {" "}
                  <i className="fas fa-calendar-days"></i> {tgl}
                </small>
              </div>

              <small className="fw-bold text-danger">
                {booking.status_pembayaran == "200" ? <>Booked</> : ""}
              </small>
              <small className="fw-bold text-danger">
                {booking.status_pembayaran == "201" &&
                localStorage.getItem("id") == booking.iduser ? (
                  <>Menuggu Approve</>
                ) : (
                  ""
                )}
              </small>
              <hr />
              <div className="d-flex justify-content-between fw-bold">
                <small>{jmlbooking.jam}</small>
                <small>Rp. {jmlbooking.harga}</small>{" "}
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
