import React, { useEffect, useState } from "react";
import Navbar from "../componenst/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

export default function Peraturanranksolo() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [setuju, setSetuju] = useState(false);

  const navigate = useNavigate();

  const handleperaturan = async () => {
    await axios
      .post(urlapi + "Peraturan", {
        id_user: localStorage.getItem("id"),
        peraturan: "rank solo",
      })
      .then((response) => {
        console.log(response.data);
        navigate("/memberkarirbaru");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const cekperaturan = async () => {
    try {
      const response = await axios.get(
        urlapi +
        "Peraturan?ranksolo=true&&id_user=" +
        localStorage.getItem("id")
      );
      if (response.data !== "") {
        setSetuju(true);
      }
    } catch (error) { }
  };

  const notify = () =>
    toast.warning("Anda belum menyetujui peraturan ini !", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  useEffect(() => {
    cekperaturan();
  }, []);

  return (
    <div style={{ height: "750px" }}>
      <Navbar judul="Rank solo" aicon="true" />
      <br></br>
      <br></br>
      <br></br>
      <div className="container">
        <center>
          <img
            src="./img/regulasi.svg"
            className="img-fluid mt-2"
            style={{ borderRadius: "10px", height: "200px" }}
          ></img>
          <h4 className="mt-4">Peraturan rank solo</h4>
        </center>

        <div>
          <ul style={{ fontSize: "12px" }}>
            <li>Pemain harus mempunyai akun medan mini soccer</li>
            <li>Pemain harus menentukan posisi bermain</li>

            <li>
              Pemain wajib membayar sesuai jumlah di aplikasi di saat booking
              rank solo{" "}
            </li>
            <li>
              Pemain yang sudah melakukan booking rank solo dan di setujui admin
              wajib datang sesuai tanggal dan jam yang tertera di aplikasi
            </li>
            <li>Team pemain akan di acak sesui dengan aplikasi</li>

            <li>
              Perhitungan point rank solo sebagai berikut :  menang = 3 Point, Draw = 1 Point, Kalah = -2 Point
            </li>
          </ul>
        </div>
        <div className="form-check mb-2">
          <input
            className="form-check-input border-primary"
            type="checkbox"
            checked={setuju == false ? false : true}
            onClick={() => setSetuju(!setuju)}
            id="defaultCheck1"
          />
          <small className="form-check-label" for="defaultCheck1">
            Apakah anda menyetujui peraturan rank solo medan mini soccer ini ?
          </small>
        </div>

        {setuju == true ? (
          <>
            <div class="alert alert-success fw-bold" role="alert">
              <small>
                <i className="fas fa-circle-check"></i> Peraturan rank solo
                berhasil anda setujui, silahkan bermain rank solo sekarang
              </small>
            </div>
          </>
        ) : (
          ""
        )}

        {setuju == false ? (
          <>
            {" "}
            <button
              onClick={() => notify()}
              className="btn  w-100 rounded-pill mt-3"
              id="btnmmsc"
            >
              Bermain rank solo {setuju}
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => handleperaturan()}
              className="btn  w-100 rounded-pill mt-3"
              id="btnmmsc"
            >
              Bermain rank solo {setuju}
            </button>
          </>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}
