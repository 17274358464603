import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Compwinrate({ id_user }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [winrate, setWinrate] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(urlapi + "Winrate2?id_user=" + id_user);
      //   console.log(response.data);
      setWinrate(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getdata();
  }, []);
  return (
    <div>
      <p>{winrate.persen} %</p>
    </div>
  );
}
