import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Databookingspesial() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi + "Bookingspesial?id_user=" + localStorage.getItem("id")
      );
      setData(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <div>
      {data == null || data == false ? (
        <>
          <center>
            <img
              src="img/dataspesial.png"
              class="img-fluid mt-5"
              alt=""
              style={{ height: "200px" }}
            ></img>

            <h5 className="mt-4">Data booking spesial anda masih kosong</h5>
          </center>
        </>
      ) : (
        <>
          {" "}
          {data.map((dd, index) => {
            return (
              <div className="card mb-2 shadow" key={index}>
                <div className="card-body">
                  <div class="d-flex justify-content-between">
                    <small>
                      {" "}
                      <i className="fas fa-futbol"></i> {dd.lapangan}
                    </small>
                    <small>
                      <i className="fas fa-calendar-days"></i> {dd.tgl}
                    </small>
                  </div>
                  <hr />
                  <div class="d-flex justify-content-between">
                    <small>
                      {" "}
                      <i className="far fa-clock"></i> {dd.jam_booking}
                    </small>
                    {dd.status_pembayaran == 201 ? (
                      <>
                        <small className="text-warning fw-bold">Menunggu</small>
                      </>
                    ) : dd.status_pembayaran == 200 ? (
                      <>
                        <small className="text-success fw-bold">
                          Disetujui
                        </small>
                      </>
                    ) : (
                      <>
                        <small className="text-danger fw-bold">Ditolak</small>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}
