import React from "react";
import Compmainstatistik from "../componenst/Membarkarirteam/Compmainstatistik";
import Navbar from "../componenst/Navbar";

export default function Statistikteam() {
  return (
    <div>
      <Navbar judul="Statistik team" aicon="true" />
      <Compmainstatistik />
    </div>
  );
}
