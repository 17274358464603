import React from "react";

export default function Compheader({ team }) {
  return (
    <div>
      <div className="card" id="rankteam">
        <div className="card-body">
          <h5 className="mt-5 fw-bold text-white">Hello team {team}</h5>
          <label className="text-white">
            Ayoo buruan daftar rank team anda sekarang juga dan dapatkan lawan
            bermain anda
          </label>
        </div>
      </div>
    </div>
  );
}
