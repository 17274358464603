import React, { useState, useEffect } from "react";
import Compheader from "./Compheader";
import Comppembayaranrankteam from "./Comppembayaranrankteam";
import axios from "axios";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Compsesi from "./Compsesi";
import Comprank from "./Comprank";
import Comptopscore from "./Comptopscore";
import Comptopassist from "./Comptopassist";
import Complogoteam from "./Complogoteam";
import Compbookingrankteam from "./Compbookingrankteam";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export default function Compmain() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [game, setGame] = useState([]);
  const [team, setTeam] = useState([]);
  const [harga, setHarga] = useState("");
  const [memberteam, setMemberteam] = useState([]);
  const [sesimain, setSesimain] = useState([]);
  const [sesi, setSesi] = useState("");
  const [lawan, setLawan] = useState([]);
  const [point, setPoint] = useState([]);
  const [pagerank, setPagerank] = useState(false);
  const [pagescore, setPagescore] = useState(false);
  const [pageassist, setPageassist] = useState(false);
  const [ceksesi, setCeksesi] = useState("");
  const [gamerankteam, setGamerankteam] = useState([]);
  const [handlegame, setHandlegame] = useState("");
  const [cekgamerankteam, setCekgamrerankteam] = useState([]);
  const [rangking, setRanking] = useState([]);
  const [kodeteam, setkodeteam] = useState("");
  const [handlelistbooking, setHandlelistbooking] = useState(true);
  const [bordermenugame, setBordermenugame] = useState("");
  const [bookinguser1, setBookinguser1] = useState("");
  const [bookinguser2, setBookinguser2] = useState("");
  const [booking, setBooking] = useState([]);
  const [kuotapemain, setkuotapemain] = useState(0);
  const [jmlpemain, setJmlpemain] = useState(0);
  const [datateam, setDatateam] = useState("");
  // const [cekdatabooking, setCekdatabooking] = useState([]);

  const datarangking = async () => {
    try {
      const response = await axios.get(
        urlapi + "Rangkingrankteam?id_user=" + localStorage.getItem("id")
      );
      setRanking(response.data.data);
      setkodeteam(response.data.kode_team);
    } catch (error) {}
  };

  const datagame = async (kode_main) => {
    try {
      const response = await axios.get(
        urlapi + "Gamerankteam?kode_main=" + kode_main
      );
      setGame(response.data);
    } catch (error) {}
  };

  const hadlepilihgame = (kode_main) => {
    datagame(kode_main);
    sesiMain(kode_main);
    setHandlegame(kode_main);
    setBordermenugame(kode_main);
    cekkuotasesi(kode_main);
    cekbookinguser(kode_main);
    setBookinguser1("");
    setBookinguser2("");
    setSesi("");
  };

  const handlesesi = (sesi, kode_main) => {
    setSesi(sesi);
    getsesibaru(sesi, kode_main);
    if (sesi == 1) {
      setHarga(game.harga);
    } else if (sesi == 2 && game.harga2 != "") {
      setHarga(game.harga2);
    } else {
      setHarga(game.harga);
    }
  };

  const getsesibaru = async (sesi, kode_main) => {
    try {
      const response = await axios.get(
        urlapi + "Getsesirankteambaru?kode=" + kode_main + "&&sesi=" + sesi
      );
      // console.log(response.data);
    } catch (error) {}
  };

  const cekbookinguser = async (kode_main) => {
    try {
      const response = await axios.get(
        urlapi +
          "Cekbookingrankteam?kode_main=" +
          kode_main +
          "&&id_user=" +
          localStorage.getItem("id")
      );

      // console.log(response.data);
      // setBooking(response.data);
      if (response.data.sesi1 != null && response.data.sesi2 != null) {
        setBookinguser1(response.data.sesi1.sesi);
        setBookinguser2(response.data.sesi2.sesi);
      } else if (response.data.sesi1 != null && response.data.sesi2 == null) {
        setBookinguser1(response.data.sesi1.sesi);
      } else if (response.data.sesi2 != null && response.data.sesi1 == null) {
        setBookinguser2(response.data.sesi2.sesi);
      }

      // setBookinguser2(response.data.sesi2.sesi);
    } catch (error) {}
  };

  const geteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Datateam?id_user=" + localStorage.getItem("id")
      );
      setTeam(response.data);
    } catch (error) {}
  };

  const getmemberteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Memberkarirteam?iduser=" + localStorage.getItem("id")
      );
      setMemberteam(response.data);
      // console.log(response.data);
    } catch (error) {}
  };

  const sesiMain = async (kode_main) => {
    try {
      const response = await axios.get(
        urlapi + "Sesimainrankteam?kode_main=" + kode_main
      );
      setSesimain(response.data);
      // console.log(response.data);
    } catch (error) {}
  };

  const notify = () =>
    toast.warning("anda harus memilih sesi main !", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  const notify2 = () =>
    toast.warning("Sesi yang anda pilih sudah full !", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  const notify3 = () =>
    toast.warning("Anda sudah melakukan booking rank team !", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  const getLawan = async () => {
    try {
      const response = await axios.get(
        urlapi + "Lawanrankteam?id_user=" + localStorage.getItem("id")
      );
      // console.log(response.data);
      setLawan(response.data);
    } catch (error) {}
  };

  const getPoinrankteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Pointrankteam?id_user=" + localStorage.getItem("id")
      );
      // console.log(response.data);
      setPoint(response.data);
    } catch (error) {}
  };

  const cekkuotasesi = async (kode_main) => {
    try {
      const response = await axios.get(
        urlapi + "Cekkuotasesirankteam?kode_main=" + kode_main
      );
      console.log(response.data);
      setCeksesi(response.data.full);
    } catch (error) {}
  };

  const getGame = async () => {
    try {
      const response = await axios.get(urlapi + "Fungamerankteam");
      // console.log(response.data);
      setGamerankteam(response.data);
    } catch (error) {
      setGamerankteam("");
    }
  };

  const handlmenuGoal = (page) => {
    if (page == "rank") {
      setPagerank(true);
      setPageassist(false);
      setPagescore(false);
    } else if (page == "score") {
      setPagerank(false);
      setPageassist(false);
      setPagescore(true);
    } else if (page == "assist") {
      setPagerank(false);
      setPageassist(true);
      setPagescore(false);
    }
  };

  const cekGame = async () => {
    try {
      const response = await axios.get(urlapi + "Cekgamerankteam");
      // console.log(response.data);
      setCekgamrerankteam(response.data);
    } catch (error) {}
  };

  const cekJmlpemain = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Pemainrankteam?jmlpemain=true" +
          "&&id_user=" +
          localStorage.getItem("id")
      );
      setJmlpemain(response.data);
      // console.log(response.data);
    } catch (error) {}
  };

  const jmlPememainrankteam = async () => {
    try {
      const response = await axios.get(urlapi + "Jmlpemainrankteam");
      // console.log(response.data);
      setkuotapemain(response.data.jml);
    } catch (error) {}
  };

  const cekteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Datateam2?id_user=" + localStorage.getItem("id")
      );
      if (response.data.message == "false") {
        setDatateam("false");
      } else {
        setDatateam(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    jmlPememainrankteam();
    cekJmlpemain();
    cekGame();
    datagame();
    geteam();
    getmemberteam();
    sesiMain();
    getLawan();
    getPoinrankteam();
    // cekkuotasesi();
    getGame();
    datarangking();
    cekteam();
  }, []);

  return (
    <div className="card">
      <Compheader team={team.nama_team} />

      <div
        className="container"
        style={{ position: "relative", bottom: "120px" }}
      >
        <div className="card shadow" style={{ border: "none" }}>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6 col-6">
                <div className="card" id="bglistpemain">
                  <div className="card-body text-white">
                    <h5>Play {bookinguser1}</h5>
                    <label>{point == false ? <>0</> : <>{point.play}</>}</label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-6">
                <div className="card" id="bglistpemain">
                  <div className="card-body text-white">
                    <h5>Win</h5>
                    <label>{point == false ? <>0</> : <>{point.win}</>}</label>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-6 mt-2">
                <div className="card" id="bglistpemain">
                  <div className="card-body text-white">
                    <h5>Draw</h5>
                    <label>{point == false ? <>0</> : <>{point.draw}</>}</label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-6 mt-2">
                <div className="card" id="bglistpemain">
                  <div className="card-body text-white">
                    <h5>Lost</h5>
                    <label>{point == false ? <>0</> : <>{point.lost}</>}</label>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-6  mt-2">
                <div className="card" id="bglistpemain">
                  <div className="card-body text-white">
                    <h5>Rank</h5>
                    <label>
                      {rangking == false || rangking == "" ? (
                        <>0</>
                      ) : (
                        <>
                          {rangking.map((data, index) => {
                            return (
                              <div key={index}>
                                {data.kode_team == kodeteam ? (
                                  <>{index + 1}</>
                                ) : (
                                  <></>
                                )}
                              </div>
                            );
                          })}
                        </>
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-6  mt-2">
                <div className="card" id="bglistpemain">
                  <div className="card-body text-white">
                    <h5>Winrate</h5>
                    <label>
                      {point == false ? (
                        <>0</>
                      ) : (
                        <>{point.winrate.slice(0, 5)}</>
                      )}{" "}
                      %
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className="container"
          style={{ position: "relative", bottom: "100px" }}
        >
          {/* <div className="card" id="bglistpemain">
            <div className="card-body">
              <label className="fw-bold text-white">
                Hello {team.nama_team}
              </label>
              <br />
              <small className="text-white">
                Ayoo buruan daftar rank team anda sekarang juga dan dapatkan
                lawan bermain anda{" "}
              </small>
            </div>
          </div> */}

          {/* {gamerankteam != null || gamerankteam != false ? (
            <>
              {" "}
              <div className="alert alert-success shadow mt-2" role="alert">
                Slot rank team dibuka, ayooo buruan daftarkan team anda sekarang
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="alert alert-danger shadow mt-2" role="alert">
                Slot rank team ditutup, cobalah di lain hari
              </div>
            </>
          )} */}
          <div
            className={
              gamerankteam == false
                ? "alert alert-danger  mt-2 border-0"
                : "d-none"
            }
            role="alert"
          >
            <h5 className="text-center">
              <i className="fas fa-warning"></i>
            </h5>
            <div className="fw-bold text-center">
              {" "}
              Slot rank team ditutup, cobalah di lain hari
            </div>
          </div>
          <div
            className={
              gamerankteam != false
                ? "alert alert-success  mt-2 border-0"
                : "d-none"
            }
            role="alert"
          >
            <small className="fw-bold">
              Slot rank team dibuka, ayooo buruan daftarkan team anda sekarang
            </small>
          </div>
          <div className="card shadow my-2" style={{ border: "none" }}>
            <div className="card-body text-secondary">
              <Link to="/pemainteam" style={{ textDecoration: "none" }}>
                <div class="d-flex justify-content-between">
                  <small
                    className="text-secondary fw-bold"
                    style={{ fontSize: "12px" }}
                  >
                    <i className="far fa-user"></i> Lihat daftar pemain anda
                  </small>
                  <small className="text-secondary">
                    <i className="fas fa-angle-right"></i>
                  </small>
                </div>
              </Link>
            </div>
          </div>
          <div className="card mb-2 shadow" style={{ border: "none" }}>
            <div className="card-body">
              <div
                class="d-flex justify-content-between"
                onClick={() => setHandlelistbooking(!handlelistbooking)}
              >
                <small
                  className="mb-2 text-secondary fw-bold"
                  style={{ cursor: "pointer", fontSize: "12px" }}
                >
                  <i className="fas fa-futbol"></i> Data booking rank team anda
                </small>
                <small>
                  {handlelistbooking ? (
                    <>
                      {" "}
                      <i className="fas fa-angle-down text-secondary"></i>
                    </>
                  ) : (
                    <>
                      {" "}
                      <i className="fas fa-angle-right text-secondary"></i>
                    </>
                  )}
                </small>
              </div>
              {handlelistbooking ? (
                <>
                  {" "}
                  <Compbookingrankteam limit="4" />
                </>
              ) : (
                <></>
              )}
            </div>
            {/* <div class="d-flex flex-row-reverse container mb-2">
              <div>
                <small style={{ fontSize: "10px" }}>
                  <Link
                    to="/bookingrankteamanda"
                    style={{ textDecoration: "none" }}
                  >
                    Lihat booking rank team semuanya{" "}
                    <i className="fas fa-angle-right"></i>
                  </Link>
                </small>
              </div>
            </div> */}
          </div>
          <div className={gamerankteam == false ? "d-none" : "mb-3"}>
            <>
              <hr />
              <div className="">
                <small className="text-secondary fw-bold">
                  Pilih rank team yang anda inginkan
                </small>
              </div>

              <div className="row">
                {gamerankteam.map((data, index) => {
                  return (
                    <div className="col-sm-6 col-6 mb-2 mt-2" key={index}>
                      <div
                        className={
                          bordermenugame == data.kode_main
                            ? "card shadow border-primary"
                            : "card shadow"
                        }
                        onClick={() => hadlepilihgame(data.kode_main)}
                        style={{}}
                        id="bglistwinrate"
                      >
                        <div className="card-body">
                          <label class="fw-bold" style={{ color: "#2b2e5a" }}>
                            Rank team {index + 1}
                          </label>
                          <br />

                          <small style={{ color: "#2b2e5a" }}>
                            {data.lapangan}
                          </small>
                          <hr />
                          <div
                            class="d-flex justify-content-between fw-bold"
                            style={{ fontSize: "7px" }}
                          >
                            <div>
                              <small>Tanggal main</small>
                              <br />
                              {data.tgl_main}
                            </div>

                            <div style={{ textAlign: "right" }}>
                              <small>Jam main</small>
                              <br />
                              {data.jam_main} WIB
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          </div>
          <div
            className={
              game == null || game == "" || game == false ? "d-none" : ""
            }
          >
            <div className="card shadow">
              <div className="card-body" style={{ fontSize: "11px" }}>
                <p className="fw-bold">Jadwal rank team</p>
                <hr />
                <div class="d-flex justify-content-between fw-bold">
                  <small>
                    Tanggal main <br />
                    {game.tgl_main}
                  </small>

                  <small style={{ textAlign: "right" }}>
                    Waktu rank team <br />
                    {game.jam_main} WIB
                  </small>
                </div>
                <hr />
                <div class="d-flex justify-content-between fw-bold">
                  <small>
                    Harga booking <br />
                    Sesi 1 : <>{game.harga} </> | Sesi 2 :{" "}
                    {game.harga2 == "" ? <>{game.harga}</> : <>{game.harga2}</>}
                  </small>

                  <small style={{ textAlign: "right" }}>
                    Lapangan <br />
                    {game.lapangan}
                  </small>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="">
            <br></br>
            <OwlCarousel
              className="owl-theme"
              loop
              margin={4}
              nav={false}
              responsive={resvonsive}
              dotsEach
              autoplay
            >
              <img
                src="/img/bennerbooking.png"
                className="img-fluid"
                alt=""
                style={{ borderRadius: "5px" }}
              ></img>
              <img
                src="/img/dp.png"
                className="img-fluid"
                alt=""
                style={{ borderRadius: "5px" }}
              ></img>
            </OwlCarousel>
          </div> */}
          <div className="row mt-3 mb-2">
            <small
              className={sesimain == false ? "d-none" : "mb-2 text-secondary"}
            >
              Pilih jam sesi rank team anda
            </small>
            <div className="container">
              <div
                className={sesimain == false ? "d-none" : "alert alert-primary"}
                role="alert"
              >
                <small className="fw-bold">
                  Jadwal rank team yang sudah di booking tidak dapat di cancel
                  dan diubah, mohon perhatikan waktu dan tanggal saat booking
                  rank team
                </small>
              </div>
            </div>

            {sesimain.map((ss, index) => {
              return (
                <div
                  className={
                    game.jml_team == 2 && ss.sesi == 2
                      ? "d-none"
                      : "col-sm-6 col-6"
                  }
                  key={index}
                >
                  {ss.sesi == ceksesi || ceksesi == "all" ? (
                    <>
                      <div
                        className={
                          sesi == ss.sesi ? "card border-primary" : "card"
                        }
                        id="bglistwinrate"
                        style={{ cursor: "pointer" }}
                        onClick={() => notify2()}
                      >
                        <div className="card-body">
                          <small className="fw-bold">Sesi {ss.sesi}</small>
                          <br />
                          <small
                            className="text-secondary"
                            style={{ fontSize: "10px" }}
                          >
                            {ceksesi == ss.sesi || ceksesi == "all" ? (
                              <>
                                <i className="fas fa-users"></i> Full
                              </>
                            ) : (
                              <>
                                <i className="far fa-clock"></i> {ss.jam_main}{" "}
                                WIB
                              </>
                            )}
                          </small>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {ss.sesi == bookinguser1 || ss.sesi == bookinguser2 ? (
                        <>
                          {" "}
                          <div
                            className={
                              sesi == ss.sesi ? "card border-primary" : "card"
                            }
                            id="bglistwinrate"
                            style={{ cursor: "pointer" }}
                            onClick={() => notify3()}
                          >
                            <div className="card-body">
                              <small className="fw-bold">Sesi {ss.sesi}</small>
                              <br />
                              <small
                                className="text-secondary"
                                style={{ fontSize: "10px" }}
                              >
                                Booked
                              </small>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div
                            className={
                              sesi == ss.sesi ? "card border-primary" : "card"
                            }
                            id="bglistwinrate"
                            style={{ cursor: "pointer" }}
                            onClick={() => handlesesi(ss.sesi, ss.kode_main)}
                          >
                            <div className="card-body">
                              <small className="fw-bold">Sesi {ss.sesi}</small>
                              <br />
                              <small
                                className="text-secondary"
                                style={{ fontSize: "10px" }}
                              >
                                {ceksesi == ss.sesi || ceksesi == "all" ? (
                                  <>
                                    <i className="fas fa-users"></i> Full
                                  </>
                                ) : (
                                  <>
                                    <i className="far fa-clock"></i>{" "}
                                    {ss.jam_main} WIB
                                  </>
                                )}
                              </small>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </div>
          {/* <Compsesi  /> */}

          <div>
            {sesi == "" ? (
              <>
                {gamerankteam == false ? (
                  <>
                    <img
                      src="./img/bennerbaru.png"
                      className="img-fluid"
                      style={{ borderRadius: "10px" }}
                    ></img>
                  </>
                ) : (
                  <>
                    {" "}
                    <Button
                      variant="btn w-100 rounded-pill mt-4"
                      onClick={() => notify()}
                      style={{ backgroundColor: "#2b2e5a", color: "white" }}
                    >
                      Bergabung sekarang
                    </Button>{" "}
                  </>
                )}

                <ToastContainer />
              </>
            ) : (
              <>
                <br />
                {/* <Comppembayaranrankteam
                  harga={harga}
                  kode_main={game.kode_main}
                  kode_team={team.kode}
                  sesi={sesi}
                /> */}

                {datateam == "false" ? (
                  <>
                    <div
                      class="alert alert-primary border-0 text-center"
                      role="alert"
                    >
                      <h5 className="text-center fw-bold">Opps!!!</h5>
                      <small className="fw-bold text-cnter">
                        Anda belum mempunyai team silahkan buat team anda untuk
                        bermain rank team
                      </small>
                      <br />
                      <Link
                        to="/daftarteamrankteam"
                        className="mt-2"
                        style={{ textDecoration: "none" }}
                      >
                        Daftarkan team anda sekarang
                      </Link>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <Link
                      to={
                        "/checkoutrankteam/" +
                        game.kode_main +
                        "/" +
                        team.kode +
                        "/" +
                        sesi
                      }
                      className="btn btn-primary rounded-pill w-100 mt-4"
                      style={{ backgroundColor: "#2b2e5a", color: "white" }}
                    >
                      Bergabung sekarang
                    </Link>{" "}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
