import React, { useEffect, useState } from "react";
import axios from "axios";

export default function Compmatch({ iduser }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [match, setMatc] = useState([]);

  const getData = async () => {
    try {
      const response = await axios.get(
        urlapi + "Mainmemberkarir?id_user=" + iduser
      );
      setMatc(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="card mt-2">
        <div className="card-body">
          <table class="table table-striped" style={{ fontSize: "12px" }}>
            <thead>
              <tr className="text-center">
                <th scope="col">#</th>
                <th scope="col">Team</th>
                <th scope="col">Match</th>
              </tr>
            </thead>
            <tbody>
              {match.map((data, index) => {
                return (
                  <tr key={index} className="text-center">
                    <th scope="row">{index + 1}</th>
                    <td>{data.team == "Putih" ? "Hijau" : data.team}</td>
                    <td>
                      {data.team == "Putih" ? "Hijau" : data.team}{" "}
                      <label className="fw-bold">
                        {data.score_team} VS {data.score_lawan}{" "}
                      </label>{" "}
                      {data.team_lawan == "Putih" ? "Hijau" : data.team_lawan}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
