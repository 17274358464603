import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { imageDb } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { Container, Form } from "react-bootstrap";

export default function Comppelunasan({
  kodebooking,
  lapangan,
  tglbooking,
  jambooking,
  status,
}) {
  const urlapi = process.env.REACT_APP_BASE_URL;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [booking, setBooking] = useState([]);

  const [norek, setNorek] = useState("");
  const [atasnama, setAtasnam] = useState("");
  const [nameImg, setNameImg] = useState("");
  const [valueimg, setvalueimg] = useState("");
  const [img, setImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(true);
  const [alertwa, setAlertwa] = useState(false);
  const [pembayaran, setPembayaran] = useState(true);
  const [detailbooking, setDetailbooking] = useState(false);
  const [pelunasan, setPelunasan] = useState([]);

  const handledetailbooking = (page) => {
    if (page == "pembayaran") {
      setPembayaran(true);
      setDetailbooking(false);
    } else {
      setPembayaran(false);
      setDetailbooking(true);
    }
  };

  const formatrupiah = (numb) => {
    const format = numb.toString().split("").reverse().join("");
    const convert = format.match(/\d{1,3}/g);
    const rupiah = "Rp " + convert.join(".").split("").reverse().join("");
    return rupiah;
  };

  const handleChangeImg = (e) => {
    setImg(e.target.files[0]);
    // console.log(e);
    setvalueimg(e.target.value);
    setNameImg(e.target.files[0].name);
  };

  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi + "Datapembayaranbooking?kode=" + kodebooking
      );
      setBooking(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getpelunasan = async () => {
    try {
      const response = await axios.get(
        urlapi + "Addpelunasan?kode=" + kodebooking
      );
      setPelunasan(response.data);
      // console.log(response.data);
      // console.log(response.data);
    } catch (error) {}
  };

  const handleLunas = () => {
    setLoading(true);
    const imgRef = ref(imageDb, `files/${nameImg}`);
    uploadBytes(imgRef, img);
    console.log(imgRef._location.path_);

    setTimeout(() => {
      getDownloadURL(ref(imageDb, `files/${nameImg}`)).then((url) => {
        console.log("prosess");
        setForm(false);
        addPelunasan(url);
      });
    }, 15000);
  };

  const addPelunasan = async (url) => {
    const response = await axios
      .post(urlapi + "Addpelunasan", {
        id_user: localStorage.getItem("id"),
        kode_booking: kodebooking,
        sisa_pembayaran: booking.pembayaran,
        atas_nama: atasnama,
        bank: norek,
        bukti: url,
      })
      .then((response) => {
        setLoading(false);
        console.log(response.data);
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  useEffect(() => {
    getdata();
    getpelunasan();
  }, []);

  return (
    <div>
      {booking.status_pembayaran == "Dp" || booking.status_pembayaran == "" ? (
        <>
          <div className={pelunasan.status == 201 ? "" : "d-none"}>
            <div className="alert alert-primary mt-2" role="alert">
              <small>
                Pelunasan berhasil dikirim, mohon untuk menunggu persetujuan
              </small>
            </div>
          </div>

          <div className={pelunasan.status == 200 ? "" : "d-none"}>
            <div className="alert alert-success mt-2" role="alert">
              <small>
                Hello {localStorage.getItem("nama")}, Pelunasan anda berhasil
                disetujui
              </small>
            </div>
          </div>

          <div className={pelunasan.status == 400 ? "" : "d-none"}>
            <div className="alert alert-danger mt-2" role="alert">
              <small>
                Hello {localStorage.getItem("nama")}, Pelunasan anda ditolak,
                silahkan melakukan pelunasan kembali
              </small>
            </div>
          </div>
          <Button
            variant={
              pelunasan.status == 200 || booking.status == 201
                ? "btn btn-sm w-100 rounded-pill mt-1 d-none"
                : "btn btn-sm w-100 rounded-pill mt-1"
            }
            onClick={handleShow}
            style={{ backgroundColor: "#2b2e5a", color: "white" }}
          >
            Mohon untuk melakukan pelunasan
          </Button>
          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="bottom"
            style={{ height: "500px" }}
          >
            <Offcanvas.Header closeButton>
              <Container>
                <div className="d-flex justify-content-between">
                  <Offcanvas.Title
                    onClick={() => handledetailbooking("pembayaran")}
                    className={pembayaran ? "text-primary" : ""}
                  >
                    Pelunasan
                  </Offcanvas.Title>
                  <Offcanvas.Title
                    className={detailbooking ? "text-primary" : ""}
                    onClick={() => handledetailbooking("detail")}
                  >
                    {" "}
                    Detail Booking
                  </Offcanvas.Title>
                </div>
              </Container>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Container>
                {loading ? (
                  <div className="text-center" style={{ marginTop: "150px" }}>
                    <div class="spinner-grow text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-secondary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-success" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <br></br>
                    <label>Loading....</label>
                    <p className="text-center">
                      Mohon untuk menunggu proses pembayaran
                    </p>
                  </div>
                ) : (
                  <div>
                    {form ? (
                      <>
                        {booking.total_harga == "" ? (
                          <>
                            <div className={detailbooking ? "" : "d-none"}>
                              <div className="d-flex justify-content-between">
                                <div>
                                  <label className="fw-bold">Lapangan</label>
                                  <p>{lapangan}</p>
                                </div>
                                <div>
                                  <label className="fw-bold">Jam Main</label>
                                  <p>{jambooking}</p>
                                </div>
                              </div>
                              <hr />
                              <div className="d-flex justify-content-between">
                                <div>
                                  <label className="fw-bold">Tgl Booking</label>
                                  <p>{tglbooking}</p>
                                </div>
                                <div>
                                  <label className="fw-bold">Harga</label>
                                  <p>{formatrupiah(booking.harga)}</p>
                                </div>
                              </div>
                              <hr />
                              <div className="d-flex justify-content-between">
                                <div>
                                  <label className="fw-bold">
                                    Sistem Pembayaran
                                  </label>
                                  <p>DP 50%</p>
                                </div>
                                <div>
                                  <label className="fw-bold">Transfer</label>
                                  <p>{formatrupiah(booking.harga / 2)}</p>
                                </div>
                              </div>
                              <hr />
                              <div className="d-flex justify-content-between">
                                <div>
                                  <label className="fw-bold">
                                    Sisa Pembayaran
                                  </label>
                                  <p>{formatrupiah(booking.harga / 2)}</p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className={detailbooking ? "" : "d-none"}>
                              <div className="d-flex justify-content-between">
                                <div>
                                  <label className="fw-bold">Lapangan</label>
                                  <p>{lapangan}</p>
                                </div>
                                <div>
                                  <label className="fw-bold">Jam Main</label>
                                  <p>{jambooking}</p>
                                </div>
                              </div>
                              <hr />
                              <div className="d-flex justify-content-between">
                                <div>
                                  <label className="fw-bold">Tgl Booking</label>
                                  <p>{tglbooking}</p>
                                </div>
                                <div>
                                  <label className="fw-bold">Harga</label>
                                  <p>{formatrupiah(booking.total_harga)}</p>
                                </div>
                              </div>
                              <hr />
                              <div className="d-flex justify-content-between">
                                <div>
                                  <label className="fw-bold">
                                    Sistem Pembayaran
                                  </label>
                                  <p>DP 50%</p>
                                </div>
                                <div>
                                  <label className="fw-bold">Nominal</label>
                                  <p>{formatrupiah(booking.pembayaran)}</p>
                                </div>
                              </div>
                              <hr />
                              <div className="d-flex justify-content-between">
                                <div>
                                  <label className="fw-bold">
                                    Sisa Pembayaran
                                  </label>
                                  <p>{formatrupiah(booking.pembayaran)}</p>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        <div className={pembayaran ? "" : "d-none"}>
                          {booking.total_harga == "" ? (
                            <>
                              <div class="alert alert-primary" role="alert">
                                Sisa pembayaran booking {lapangan} sebesar{" "}
                                <strong>
                                  {" "}
                                  {formatrupiah(booking.harga / 2)}
                                </strong>
                              </div>
                            </>
                          ) : (
                            <>
                              {" "}
                              <div class="alert alert-primary" role="alert">
                                Sisa pembayaran booking {lapangan} sebesar{" "}
                                <strong>
                                  {" "}
                                  {formatrupiah(booking.pembayaran)}
                                </strong>
                              </div>
                            </>
                          )}

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            {/* <Form.Control
                        type="text"
                        onChange={(e) => setNorek(e.target.value)}
                        placeholder="n"
                      /> */}
                            <select
                              className="form-control"
                              required
                              onChange={(e) => setNorek(e.target.value)}
                            >
                              <option value="">
                                -- Pilih Nama Bank Anda --
                              </option>
                              <option>BRI</option>
                              <option>BNI</option>
                              <option>BCA</option>
                              <option>Mandiri</option>
                              <option>Bank Sumut</option>
                              <option>Danamon</option>
                              <option>CIMB Niaga</option>
                              <option>Lainya</option>
                            </select>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Control
                              type="text"
                              onChange={(e) => setAtasnam(e.target.value)}
                              placeholder="Atas nama"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Control
                              type="file"
                              onChange={(e) => handleChangeImg(e)}
                            />
                            <Form.Text className="text-muted">
                              Masukan bukti pembayaran anda dengan benar dan
                              jelas dengan format Gambar (JPG, JPEG dan PNG)
                            </Form.Text>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <textarea
                              className="form-control"
                              placeholder="Keterangan"
                            ></textarea>
                            <Form.Text className="text-muted"></Form.Text>
                          </Form.Group>
                          <button
                            className="btn w-100 rounded-pill"
                            onClick={() => handleLunas()}
                            disabled={
                              norek == "" || atasnama == "" || valueimg == ""
                                ? true
                                : false
                            }
                            style={{
                              backgroundColor: "#2b2e5a",
                              color: "white",
                            }}
                          >
                            Bayar sekarang
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <center>
                          {alertwa ? (
                            <div>
                              <img
                                src="/close.png"
                                className="img-fluid"
                                alt=""
                                style={{ height: "100px" }}
                              ></img>
                              <h5 className="mt-4 text-secondary">
                                <strong>
                                  Hei, {localStorage.getItem("nama")}{" "}
                                </strong>
                                <br></br>
                                Pembayaran anda gagal, mohon untuk mencoba lagi
                                <center>
                                  <a
                                    href={"/booking/data"}
                                    className="btn mt-5"
                                    style={{
                                      backgroundColor: "#2b2e5a",
                                      color: "white",
                                    }}
                                  >
                                    Coba lagi
                                  </a>
                                </center>
                              </h5>
                            </div>
                          ) : (
                            <>
                              <img
                                src="/sukses.png"
                                className="img-fluid"
                                alt=""
                                style={{ height: "100px" }}
                              ></img>
                              <h5 className="mt-4 text-secondary">
                                <strong>
                                  Hei, {localStorage.getItem("nama")}{" "}
                                </strong>
                                <br></br>
                                Pembayaran anda berhasil dikirim silahkan
                                menunggu persetujuan pembayaran anda
                              </h5>
                              <a
                                href={"/booking/data"}
                                className="btn mt-5"
                                style={{
                                  backgroundColor: "#2b2e5a",
                                  color: "white",
                                }}
                              >
                                Tutup popup
                              </a>
                            </>
                          )}
                        </center>
                      </>
                    )}
                  </div>
                )}
              </Container>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
