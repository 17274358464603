import axios from "axios";
import React, { useEffect, useState } from "react";
import Compusers from "../History/Compusers";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";

export default function Comphasilranksolo({ kode_main }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [hasil, setHasil] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi + "Hasilranksolo?kode" + kode_main
      );
      setHasil(response.data);
      //   console.log(response.data);
    } catch (error) { }
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <div>
      <div className="card" id="menurank">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-5 col-5">
              <center>
                <Link
                  to={"/pemainmemberkarir/" + kode_main + "/Hitam/Putih/all"}
                >
                  <img
                    src="/img/hitam.png"
                    className="img-fluid"
                    alt=""
                    style={{ borderRadius: "5px", height: "80px" }}
                  ></img>
                  <Compusers />
                </Link>
              </center>
            </div>
            <div className="col-sm-2 col-2">
              <Link to={"/pemainmemberkarir/" + kode_main + "/Hitam/Putih/all"}>
                <center>
                  <img
                    src="./img/logomms.png"
                    className="img-fluid mt-4"
                    style={{ height: "30px" }}
                  ></img>
                </center>
              </Link>
            </div>
            <div className="col-sm-5 col-5">
              <center>
                <Link
                  to={"/pemainmemberkarir/" + kode_main + "/Putih/Hitam/all"}
                >
                  <img
                    src="/img/putih.png"
                    className="img-fluid"
                    alt=""
                    style={{ borderRadius: "5px", height: "80px" }}
                  ></img>
                  <Compusers />
                </Link>
              </center>
            </div>
          </div>
        </div>
      </div>

      <div className="card mt-3" id="menurank">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-5 col-5">
              <center>
                <Link
                  to={"/pemainmemberkarir/" + kode_main + "/Merah/Kuning/all"}
                >
                  <img
                    src="/img/merah.png"
                    className="img-fluid"
                    alt=""
                    style={{ borderRadius: "5px", height: "80px" }}
                  ></img>
                  <Compusers />
                </Link>
              </center>
            </div>
            <div className="col-sm-2 col-2">
              <Link
                to={"/pemainmemberkarir/" + kode_main + "/Merah/Kuning/all"}
              >
                <center>
                  <img
                    src="./img/logomms.png"
                    className="img-fluid mt-4"
                    style={{ height: "30px" }}
                  ></img>
                </center>
              </Link>
            </div>
            <div className="col-sm-5 col-5">
              <center>
                <Link
                  to={"/pemainmemberkarir/" + kode_main + "/Kuning/Merah/all"}
                >
                  <img
                    src="/img/kuning.png"
                    className="img-fluid"
                    alt=""
                    style={{ borderRadius: "5px", height: "80px" }}
                  ></img>
                </Link>
                <Compusers />
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
