import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Comphasilpertandingan({ kode }) {
  const [hasil2, setHasil2] = useState([]);
  const [imgteam, setImgteam] = useState("");
  const [imglawan, setImglawan] = useState("");
  const [datagoal, setDatagoal] = useState([]);
  const [dataasist, setDataasist] = useState([]);
  const urlapi = process.env.REACT_APP_BASE_URL;

  const getData = async () => {
    try {
      const response = await axios.get(
        urlapi + "Hasilpertandingan2?kode=" + kode
      );
      //   setHasil2(response.data);
      console.log(response.data);
      setHasil2(response.data.data);
      setImgteam(response.data.imgteam);
      setImglawan(response.data.imglawan);
    } catch (error) {}
  };

  const dataGoal = async () => {
    try {
      const response = await axios.get(urlapi + "Datagoal?kode=" + kode);
      setDatagoal(response.data);
    } catch (error) {}
  };

  const dataAsist = async () => {
    try {
      const response = await axios.get(urlapi + "Dataasist?kode=" + kode);
      setDataasist(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getData();
    dataGoal();
    dataAsist();
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-sm-4 col-4">
          <div className="my-3">
            <center>
              <img
                src={"https://ratumerak.id/adminmmsc/assets/berkas/" + imgteam}
                class="img-fluid "
                alt="Responsive image"
                style={{
                  height: "65px",
                  width: "65px",
                  borderRadius: "100%",
                  border: "3px solid yellow",
                }}
              ></img>{" "}
              <br />
              <small className="fw-bold">{hasil2.team}</small>
            </center>
            <br />
          </div>
        </div>
        <div className="col-sm-4 col-4">
          <center>
            <div
              className="card bg-primary mt-4"
              style={{ width: "80px", height: "40px" }}
            >
              <h5 className="text-primary my-2 mx-1 text-white">
                {" "}
                <strong>
                  {hasil2.score_team} : {hasil2.score_lawan}
                </strong>
              </h5>
            </div>
            <small className="fw-bold text-white" style={{ fontSize: "8px" }}>
              {" "}
              <i className="fas fa-calendar-day"></i> {hasil2.tgl_main}
            </small>
          </center>
        </div>
        <div className="col-sm-4 col-4">
          <div className="my-3">
            <center>
              <img
                src={"https://ratumerak.id/adminmmsc/assets/berkas/" + imglawan}
                class="img-fluid "
                alt="Responsive image"
                style={{
                  height: "65px",
                  width: "65px",
                  borderRadius: "100%",
                  border: "3px solid yellow",
                }}
              ></img>{" "}
              <br />
              <small className="fw-bold">{hasil2.team_lawan}</small>
            </center>
            <br />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-6">
          <div class="d-flex justify-content-start">
            <div style={{ fontSize: "7px", margin: "2px" }}>
              <p className="fw-bold text-white" style={{ lineHeight: "5px" }}>
                Goal
              </p>
              <div class="d-flex justify-content-end">
                <div>
                  {datagoal.map((gg, index) => {
                    return (
                      <div key={index}>
                        <label
                          className={
                            gg.team == hasil2.team_lawan ? "d-none" : "fw-bold"
                          }
                          style={{
                            fontSize: "7px",
                            marginRight: "5px",
                          }}
                        >
                          <i className="fas fa-futbol"></i> {gg.nama_pemain}{" "}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <div>
                  {datagoal.map((gg, index) => {
                    return (
                      <div key={index}>
                        {dataasist.map((ast, index) => {
                          return (
                            <label
                              key={index}
                              className={
                                gg.team == hasil2.team_lawan
                                  ? "d-none"
                                  : " text-warning"
                              }
                              style={{
                                fontSize: "7px",
                              }}
                            >
                              <div
                                className={
                                  ast.menit == gg.menit ? "" : "d-none"
                                }
                              >
                                {gg.menit == "" ? (
                                  <></>
                                ) : (
                                  <> {ast.nama_pemain} </>
                                )}
                              </div>
                            </label>
                          );
                        })}

                        <label
                          key={index}
                          className={
                            gg.team == hasil2.team_lawan
                              ? "d-none"
                              : " text-warning"
                          }
                          style={{
                            fontSize: "7px",
                          }}
                        >
                          {gg.menit == "" || gg.menit == null ? (
                            ""
                          ) : (
                            <>
                              {" "}
                              {"  "} ({gg.menit})
                            </>
                          )}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-6">
          <div class="d-flex justify-content-end">
            <div
              className="text-end"
              style={{ fontSize: "7px", margin: "2px" }}
            >
              <p className="fw-bold text-white" style={{ lineHeight: "5px" }}>
                Goal
              </p>
              <div class="d-flex justify-content-end">
                <div>
                  {datagoal.map((gg, index) => {
                    return (
                      <div key={index}>
                        <label
                          className={
                            gg.team == hasil2.team ? "d-none" : "fw-bold"
                          }
                          style={{
                            fontSize: "7px",
                            marginRight: "5px",
                          }}
                        >
                          <i className="fas fa-futbol"></i> {gg.nama_pemain}{" "}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <div>
                  {datagoal.map((gg, index) => {
                    return (
                      <div key={index}>
                        {dataasist.map((ast, index) => {
                          return (
                            <label
                              key={index}
                              className={
                                gg.team == hasil2.team
                                  ? "d-none"
                                  : " text-warning"
                              }
                              style={{
                                fontSize: "7px",
                              }}
                            >
                              <div
                                className={
                                  ast.menit == gg.menit ? "" : "d-none"
                                }
                              >
                                {gg.menit == "" ? (
                                  <></>
                                ) : (
                                  <> {ast.nama_pemain} </>
                                )}
                              </div>
                            </label>
                          );
                        })}

                        <label
                          key={index}
                          className={
                            gg.team == hasil2.team ? "d-none" : " text-warning"
                          }
                          style={{
                            fontSize: "7px",
                          }}
                        >
                          {gg.menit == "" || gg.menit == null ? (
                            ""
                          ) : (
                            <>
                              {" "}
                              {"  "} ({gg.menit})
                            </>
                          )}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* <div
              className="text-end"
              style={{ fontSize: "9px", margin: "2px" }}
            >
              <p>Assist</p>
              
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
