import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

export default function Comptambahpemain() {
  const urlapi = process.env.REACT_APP_BASE_URL;

  const [nama, setNama] = useState("");
  const [nickname, setNickname] = useState("");
  const [posisi, setPosisi] = useState("");
  const [nopunggung, setNopunggung] = useState("");
  const [jmlpemain, setJmlpemain] = useState(0);
  const [kuotapemain, setKuotapemain] = useState(0);
  const [nik, setNik] = useState("");
  const [notifnik, setNotifnik] = useState(false);
  const [panjangnik, setPanjangnik] = useState("");
  const [statusteam, setStatusteam] = useState(0);

  const notify = () =>
    toast.success("Data berhasil ditambah !", {
      position: toast.POSITION.TOP_CENTER,
    });

  const notify2 = () =>
    toast.warning("Nik yang anda masukan sudah terdaftar !", {
      position: toast.POSITION.TOP_CENTER,
    });

  const notify3 = () =>
    toast.error("Mohon maaf kuota tambah pemain sudah penuh!", {
      position: toast.POSITION.TOP_CENTER,
    });

  const notify4 = () =>
    toast.error("Mohon maaf anda tidak dapat menambah pemain!", {
      position: toast.POSITION.TOP_CENTER,
    });

  const addPemain = async () => {
    await axios
      .post(urlapi + "Pemainrankteam", {
        id_user: localStorage.getItem("id"),
        nama_pemain: nama,
        posisi: posisi,
        no_punggung: nopunggung,
        nik: nik,
        nickname: nickname,
      })
      .then((response) => {
        if (response.data.kuota == "full") {
          notify3();
        } else {
          notify();
        }

        jmlPememainrankteam();
        cekJmlpemain();
        setNama("");
        setPosisi("");
        setNopunggung("");
        setNik("");
        setNickname("");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const cekJmlpemain = async () => {
    try {
      const response = await axios.get(
        urlapi +
        "Pemainrankteam?jmlpemain=true" +
        "&&id_user=" +
        localStorage.getItem("id")
      );
      setJmlpemain(response.data);
      // console.log(response.data);
    } catch (error) { }
  };

  const jmlPememainrankteam = async () => {
    try {
      const response = await axios.get(urlapi + "Jmlpemainrankteam");
      // console.log(response.data);
      setKuotapemain(response.data.jml);
    } catch (error) { }
  };

  const handlenik = (e) => {
    setNik(e);
    ceknik(e);

    if (e.length == 16) {
      setPanjangnik("true");
    } else if (e.length > 16) {
      setPanjangnik("false1");
    } else if (e.length < 16) {
      setPanjangnik("false2");
    }
    // console.log(e);
  };

  const ceknik = async (nik) => {
    try {
      const response = await axios.get(urlapi + "Ceknik?nik=" + nik);
      // console.log(response.data);
      if (response.data == "") {
        setNotifnik(false);
      } else {
        setNotifnik(true);
      }
    } catch (error) {
      setNotifnik(false);
    }
  };

  const cekstatusteam = async () => {
    try {
      const response = await axios.get(urlapi + "Cekstatusteam?id=" + localStorage.getItem('id'));
      setStatusteam(response.data.status)
      // console.log(response.data);
    } catch (error) {

    }
  }

  useEffect(() => {
    jmlPememainrankteam();
    cekJmlpemain();
    cekstatusteam()
  }, []);

  return (
    <div>
      <div className="my-5">
        <div className="container">
          <br></br>
          <div className="card border-0" id="bggradient1">
            <div className="card-body text-white">
              <h5 className="fw-bold">
                <i className="fas fa-user-plus"></i> Tambah pemain team anda
              </h5>
              <small>
                Masukan data pemain team anda dengan benar sesuai data diri
              </small>
            </div>
          </div>
          <div>
            <div className="alert alert-info mt-2 border-0 fw-bold">
              <small className="fw-bold">
                {" "}

                <i className="fas fa-circle-info"></i> Team yang sudah disetujui oleh admin
                tidak dapat mengubah nama pemain, nik
                pemain dan posisi pemain
              </small>
            </div>

            {statusteam == 0 ? <>
              <div className="alert alert-warning text-center">
                <h5><i className="fas fa-warning"></i></h5>
                <small className="fw-bold">Team anda belum dapat bermain rank team, silahkan menunggu persetujuan dari adamin</small>
              </div>
            </> : <div className="alert alert-success text-center">
              <h5><i className="fas fa-circle-check"></i></h5>
              <small className="fw-bold">Data team anda sudah disetujui, anda dapat melakukan bermain rank team sekarang</small>
            </div>}


          </div>
          <div className="card shadow mt-3" style={{ border: "none" }}>
            <div className="card-body">
              <small className="text-secondary">
                {" "}
                <i className="fas fa-user-plus"></i> Tambah pemain anda
              </small>
              <hr />

              <div className="form-group">
                <label className="mb-1 fw-bold">Nama</label>
                <input
                  className="form-control"
                  onChange={(e) => setNama(e.target.value)}
                  name="nama"
                  value={nama}
                />
              </div>
              {/* 
              <div className="form-group mt-3">
                <label className="mb-1 fw-bold">Nickname</label>
                <input
                  className="form-control"
                  onChange={(e) => setNickname(e.target.value)}
                  name="nickname"
                  value={nickname}
                />
              </div> */}
              {/* <div className="form-group mt-3">
                <label className="mb-1 fw-bold">No Punggung</label>
                <input
                  className="form-control"
                  type="number"
                  name="no_punggung"
                  onChange={(e) => setNopunggung(e.target.value)}
                  value={nopunggung}
                />
              </div> */}
              <div className="form-group mt-3">
                <label className="mb-1 fw-bold">Posisi</label>
                <select
                  className="form-control"
                  name="posisi"
                  onChange={(e) => setPosisi(e.target.value)}
                >
                  <option value="">-- Pilih posisi --</option>
                  <option>Penyerang</option>
                  <option>Gelandang serang</option>
                  <option>Gelandang sayap</option>
                  <option>Gelandang tengah</option>
                  <option>Bek sayap</option>
                  <option>Bek tangah</option>
                  <option>Penjaga gawang</option>
                </select>
              </div>

              <div className="form-group mt-3">
                <label className="mb-1 fw-bold">NIK</label>
                <input
                  className="form-control"
                  type="number"
                  name="nik"
                  onChange={(e) => handlenik(e.target.value)}
                  value={nik}
                />
                {notifnik == true ? (
                  <>
                    <small className="text-danger">
                      Nik yang anda masukan sudah terdaftar
                    </small>
                  </>
                ) : (
                  ""
                )}
                <br />
                {panjangnik == "true" ? (
                  <>
                    <small className="text-success">
                      Panjang nik sudah benar
                    </small>
                  </>
                ) : panjangnik == "false1" ? (
                  <>
                    <small className="text-danger">Nik terlalu panjang</small>
                  </>
                ) : panjangnik == "false2" ? (
                  <>
                    <small className="text-danger">Nik terlalu pendek</small>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex justify-content-between">
                {nama == "" ||
                  posisi == "" ||
                  nik == "" ||
                  notifnik == true ||
                  panjangnik == "false1" ||
                  panjangnik == "false2" ? (
                  <>
                    {" "}
                    <button
                      className="btn btn-primary mt-4 w-100 disabled rounded-pill"
                      style={{
                        marginRight: "10px",
                        backgroundColor: "#2b2e5a",
                      }}
                      onClick={() => addPemain()}
                    >
                      <i className="fas fa-plus"></i> Tambah
                    </button>
                  </>
                ) : (
                  <>
                    {" "}

                    {statusteam == 1 ? <>
                      <button
                        className="btn btn-primary mt-4 w-100 rounded-pill"
                        style={{
                          marginRight: "10px",
                          backgroundColor: "#2b2e5a",
                        }}
                        onClick={() => notify4()}
                      >
                        <i className="fas fa-plus"></i> Tambah
                      </button>
                    </> : <>

                      <button
                        className="btn btn-primary mt-4 w-100 rounded-pill"
                        style={{
                          marginRight: "10px",
                          backgroundColor: "#2b2e5a",
                        }}
                        onClick={() => addPemain()}
                      >
                        <i className="fas fa-plus"></i> Tambah
                      </button>

                    </>}

                  </>
                )}

                <Link
                  to="/pemainteam"
                  className="btn btn-primary mt-4 w-100 rounded-pill"
                  style={{ backgroundColor: "#2b2e5a" }}
                >
                  Kembali <i className="fas fa-angle-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}
