import axios from "axios";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";

export default function Ubahpassword() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [page, setPage] = useState(false);
  const [passlama, setPasslama] = useState("");
  const [passbaru, setPassbaru] = useState("");
  const [passbaru2, setPassbaru2] = useState("");
  const [pass, setPass] = useState("");

  const cekPassword = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Cekpass?passlama=" +
          passlama +
          "&&email=" +
          localStorage.getItem("email")
      );
      //   console.log(response.data);
      setPass(response.data.message);
    } catch (error) {}
  };

  const notifupdate = () => {
    toast.success("Password anda berhasil di update !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const notifupdate2 = () => {
    toast.error("Password anda gagal di update !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const Actionupdate = async () => {
    await axios
      .post(urlapi + "Cekpass", {
        id: localStorage.getItem("id"),
        passlama: passlama,
        passbaru: passbaru,
        passbaru2: passbaru2,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data == true) {
          notifupdate();
          setPassbaru("");
          setPassbaru2("");
          setPasslama("");
          setPass("");
        } else {
          notifupdate2();
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <div>
      <div className="card mt-2">
        <div className="card-body">
          <div
            className="d-flex justify-content-between"
            onClick={() => setPage(!page)}
          >
            <p className="fw-bold text-primary">Ubah password</p>
            <p className="fw-bold text-primary">
              {page == true ? (
                <>
                  {" "}
                  <i className="fas fa-angle-down"></i>
                </>
              ) : (
                <>
                  {" "}
                  <i className="fas fa-angle-right"></i>
                </>
              )}
            </p>
          </div>

          {page == true ? (
            <>
              <hr />
              <div className="form-group">
                <label className="fw-bold">Password lama</label>
                <input
                  className="form-control mt-2 text-secondary"
                  onChange={(e) => setPasslama(e.target.value)}
                  value={passlama}
                  onKeyUp={() => cekPassword()}
                  placeholder="Masukan password lama anda"
                />
                {pass == "salah" && passlama == "" ? (
                  <> </>
                ) : pass == "salah" ? (
                  <>
                    {" "}
                    <small className="text-danger">Password masih salah</small>
                  </>
                ) : pass == "benar" ? (
                  <>
                    <small className="text-success">Password sudah benar</small>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="form-group mt-3">
                <label className="fw-bold">Password baru</label>
                <input
                  className="form-control mt-2 text-secondary"
                  onChange={(e) => setPassbaru(e.target.value)}
                  value={passbaru}
                  placeholder="Masukan password baru"
                />
              </div>

              <div className="form-group mt-3">
                <label className="fw-bold">Ulangi password baru</label>
                <input
                  className="form-control mt-2 text-secondary"
                  onChange={(e) => setPassbaru2(e.target.value)}
                  value={passbaru2}
                  placeholder="Ulangi password baru"
                />
                {passbaru2 == "" ? (
                  <></>
                ) : passbaru == passbaru2 ? (
                  <>
                    <small className="text-success">Password sudah sama</small>
                  </>
                ) : (
                  <>
                    <small className="text-danger">Password belum sama</small>
                  </>
                )}
              </div>

              {pass == "benar" &&
              passlama !== "" &&
              passbaru !== "" &&
              passbaru !== "" &&
              passbaru == passbaru2 ? (
                <>
                  <button
                    className="btn w-100 mt-3 mb-3 rounded-pill"
                    onClick={() => Actionupdate()}
                    style={{ backgroundColor: "#2b2e5a", color: "white" }}
                  >
                    Updata password anda
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn w-100 mt-3 mb-3 rounded-pill"
                    disabled
                    style={{ backgroundColor: "#2b2e5a", color: "white" }}
                  >
                    Updata password anda
                  </button>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
