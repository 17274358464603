import React from "react";
import Navbar from "../componenst/Navbar";
import Compmainrankteam from "../componenst/profilteam/Compmainrankteam";

export default function Profilteam() {
  return (
    <div>
      <Navbar judul="Profil team" aicon="true" />
      <br />
      <Compmainrankteam />
    </div>
  );
}
