import React from "react";
import { useState, useEffect } from "react";
import { Button, Container, Form } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { imageDb } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import axios from "axios";

export default function ({
  harga,
  total_harga,
  kode_main,
  kode_team,
  sesi,
  foto,
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const urlapi = process.env.REACT_APP_BASE_URL;
  const [bank, setBank] = useState("");
  const [atasnama, setAtasnama] = useState("");
  const [nameImg, setNameImg] = useState("");
  const [valueimg, setvalueimg] = useState("");
  const [img, setImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(true);
  const [alertwa, setAlertwa] = useState(false);
  const [pagepenuh, setPagepenuh] = useState(false);
  const [team, setTeam] = useState([]);

  const handleChangeImg = (e) => {
    setImg(e.target.files[0]);

    setvalueimg(e.target.value);
    setNameImg(e.target.files[0].name);
  };

  const handleBayar = () => {
    setForm(false);
    setLoading(true);
    const imgRef = ref(imageDb, `files/${nameImg}`);
    uploadBytes(imgRef, img);
    console.log(imgRef._location.path_);

    setTimeout(() => {
      getDownloadURL(ref(imageDb, `files/${nameImg}`)).then((url) => {
        console.log("prosess");
        setForm(false);
        addMemberkarirteam(url);
      });
    }, 15000);
  };

  // const handleBayar = () => {
  //   setForm(false);
  //   setLoading(true);

  //   setTimeout(() => {
  //     addMemberkarirteam("lorem");
  //   }, 500);
  // };

  const addMemberkarirteam = async (url) => {
    await axios
      .post(urlapi + "Memberkarirteam", {
        id_user: localStorage.getItem("id"),
        kode_main: kode_main,
        harga: harga,
        total_harga: total_harga,
        foto: foto,
        kode_team: kode_team,
        atas_nama: atasnama,
        sesi: sesi,
        bank: bank,
        bukti: url,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data == true) {
          setForm(false);
          setLoading(false);
          setAlertwa(true);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <>
      <Button
        variant="btn w-100 rounded-pill"
        onClick={handleShow}
        style={{ backgroundColor: "#2b2e5a", color: "white" }}
      >
        Pembayaran rank team
      </Button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="bottom"
        style={{ height: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Container>
            <Offcanvas.Title>Pembayaran rank team</Offcanvas.Title>
          </Container>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container>
            <div>
              {loading ? (
                <div className="text-center" style={{ marginTop: "150px" }}>
                  <div class="spinner-grow text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-success" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <br></br>
                  <label>Loading....</label>
                  <p className="text-center">
                    Mohon untuk menunggu proses pembayaran
                  </p>
                </div>
              ) : (
                ""
              )}

              {form == true ? (
                <>
                  <p>
                    Pembayaran rank solo hanya dapat di lakukan dengan BANK
                    TRANSFER dengan nomor rekening tujuan{" "}
                    <b>REK BCA : 6475383951 a/n Pendy Or Handoko</b>
                  </p>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control type="number" value={total_harga} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <select
                      className="form-control"
                      onChange={(e) => setBank(e.target.value)}
                      required
                    >
                      <option value="">-- Pilih Nama Bank Anda --</option>
                      <option>BRI</option>
                      <option>BNI</option>
                      <option>BCA</option>
                      <option>Mandiri</option>
                      <option>Bank Sumut</option>
                      <option>Danamon</option>
                      <option>CIMB Niaga</option>
                      <option>Lainya</option>
                    </select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      onChange={(e) => setAtasnama(e.target.value)}
                      placeholder="Atas nama"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicupload">
                    <label
                      for="images"
                      class="drop-container"
                      id="dropcontainer"
                    >
                      <input
                        type="file"
                        id="images"
                        className="form-control"
                        accept="image/*"
                        required
                        onChange={(e) => handleChangeImg(e)}
                      />
                    </label>
                    <small>
                      Upload bukti pembayaran dengan format Gambar (JPG, JPEG &
                      PNG ,,,,)
                    </small>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <textarea
                      className="form-control"
                      placeholder="Keterangan"
                    ></textarea>
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>

                  {bank == "" || atasnama == "" || nameImg == "" ? (
                    <>
                      {" "}
                      <button
                        className="btn w-100 rounded-pill"
                        disabled
                        style={{ backgroundColor: "#2b2e5a", color: "white" }}
                      >
                        Bayar sekarang
                      </button>{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <button
                        className="btn w-100 rounded-pill"
                        onClick={() => handleBayar()}
                        style={{ backgroundColor: "#2b2e5a", color: "white" }}
                      >
                        Bayar sekarang
                      </button>
                    </>
                  )}
                </>
              ) : (
                ""
              )}

              {alertwa == true ? (
                <>
                  <center>
                    <img
                      src="/sukses.png"
                      className="img-fluid"
                      alt=""
                      style={{ height: "100px" }}
                    ></img>
                    <h5 className="mt-4 text-secondary">
                      <strong>Hei, {localStorage.getItem("nama")} </strong>
                      <br></br>
                      Pembayaran anda berhasil dikirim silahkan menunggu
                      persetujuan pembayaran anda
                    </h5>
                    <a
                      href={"/memberkarirteam"}
                      className="btn mt-2"
                      style={{
                        backgroundColor: "#2b2e5a",
                        color: "white",
                      }}
                    >
                      Tutup popup
                    </a>
                  </center>
                </>
              ) : (
                ""
              )}
            </div>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
