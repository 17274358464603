import React from "react";
import Compregister from "../componenst/Register/Compregister";

export default function Register() {
  return (
    <div className="register">
      <Compregister />
    </div>
  );
}
