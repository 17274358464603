import axios from "axios";
import React, { useEffect, useState } from "react";
import Compgoal from "./Compgoal";
import Compassist from "./Compassist";
import Compjadwalrankteam from "./Compjadwalrankteam";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Complogoteam from "./Complogoteam";
import { Link } from "react-router-dom";

export default function Comphistoryanda() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [match, setMatch] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi + "Pertandinganrankteam?id_user=" + localStorage.getItem("id")
      );
      //   console.log(response.data);
      setMatch(response.data);
    } catch (error) {}
  };

  const resvonsive = {
    0: {
      items: 1.5,
    },
    600: {
      items: 1.5,
    },
    1000: {
      items: 1.5,
    },
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <div>
      <br />
      <div className="mt-5">
        <div className="container">
          <div
            className="card mt-1 mb-2 shadow text-white"
            id="bggradient1"
            style={{ border: "none" }}
          >
            <div className="card-body">
              <h5 className="fw-bold" style={{ color: "#fff" }}>
                History rank team anda
              </h5>
              <small className="text-white">
                Hasil pertandingan rank team anda{" "}
              </small>
            </div>
          </div>
          <div className="">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={4}
              nav={false}
              responsive={resvonsive}
              dotsEach
              autoplay
            >
              <img
                src="/img/bennerbooking.png"
                className="img-fluid"
                alt=""
                style={{ borderRadius: "5px" }}
              ></img>
              <img
                src="/img/dp.png"
                className="img-fluid"
                alt=""
                style={{ borderRadius: "5px" }}
              ></img>
            </OwlCarousel>
          </div>
          {match == false ? (
            <>
              <center>
                <img
                  src="/img/trash.png"
                  class="img-fluid"
                  alt="Responsive image"
                  style={{ height: "200px" }}
                />
                <p className="mt-3 mb-5 fw-bold">
                  History rank team anda tidak tersedia
                </p>
              </center>
            </>
          ) : (
            <></>
          )}
          {match.map((data, index) => {
            return (
              <div key={index}>
                <Compjadwalrankteam kode_main={data.kode_main} />
                <div className="card my-3" id="bgcardliga" key={index}>
                  <div className="card-body">
                    <div className="card">
                      <div
                        className="card"
                        style={{
                          border: "3px solid white",
                          backgroundColor: "#2b2e5a",
                        }}
                      >
                        <div className="card-body">
                          <div class="row my-3">
                            <div className="col-sm-5 col-5">
                              <center>
                                {/* <img
                                  src="/img/club.png"
                                  class="img-fluid"
                                  alt="Responsive image"
                                  style={{ height: "100px" }}
                                /> */}
                                <Complogoteam team={data.team} />
                                <br />
                                <label className="fw-bold text-white">
                                  {data.team}
                                </label>
                              </center>
                              <div className="d-flex justify-content-center container">
                                <div>
                                  <Compgoal
                                    kode_main={data.kode_main}
                                    team={data.team}
                                    kode_team={data.kode_team}
                                  />
                                </div>
                                <div> </div>
                                <div style={{ marginLeft: "5px" }}>
                                  <Compassist
                                    kode_main={data.kode_main}
                                    team={data.team}
                                    kode_team={data.kode_team}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-2 col-2 mt-4">
                              <center>
                                <img
                                  src="./img/logomms.png"
                                  className="img-fluid"
                                  style={{ height: "20px" }}
                                ></img>{" "}
                                <p
                                  className="fw-bold text-white"
                                  style={{ fontSize: "10px" }}
                                >
                                  {data.score_team}{" "}
                                  <lalbel className="text-white"> - </lalbel>{" "}
                                  {data.score_lawan}
                                </p>
                                <Link
                                  to={`${
                                    "/pemainrankteam/" +
                                    data.team +
                                    "/" +
                                    data.team_lawan
                                  }`}
                                >
                                  <img
                                    src="./img/play.png"
                                    className="img-fluid"
                                    style={{ height: "20px" }}
                                  ></img>
                                </Link>
                              </center>
                            </div>
                            <div className="col-sm-5 col-5">
                              <center>
                                {/* <img
                                  src="/img/club.png"
                                  class="img-fluid"
                                  alt="Responsive image"
                                  style={{ height: "100px" }}
                                /> */}
                                <Complogoteam team={data.team_lawan} />
                                <br />
                                <label className="fw-bold text-white">
                                  {data.team_lawan}
                                </label>
                              </center>
                              <div className="d-flex justify-content-center container">
                                <div>
                                  <Compgoal
                                    kode_main={data.kode_main}
                                    team={data.team_lawan}
                                    kode_team={data.kode_team}
                                  />
                                </div>
                                <div style={{ marginLeft: "5px" }}>
                                  <Compassist
                                    kode_main={data.kode_main}
                                    team={data.team_lawan}
                                    kode_team={data.kode_team}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
