import React, { useEffect, useState } from "react";
import axios from "axios";

export default function ({ kode_main }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [game, setGame] = useState([]);

  const datagame = async () => {
    try {
      const response = await axios.get(urlapi + "Game2?kode=" + kode_main);
      setGame(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    datagame();
  }, []);

  return (
    <div>
      <div
        className="card mb-2 shadow text-secondary"
        style={{ border: "none" }}
      >
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <small>
              <i className="fa-solid fa-shield-halved"></i> Rank solo
            </small>
            <small>
              <i className="fas fa-calendar-days"></i> {game.tgl_main}
            </small>
          </div>

          <div className="d-flex justify-content-between mt-1">
            <small>
              <i className="fas fa-futbol"></i> {game.lapangan}
            </small>
            <small>
              {" "}
              <i className="fas fa-clock"></i> {game.jam_main} WIB
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}
