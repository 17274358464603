import React from "react";
import Navbar from "../componenst/Navbar";
import Comphasilrankteam2 from "../componenst/Membarkarirteam/Comphasilrankteam2";

export default function Hasilrankteam() {
  return (
    <div>
      <Navbar judul="Hasil rank team" aicon="true" />
      <Comphasilrankteam2 />
    </div>
  );
}
