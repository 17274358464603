import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Compgoal from "./Compgoal";
import Compassist from "./Compassist";
import Compjadwalrankteam from "./Compjadwalrankteam";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import Complogoteam from "./Complogoteam";
import Compkartu from "./Compkartu";

export default function Comphasilrankteam2() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [match, setMatch] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(urlapi + "Pertandinganrankteam");
      //   console.log(response.data);
      setMatch(response.data);
    } catch (error) {}
  };

  const resvonsive = {
    0: {
      items: 1.5,
    },
    600: {
      items: 1.5,
    },
    1000: {
      items: 1.5,
    },
  };

  useEffect(() => {
    getdata();
  }, []);
  return (
    <div>
      <br></br>

      <div className="container mt-4">
        <div
          className="card mt-5 shadow"
          id="bggradient1"
          style={{ border: "none" }}
        >
          <div className="card-body text-white">
            <h5 className="fw-bold" style={{ color: "" }}>
              Hasil rank team{" "}
            </h5>
            <small>Hasil pertandingan rank team medan mini soccer </small>
          </div>
        </div>
        <div className="mt-3">
          <OwlCarousel
            className="owl-theme"
            loop
            margin={4}
            nav={false}
            responsive={resvonsive}
            dotsEach
            autoplay
          >
            <img
              src="/img/bennerbooking.png"
              className="img-fluid"
              alt=""
              style={{ borderRadius: "5px" }}
            ></img>
            <img
              src="/img/dp.png"
              className="img-fluid"
              alt=""
              style={{ borderRadius: "5px" }}
            ></img>
          </OwlCarousel>
        </div>

        {match.map((data, index) => {
          return (
            <div key={index}>
              <Compjadwalrankteam kode_main={data.kode_main} />
              <div className="card my-2" id="bgcardliga" key={index}>
                <div className="card-body">
                  <div className="card">
                    <div
                      className="card"
                      style={{
                        border: "3px solid white",
                        backgroundColor: "#2b2e5a",
                      }}
                    >
                      <div className="card-body">
                        <div class="row my-3">
                          <div className="col-sm-5 col-5">
                            <center>
                              {/* <img
                                src="/img/club.png"
                                class="img-fluid"
                                alt="Responsive image"
                                style={{ height: "100px" }}
                              /> */}
                              <Complogoteam team={data.team} />
                              <div className="mt-2">
                                <label className="fw-bold text-white">
                                  {data.team}
                                </label>
                              </div>
                            </center>
                            <div className="d-flex justify-content-center container">
                              <div>
                                <Compgoal
                                  kode={data.kode}
                                  kode_main={data.kode_main}
                                  team={data.team}
                                  kode_team={data.kode_team}
                                />
                              </div>
                              <div> </div>
                              <div style={{ marginLeft: "5px" }}>
                                <Compassist
                                  kode={data.kode}
                                  kode_main={data.kode_main}
                                  team={data.team}
                                  kode_team={data.kode_team}
                                />
                              </div>
                            </div>
                            <Compkartu
                              kode={data.kode}
                              kode_main={data.kode_main}
                              team={data.team}
                            />
                          </div>
                          <div className="col-sm-2 col-2 mt-4">
                            <center>
                              <img
                                src="./img/logomms.png"
                                className="img-fluid"
                                style={{ height: "20px" }}
                              ></img>{" "}
                              <p
                                className="fw-bold text-white"
                                style={{ fontSize: "9px" }}
                              >
                                {data.score_team}{" "}
                                <lalbel className="text-white"> - </lalbel>{" "}
                                {data.score_lawan}
                              </p>
                              <Link
                                to={`${
                                  "/pemainrankteam/" +
                                  data.team +
                                  "/" +
                                  data.lawan +
                                  "/" +
                                  data.kode
                                }`}
                              >
                                <img
                                  src="./img/play.png"
                                  className="img-fluid"
                                  style={{ height: "20px" }}
                                ></img>
                              </Link>
                            </center>
                          </div>
                          <div className="col-sm-5 col-5">
                            <center>
                              <Complogoteam team={data.lawan} />
                              {/* <img
                                src="/img/club.png"
                                class="img-fluid"
                                alt="Responsive image"
                                style={{ height: "100px" }}
                              /> */}
                              <div className="mt-2">
                                <label className="fw-bold text-white">
                                  {data.lawan}
                                </label>
                              </div>
                            </center>
                            <div className="d-flex justify-content-center container">
                              <div>
                                <Compgoal
                                  kode={data.kode}
                                  kode_main={data.kode_main}
                                  team={data.lawan}
                                  kode_team={data.kode_team}
                                />
                              </div>
                              <div style={{ marginLeft: "5px" }}>
                                <Compassist
                                  kode={data.kode}
                                  kode_main={data.kode_main}
                                  team={data.lawan}
                                  kode_team={data.kode_team}
                                />
                              </div>
                            </div>

                            <Compkartu
                              kode={data.kode}
                              kode_main={data.kode_main}
                              team={data.lawan}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {match == false ? (
          <>
            <div className="my-5">
              {" "}
              <center>
                <img
                  src="./img/trash.png"
                  className="img-fluid"
                  style={{ height: "200px" }}
                ></img>
                <p className="text-center fw-bold mt-3">
                  Data pertandingan rank team <br />
                  medan mini soccer masih kosong
                </p>
              </center>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
