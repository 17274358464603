import React from "react";

export default function Complisttopkiper() {
  return (
    <div>
      <div className="mt-3">
        <p className="text-center text-secondary fw-bold">Top Kiper Kosong</p>
      </div>
    </div>
  );
}
