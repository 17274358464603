import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

export default function Comppemain({ kodemain, team, lawan }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [pemain, setPemain] = useState([]);
  const [pemain2, setPemain2] = useState([]);
  const { all } = useParams();
  const [show, setShow] = useState(false);
  const [video, setVideo] = useState([]);
  const [jam, setJam] = useState(0);
  var date = new Date();

  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Getpemainmemberkarir?kodemain=" +
          kodemain +
          "&&team=" +
          team +
          "&&lawan=" +
          lawan
      );
      setPemain(response.data.team);
      setPemain2(response.data.lawan);
    } catch (error) {}
  };

  const getVideo = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Videopertandingan?kode_main=" +
          kodemain +
          "&&team=" +
          team +
          "&&lawan=" +
          lawan
      );
      setVideo(response.data);
    } catch (error) {}
  };

  const getshow = async () => {
    try {
      const response = await axios.get(urlapi + "Getshow");
      // console.log(response.data);
      if (response.data.show == 1) {
        setShow(true);
      } else {
        setShow(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    setJam(date.getHours());
    getdata();
    getVideo();
    getshow();
  }, []);
  return (
    <div>
      <div>
        <div className="card mt-5">
          <img
            src="/img/mms1.png"
            className="img-fluid"
            alt="Responsive image"
          ></img>

          <div className="container">
            {all == null || all == "" || all == false ? (
              <>
                <div className="card mt-3 shadow">
                  <div className="card-body">
                    <p className="fw-bold">
                      <i className="fas fa-play text-primary"></i> Video hasil
                      pertandingan
                    </p>
                    <div class="ratio ratio-16x9">
                      <iframe
                        className="embed-responsive-item"
                        src={
                          "https://www.youtube.com/embed/" + video.link_video
                        }
                      ></iframe>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div
              className="card shadow mt-3 text-white shadow"
              style={{ border: "none" }}
              id="bglistpemain"
            >
              <div className="card-body">
                <h5 className="fw-bold">Halaman peserta</h5>
                <label>Halaman peserta rank solo medan mini soccer</label>
              </div>
            </div>
          </div>

          <div className="card-body shadow">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6 col-6">
                    <p className="text-secondary">
                      <i className="fas fa-shield-halved"></i> Team{" "}
                      {team == "Putih" ? "Hijau" : team}
                    </p>
                    {pemain.map((data, index) => {
                      return (
                        <div key={index}>
                          {/* <Link
                            key={index}
                            to={"/detailpemain/" + data.id_user}
                            style={{ textDecoration: "none", color: "black" }}
                          > */}
                          <div className="d-flex justify-content-between">
                            <div>
                              <img
                                src="/img/pm.png"
                                className="img-fluid"
                                alt="Responsive image"
                                style={{ height: "20px" }}
                              ></img>
                              <label
                                className="fw-bold"
                                style={{ marginLeft: "8px", fontSize: "8px" }}
                              >
                                {show == false ? <>Player</> : <>{data.nama}</>}
                                {data.posisi == "Penjaga gawang" ||
                                data.posisi == "Penjaga Gawang" ? (
                                  <> (GK)</>
                                ) : (
                                  <></>
                                )}
                                {index == 0 ? " (C)" : ""}
                              </label>
                            </div>
                          </div>
                          <hr />
                          {/* </Link> */}
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-sm-6 col-6">
                    <p className="text-secondary">
                      {" "}
                      <i className="fas fa-shield-halved"></i> Team{" "}
                      {lawan == "Putih" ? "Hijau" : lawan}
                    </p>
                    {pemain2.map((data, index) => {
                      return (
                        <>
                          {/* <Link
                            key={index}
                            to={"/detailpemain/" + data.id_user}
                            style={{ textDecoration: "none", color: "black" }}
                          > */}
                          <div className="d-flex justify-content-between">
                            <div>
                              <img
                                src="/img/pm.png"
                                className="img-fluid"
                                alt="Responsive image"
                                style={{ height: "20px" }}
                              ></img>
                              <label
                                className="fw-bold"
                                style={{ marginLeft: "8px", fontSize: "8px" }}
                              >
                                {show == false ? <>Player</> : <>{data.nama}</>}

                                {data.posisi == "Penjaga gawang" ||
                                data.posisi == "Penjaga Gawang" ? (
                                  <> (GK)</>
                                ) : (
                                  <></>
                                )}
                                {index == 0 ? " (C)" : ""}
                              </label>
                            </div>
                          </div>
                          <hr />
                          {/* </Link> */}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
