import React from "react";
import Compteam from "./Compteam";

export default function Compheaderteam() {
  return (
    <div>
      <div>
        <div
          className="card text-white"
          style={{
            borderRadius: "0px",
            backgroundImage:
              "url('https://p4.wallpaperbetter.com/wallpaper/199/530/491/field-football-tribunes-strips-wallpaper-preview.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "containt",
            backgroundPosition: "center",
          }}
        >
          <br />
          <br />
          <div className=" mt-5">
            <div className="row">
              <div className="col-sm-6 col-8">
                {" "}
                <div
                  className="card border-0"
                  style={{
                    position: "relative",
                    top: "50px",
                    right: "1px",
                    borderTopRightRadius: "20px",
                    borderTopLeftRadius: "0px",
                  }}
                >
                  <div className="card-body">
                    <Compteam />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
