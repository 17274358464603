import React from "react";

export default function Compprofil({ foto }) {
  return (
    <div>
      <center>
        {foto == "" ? (
          <>
            {" "}
            <img
              src="http://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png"
              className="img-fluid "
              alt=""
              style={{
                height: "150px",
                width: "150px",
                //   borderRadius: "100%",
                //   borderStyle: "solid",
              }}
            ></img>
          </>
        ) : (
          <>
            {" "}
            <img
              src={foto}
              className="img-fluid "
              alt=""
              style={{
                height: "150px",
                width: "150px",
                //   borderRadius: "100%",
                //   borderStyle: "solid",
              }}
            ></img>
          </>
        )}
      </center>
    </div>
  );
}
