import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import Compmatch from "./Compmatch";
import Compgoal from "./Compgoal";
import Compassist from "./Compassist";
import Compwinrate from "./Compwinrate";
import Comprank from "./Comprank";

export default function Compmain() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [winrate, setWinrate] = useState([]);
  const [profil, setProfil] = useState([]);
  const { iduser } = useParams();

  const [play, setPlay] = useState(true);
  const [goal, setGoal] = useState(false);
  const [assist, setAssist] = useState(false);
  const [winrate2, setWinrate2] = useState(false);
  const [rank, setRank] = useState(false);

  const resvonsive = {
    0: {
      items: 3.2,
    },
    600: {
      items: 3.2,
    },
    1000: {
      items: 3.2,
    },
  };

  const getData = async () => {
    try {
      const response = await axios.get(urlapi + "Winrate?iduser=" + iduser);
      setWinrate(response.data);
    } catch (error) {}
  };

  const getProfil = async () => {
    try {
      const response = await axios.get(urlapi + "Profil?id=" + iduser);
      setProfil(response.data);
    } catch (error) {}
  };

  const Handlemenu = (menu) => {
    if (menu == "play") {
      setPlay(true);
      setGoal(false);
      setAssist(false);
      setWinrate2(false);
      setRank(false);
    } else if (menu == "goal") {
      setPlay(false);
      setGoal(true);
      setAssist(false);
      setWinrate2(false);

      setRank(false);
    } else if (menu == "assist") {
      setPlay(false);
      setGoal(false);
      setAssist(true);
      setWinrate2(false);
      setRank(false);
    } else if (menu == "winrate") {
      setPlay(false);
      setGoal(false);
      setAssist(false);
      setWinrate2(true);
      setRank(false);
    } else if (menu == "rank") {
      setPlay(false);
      setGoal(false);
      setAssist(false);
      setWinrate2(false);
      setRank(true);
    }
  };

  useEffect(() => {
    getData();
    getProfil();
  }, []);

  return (
    <div>
      <div>
        <div
          className="card text-white"
          id="bgprofil"
          style={{
            borderRadius: "0px",
            backgroundColor: "#2b2e5a",
            height: "250px",
          }}
        >
          <div className="container my-5">
            <div class="d-flex align-items-stretch">
              <div>
                <img
                  src="/pemain.png"
                  className="img-fluid mt-4"
                  alt="343"
                  style={{
                    borderRadius: "100%",
                    borderStyle: "solid",
                    height: "70px",
                  }}
                ></img>
                <small
                  className="fw-bold"
                  style={{ marginLeft: "10px", marginTop: "25px" }}
                >
                  {profil.nama}
                </small>
              </div>
              <div></div>
            </div>
            <div className="mt-5">
              <div class="d-flex justify-content-between">
                <button
                  type="button"
                  class={
                    play
                      ? "btn btn-light btn-sm"
                      : "btn btn-outline-light btn-sm"
                  }
                  onClick={() => Handlemenu("play")}
                >
                  Play
                </button>

                <button
                  type="button"
                  class={
                    goal == true
                      ? "btn btn-light btn-sm"
                      : "btn btn-outline-light btn-sm"
                  }
                  onClick={() => Handlemenu("goal")}
                >
                  Goal
                </button>

                <button
                  type="button"
                  class={
                    assist
                      ? "btn btn-light btn-sm"
                      : "btn btn-outline-light btn-sm"
                  }
                  onClick={() => Handlemenu("assist")}
                >
                  Assist
                </button>
                <button
                  type="button"
                  class={
                    winrate2
                      ? "btn btn-light btn-sm"
                      : "btn btn-outline-light btn-sm"
                  }
                  onClick={() => Handlemenu("winrate")}
                >
                  Winrate
                </button>

                <button
                  type="button"
                  class={
                    rank
                      ? "btn btn-light btn-sm"
                      : "btn btn-outline-light btn-sm"
                  }
                  onClick={() => Handlemenu("rank")}
                >
                  Rank
                </button>
              </div>
            </div>
          </div>
        </div>

        <center></center>
      </div>
      {play == true ? (
        <>
          <div style={{ height: "600px" }}>
            <div className="container">
              <div className="card mt-3 shadow" style={{ border: "none" }}>
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div className="card border-dark shadow">
                      <div className="card-body">
                        <small className="fw-bold">
                          {winrate.play} <br />
                          Play
                        </small>
                      </div>
                    </div>

                    <div className="card border-success shadow">
                      <div className="card-body">
                        <small className="fw-bold text-success">
                          {winrate.win} <br />
                          Win
                        </small>
                      </div>
                    </div>

                    <div className="card border-primary shadow">
                      <div className="card-body">
                        <small className="fw-bold text-primary">
                          {winrate.draw} <br />
                          Draw
                        </small>
                      </div>
                    </div>

                    <div className="card border-danger shadow">
                      <div className="card-body ">
                        <small className="fw-bold text-danger">
                          {winrate.lost} <br />
                          Lost
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Compmatch iduser={iduser} />
            </div>
          </div>
        </>
      ) : goal == true ? (
        <>
          <Compgoal id_user={iduser} />
        </>
      ) : assist == true ? (
        <>
          {" "}
          <Compassist id_user={iduser} />
        </>
      ) : winrate2 == true ? (
        <>
          <Compwinrate id_user={iduser} />
        </>
      ) : (
        <Comprank id_user={iduser} />
      )}
    </div>
  );
}
