import axios from "axios";
import React, { useEffect, useState } from "react";

export default function StatusBookedrankteam({ kode_main, jam_mulai }) {
  const [booked, setBooked] = useState([]);
  const urlapi = process.env.REACT_APP_BASE_URL;

  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Getbookedrankteam?kode_main=" +
          kode_main +
          "&&jam_mulai=" +
          jam_mulai
      );
      console.log(response.data);
      setBooked(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getdata();
  }, [kode_main]);

  return (
    <div>
      {booked.message != "false" ? (
        <div>Booked rank</div>
      ) : (
        <>
          {/* <small style={{ fontSize: "10px" }}>Booked</small>

          <div className="text-success" style={{ fontSize: "10px" }}>
            Menunggu persetujuan, anda masih dapat booking jam lapangan ini
          </div> */}
        </>
      )}
    </div>
  );
}
