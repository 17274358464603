import React from "react";
import Navbar from "../componenst/Navbar";
import Comppemain from "../componenst/History/Comppemain";

export default function Pemainmemberkarir() {
  return (
    <div>
      <Navbar judul="Peserta" aicon="true" />
      <Comppemain />
    </div>
  );
}
