import axios from "axios";
import React, { useEffect, useState } from "react";
import Compnama from "../Memberkarir/Compnama";

export default function Compgoalasist({ kodemain, team, lawan }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [goal, setGoal] = useState([]);
  const [assist, setAssist] = useState([]);

  const datagoal = async () => {
    try {
      const response = await axios.get(
        urlapi + "Goalmemberkarir?kodemain=" + kodemain + "&&team=" + team
      );
      setGoal(response.data);
      // console.log(response.data);
    } catch (error) {}
  };

  const dataassist = async () => {
    try {
      const response = await axios.get(
        urlapi + "Assistmemberkarir?kodemain=" + kodemain + "&&team=" + team
      );
      setAssist(response.data);
      // console.log(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    datagoal();
    dataassist();
  }, []);

  return (
    <div>
      <div>
        {/* <label className="text-secondary fw-bold">Goal & Assist : </label> */}

        <div className="d-flex justify-content-center mt-2">
          <div>
            {goal.map((data, index) => {
              return (
                <div key={index}>
                  <div className="text-white" style={{ fontSize: "5px" }}>
                    {" "}
                    <i className="far fa-futbol"></i>{" "}
                    {data.id_user == "Own goal" ? (
                      "Own goal"
                    ) : (
                      <>
                        {" "}
                        <Compnama iduser={data.id_user} />
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="">
            {assist.map((data, index) => {
              return (
                <div key={index}>
                  <div
                    className="text-warning"
                    style={{ fontSize: "5px", marginLeft: "3px" }}
                  >
                    {" "}
                    {data.id_user == "" ? (
                      <>({data.menit})</>
                    ) : (
                      <>
                        {" "}
                        (<Compnama iduser={data.id_user} />) ({data.menit})
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
