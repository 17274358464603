import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Compteampemain({ id_pemain }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [team, setTeam] = useState([]);

  const getteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Teampemain?id_pemain=" + id_pemain
      );
      setTeam(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getteam();
  }, [id_pemain]);

  return <div>{team.team}</div>;
}
