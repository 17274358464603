import Navbar from "../componenst/Navbar";
import Compheaderkarir from "../componenst/Memberkarir/Compheaderkarir";
import Compmain from "../componenst/Memberkarir/Compmain";
import Compmain2 from "../componenst/Memberkarir/Compmain2";

export default function Memberkarir() {
  return (
    <div>
      <Navbar judul="Rank solo" aicon="true" />
      <Compheaderkarir />
      <Compmain2 />
    </div>
  );
}
