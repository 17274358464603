import React from "react";
import { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { imageDb } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import axios from "axios";
import Compposisi from "./Compposisi";

export default function Comppembayaran({ harga, posisi, sesi }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const urlapi = process.env.REACT_APP_BASE_URL;
  const [bank, setBank] = useState("");
  const [atasnama, setAtasnama] = useState("");
  const [nameImg, setNameImg] = useState("");
  const [valueimg, setvalueimg] = useState("");
  const [img, setImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(true);
  const [alertwa, setAlertwa] = useState(false);
  const [pagepenuh, setPagepenuh] = useState(false);

  const handleChangeImg = (e) => {
    setImg(e.target.files[0]);

    setvalueimg(e.target.value);
    setNameImg(e.target.files[0].name);
  };

  const handleBayar = () => {
    setForm(false);
    setLoading(true);
    const imgRef = ref(imageDb, `files/${nameImg}`);
    uploadBytes(imgRef, img);
    console.log(imgRef._location.path_);

    setTimeout(() => {
      getDownloadURL(ref(imageDb, `files/${nameImg}`)).then((url) => {
        console.log("prosess");
        setForm(false);
        addMemberkarir(url);
      });
    }, 15000);
  };

  const addMemberkarir = async (url) => {
    if (posisi == "Penjaga gawang") {
      await axios
        .post(urlapi + "Add_memberpenjagagawang", {
          nama: localStorage.getItem("nama"),
          iduser: localStorage.getItem("id"),
          posisi: posisi,
          bukti: url,
          bank: bank,
          atasnama: atasnama,
          sesimain: sesi,
        })
        .then((response) => {
          setForm(false);
          setLoading(false);
          setAlertwa(true);
          console.log(response.data.message);
          if (response.data.message == "penjaga gawang penuh") {
            setPagepenuh(true);
          }
          // sendwa(response.data.kode_booking);
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      await axios
        .post(urlapi + "Add_memberkarirbaru", {
          nama: localStorage.getItem("nama"),
          iduser: localStorage.getItem("id"),
          posisi: posisi,
          bukti: url,
          bank: bank,
          atasnama: atasnama,
          sesimain: sesi,
        })
        .then((response) => {
          setForm(false);
          setLoading(false);
          setAlertwa(true);
          console.log(response.data);
          // sendwa(response.data.kode_booking);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  return (
    <>
      <Button
        variant="btn w-100 rounded-pill"
        onClick={handleShow}
        style={{ backgroundColor: "#2b2e5a", color: "white" }}
      >
        Bergabung sekarang
      </Button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="bottom"
        style={{ height: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Container>
            <Offcanvas.Title>Pembayaran</Offcanvas.Title>
          </Container>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container>
            {loading ? (
              <div className="text-center" style={{ marginTop: "150px" }}>
                <div class="spinner-grow text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-success" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <br></br>
                <label>Loading....</label>
                <p className="text-center">
                  Mohon untuk menunggu proses pembayaran
                </p>
              </div>
            ) : (
              ""
            )}

            {form ? (
              <>
                <p>
                  Pembayaran rank solo hanya dapat di lakukan dengan BANK
                  TRANSFER dengan nomor rekening tujuan{" "}
                  <b>REK BCA : 6475383951 a/n Pendy Or Handoko</b>
                </p>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control type="number" value={harga} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control type="text" value={`Sesi ` + sesi} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <select
                    className="form-control"
                    required
                    onChange={(e) => setBank(e.target.value)}
                  >
                    <option value="">-- Pilih Nama Bank Anda --</option>
                    <option>BRI</option>
                    <option>BNI</option>
                    <option>BCA</option>
                    <option>Mandiri</option>
                    <option>Bank Sumut</option>
                    <option>Danamon</option>
                    <option>CIMB Niaga</option>
                    <option>Lainya</option>
                  </select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    onChange={(e) => setAtasnama(e.target.value)}
                    placeholder="Atas nama"
                  />
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="file"
                    onChange={(e) => handleChangeImg(e)}
                  />
                  <Form.Text className="text-muted">
                    Masukan bukti pembayaran anda dengan benar dan jelas dengan
                    format Gambar (JPG, JPEG dan PNG)
                  </Form.Text>
                </Form.Group> */}
                <Form.Group className="mb-3" controlId="formBasicupload">
                  <label for="images" class="drop-container" id="dropcontainer">
                    <span class="drop-title">
                      {nameImg == "" ? (
                        "Upload bukti pembayaran"
                      ) : (
                        <>
                          <i class="fa-solid fa-circle-check text-success"></i>{" "}
                          Upload sukses
                        </>
                      )}
                    </span>

                    <input
                      type="file"
                      id="images"
                      className="form-control"
                      accept="image/*"
                      onChange={(e) => handleChangeImg(e)}
                      required
                    />
                  </label>
                  <small>
                    Upload bukti pembayaran dengan format Gambar (JPG, JPEG &
                    PNG)
                  </small>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <textarea
                    className="form-control"
                    placeholder="Keterangan"
                  ></textarea>
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
                {bank == "" || atasnama == "" || valueimg == "" ? (
                  <button
                    className="btn w-100 rounded-pill disabled"
                    style={{ backgroundColor: "#2b2e5a", color: "white" }}
                  >
                    Bayar sekarang
                  </button>
                ) : (
                  <button
                    className="btn w-100 rounded-pill"
                    onClick={() => handleBayar()}
                    style={{ backgroundColor: "#2b2e5a", color: "white" }}
                  >
                    Bayar sekarang
                  </button>
                )}
              </>
            ) : (
              ""
            )}

            {alertwa ? (
              <>
                <center>
                  {pagepenuh == false ? (
                    <>
                      <img
                        src="/sukses.png"
                        className="img-fluid"
                        alt=""
                        style={{ height: "100px" }}
                      ></img>
                      <h5 className="mt-4 text-secondary">
                        <strong>Hei, {localStorage.getItem("nama")} </strong>
                        <br></br>
                        Pembayaran anda berhasil dikirim silahkan menunggu
                        persetujuan pembayaran anda
                      </h5>
                      <a
                        href={"/memberkarirbaru"}
                        className="btn mt-5"
                        style={{
                          backgroundColor: "#2b2e5a",
                          color: "white",
                        }}
                      >
                        Tutup popup
                      </a>
                    </>
                  ) : (
                    <>
                      <img
                        src="img/warning.png"
                        className="img-fluid"
                        alt=""
                        style={{ height: "100px" }}
                      ></img>
                      <h4 className="mt-4 text-secondary">
                        Mohon maaf untuk posisi penjaga gawang sudah penuh,
                        cobalah ubah posisi bermain anda atau pilih sesi main
                        yang berbeda
                      </h4>

                      <a
                        href={"/memberkarirbaru"}
                        className="btn mt-2"
                        style={{
                          backgroundColor: "#2b2e5a",
                          color: "white",
                        }}
                      >
                        Tutup popup
                      </a>
                      <Compposisi title={"Ubah posisi anda"} />
                    </>
                  )}
                </center>
              </>
            ) : (
              <div></div>
            )}
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
