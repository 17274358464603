import axios from "axios";
import React, { useEffect, useState } from "react";
import Compnama from "../Memberkarir/Compnama";

export default function Compgoal({ kode_main, team }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [goal, setGoal] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi + "Goalranksolo?kode_main=" + kode_main + "&&team=" + team
      );
      setGoal(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <div className="my-1">
      {goal.map((data, index) => {
        return (
          <div key={index} className="text-white" style={{ fontSize: "5px" }}>
            <i className="fas fa-futbol"></i>{" "}
            {data.id_user == "Own goal" || data.id_user == "own goal" ? (
              "Own goal"
            ) : (
              <>
                <Compnama iduser={data.id_user} />
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}
