import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Complogoteamrank from "../Rankleague/Complogoteamrank";
import Complogoteamrank2 from "./Complogoteamrank2";

export default function Compmatchrankteam({
  kode_main,
  lapangan,
  tglmain,
  jammain,
  id,
}) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [pp1, setPP1] = useState([]);
  const [pp2, setPP2] = useState([]);

  const getdata1 = async () => {
    try {
      const response = await axios.get(
        urlapi + "Matchrankteam?kode_main=" + kode_main + "&&sesi=1"
      );
      // console.log(response.data);
      setPP1(response.data);
    } catch (error) {}
  };

  const getdata2 = async () => {
    try {
      const response = await axios.get(
        urlapi + "Matchrankteam?kode_main=" + kode_main + "&&sesi=2"
      );
      // console.log(response.data);
      setPP2(response.data);
    } catch (error) {}
  };

  const Logo = ({ team }) => {
    const [img, setImg] = useState([]);
    const getLogo = async () => {
      try {
        const response = await axios.get(
          urlapi + "Logoteamrankteam?team=" + team
        );
        // console.log(response.data);
        setImg(response.data);
      } catch (error) {}
    };

    useEffect(() => {
      getLogo();
    }, []);

    return (
      <div>
        {img == null ? (
          <>
            {" "}
            <img
              src="/img/club.png"
              className="img-fluid rounded-circle"
              alt="logo"
              style={{
                height: "50px",
                width: "50px",
                border: "5px solid white",
              }}
            />
          </>
        ) : (
          <>
            {" "}
            <img
              src={img.logo_team}
              className="img-fluid rounded-circle"
              alt=""
              style={{
                height: "50px",
                width: "50px",
                border: "3px solid white",
              }}
            />
            <br />
            <span class="badge bg-dark">Pool {img.pool}</span>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    getdata1();
    getdata2();
  }, []);

  return (
    <div>
      <div className="row mb-2 mt-2">
        <div className={pp1 == false ? "d-none" : "col-sm-12 col-12"}>
          <div
            className="card shadow"
            style={{ border: "none" }}
            id="bglistpemain"
          >
            <div className="card-body text-white">
              <div className={pp1 == false ? "" : "d-none"}>
                <div className="d-flex justify-content-between">
                  <span
                    className="badge bg-success"
                    style={{ fontSize: "8px" }}
                  >
                    Match rank solo sesi 1
                  </span>{" "}
                  <small className="text-white" style={{ fontSize: "10px" }}>
                    <i className="fas fa-futbol"></i> {lapangan}
                  </small>
                </div>
                <label
                  className="fw-bold text-white"
                  style={{ fontSize: "12px" }}
                >
                  <i className="fas fa-circle-info"></i> Match sesi ini belum
                  tersedia team dan lawan
                </label>
                <hr />
                <div
                  class="d-flex justify-content-between fw-bold text-secondary"
                  style={{ fontSize: "12px" }}
                >
                  <small className="text-white">
                    <i className="far fa-calendar"></i> {tglmain}
                  </small>

                  <small className="text-white">
                    <i className="far fa-clock"></i> {jammain} WIB
                  </small>
                </div>
              </div>
              <div className={pp1 == false ? "d-none" : ""}>
                <div className="d-flex justify-content-between">
                  <span
                    className="badge bg-success"
                    style={{ fontSize: "8px" }}
                  >
                    Match rank team sesi 1
                  </span>{" "}
                  <small className="text-white" style={{ fontSize: "10px" }}>
                    <i className="fas fa-futbol"></i> {lapangan}
                  </small>
                </div>
                <div className="mt-3">
                  {pp1.lawan == "" ? (
                    <>
                      <Link
                        to="/daftarteamrankteam"
                        style={{ textDecoration: "none" }}
                      >
                        <div className="row">
                          <div className="col-sm-4 col-4">
                            <center>
                              <img
                                src="./img/logomms.png"
                                className="img-fluid"
                                style={{ height: "50px" }}
                              ></img>
                              <br />
                              <small
                                className="fw-bold"
                                style={{ fontSize: "10px" }}
                              >
                                <span
                                  className="badge bg-success"
                                  style={{ fontSize: "8px" }}
                                >
                                  Slot team terisi
                                </span>
                              </small>
                            </center>
                          </div>

                          <div className="col-sm-4 col-4">
                            <center>
                              {/* <img
                            src="./img/logomms.png"
                            className="img-fluid"
                            style={{ height: "30px" }}
                          ></img> */}
                              <h5 className="fw-bold text-warning">VS</h5>
                              <span
                                className="badge bg-secondary"
                                style={{ fontSize: "8px" }}
                              >
                                {" "}
                                Daftar sebagai lawan
                              </span>
                              <br />
                              <small
                                className="fw-bold text-white"
                                style={{ fontSize: "10px" }}
                              >
                                Rank team {id}
                              </small>
                            </center>
                          </div>

                          <div className="col-sm-4 col-4">
                            <center>
                              <img
                                src="./img/logodefault.png"
                                className="img-fluid"
                                style={{ height: "50px" }}
                              ></img>{" "}
                              <br />
                              <small
                                className="fw-bold text-success"
                                style={{ fontSize: "10px" }}
                              >
                                <span
                                  className="badge bg-danger"
                                  style={{ fontSize: "8px" }}
                                >
                                  {" "}
                                  Menunggu lawan
                                </span>
                              </small>
                            </center>
                          </div>
                        </div>
                      </Link>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div
                  className={
                    pp1.lawan == ""
                      ? "d-flex justify-content-around mt-3 d-none"
                      : "row mt-3"
                  }
                  style={{ color: "#2b2e5a" }}
                >
                  <div className="col-sm-4 col-4">
                    <center>
                      <Link
                        to={"/statistikteam/" + pp1.team}
                        style={{ textDecoration: "none" }}
                      >
                        {/* <Complogoteamrank2 team={pp1.team} /> */}
                        <Logo team={pp1.team} />
                        <label
                          className="fw-bold text-white"
                          style={{ fontSize: "10px" }}
                        >
                          {pp1.team}
                        </label>
                      </Link>
                    </center>
                  </div>
                  <div className="col-sm-4 col-4">
                    <center>
                      <label className="fw-bold text-center">
                        <img
                          src="./img/logomms.png"
                          className="img-fluid"
                          style={{ height: "30px" }}
                        ></img>
                        <br />
                      </label>
                      <br />
                      <small
                        className="fw-bold text-white"
                        style={{ fontSize: "10px" }}
                      >
                        Rank team {id}
                      </small>
                    </center>
                  </div>
                  <div className="col-sm-4 col-4">
                    <center>
                      <Link
                        to={"/statistikteam/" + pp1.lawan}
                        style={{ textDecoration: "none" }}
                      >
                        {/* <Complogoteamrank2 team={pp1.lawan} /> */}
                        <Logo team={pp1.lawan} />
                        <label
                          className="fw-bold text-white"
                          style={{ fontSize: "10px" }}
                        >
                          {pp1.lawan}
                        </label>
                      </Link>
                    </center>
                  </div>
                </div>
                <hr style={{ color: "white" }} />
                <div
                  className="d-flex justify-content-between fw-bold text-secondary"
                  style={{ fontSize: "12px" }}
                >
                  <small className="text-white" style={{ fontSize: "8px" }}>
                    <i className="far fa-calendar"></i> {pp1.tgl_main}
                  </small>

                  <small className="text-white" style={{ fontSize: "8px" }}>
                    <i className="far fa-clock"></i> {pp1.jam_mulai} WIB
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={pp2 == false ? "d-none" : "col-sm-12 col-12 mt-2"}>
          <div
            className="card shadow"
            style={{ border: "none" }}
            id="bglistpemain"
          >
            <div className="card-body">
              <div className={pp2 == false ? "" : "d-none"}>
                <div className="d-flex justify-content-between">
                  <span
                    className="badge bg-success"
                    style={{ fontSize: "8px" }}
                  >
                    Match rank team sesi 2
                  </span>{" "}
                  <small className=" text-white" style={{ fontSize: "10px" }}>
                    <i className="fas fa-futbol"></i> {lapangan}
                  </small>
                </div>
                <label
                  className="fw-bold text-white "
                  style={{ fontSize: "12px" }}
                >
                  <i className="fas fa-circle-info"></i> Match sesi ini belum
                  tersedia team dan lawan
                </label>
                <hr />
                <div
                  class="d-flex justify-content-between fw-bold text-secondary"
                  style={{ fontSize: "12px" }}
                >
                  <small className="text-white">
                    <i className="far fa-calendar"></i> {tglmain}
                  </small>

                  <small className="text-white">
                    <i className="far fa-clock"></i> {jammain} WIB
                  </small>
                </div>
              </div>
              <div className={pp2 == false ? "d-none" : ""}>
                <div className="d-flex justify-content-between">
                  <span
                    className="badge bg-success"
                    style={{ fontSize: "8px" }}
                  >
                    Match rank team sesi 2
                  </span>{" "}
                  <small className="text-white" style={{ fontSize: "10px" }}>
                    <i className="fas fa-futbol"></i> {lapangan}
                  </small>
                </div>

                <div className="mt-3">
                  {pp2.lawan == "" ? (
                    <>
                      <Link
                        to="/daftarteamrankteam"
                        style={{ textDecoration: "none" }}
                      >
                        <div className="row">
                          <div className="col-sm-4 col-4">
                            <center>
                              <img
                                src="./img/logomms.png"
                                className="img-fluid"
                                style={{ height: "50px" }}
                              ></img>
                              <br />
                              <small
                                className="fw-bold"
                                style={{ fontSize: "10px" }}
                              >
                                <span
                                  className="badge bg-success"
                                  style={{ fontSize: "8px" }}
                                >
                                  Slot team terisi
                                </span>
                              </small>
                            </center>
                          </div>

                          <div className="col-sm-4 col-4">
                            <center>
                              {/* <img
                            src="./img/logomms.png"
                            className="img-fluid"
                            style={{ height: "30px" }}
                          ></img> */}
                              <h5 className="fw-bold text-warning">VS</h5>
                              <span
                                className="badge bg-secondary"
                                style={{ fontSize: "8px" }}
                              >
                                {" "}
                                Daftar sebagai lawan
                              </span>
                              <br />
                              <small
                                className="fw-bold text-white"
                                style={{ fontSize: "10px" }}
                              >
                                Rank team {id}
                              </small>
                            </center>
                          </div>

                          <div className="col-sm-4 col-4">
                            <center>
                              <img
                                src="./img/logodefault.png"
                                className="img-fluid"
                                style={{ height: "50px" }}
                              ></img>{" "}
                              <br />
                              <small
                                className="fw-bold text-success"
                                style={{ fontSize: "10px" }}
                              >
                                <span
                                  className="badge bg-danger"
                                  style={{ fontSize: "8px" }}
                                >
                                  {" "}
                                  Menunggu lawan
                                </span>
                              </small>
                            </center>
                          </div>
                        </div>
                      </Link>
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                <div
                  className={pp2.lawan == "" ? "d-none" : "row"}
                  style={{ color: "#2b2e5a" }}
                >
                  <div className="col-sm-4 col-4">
                    <center>
                      <Link
                        to={"/statistikteam/" + pp2.team}
                        style={{ textDecoration: "none" }}
                      >
                        {/* <Complogoteamrank2 team={pp2.team} /> */}
                        <Logo team={pp2.team} />
                        <label
                          className="fw-bold text-white"
                          style={{ fontSize: "10px" }}
                        >
                          {pp2.team}
                        </label>
                      </Link>
                    </center>
                  </div>

                  <div className="col-sm-4 col-4">
                    <center>
                      <label className="fw-bold text-center">
                        <img
                          src="./img/logomms.png"
                          className="img-fluid"
                          style={{ height: "30px" }}
                        ></img>

                        <br />
                      </label>
                      <br />
                      <small
                        className="fw-bold text-white"
                        style={{ fontSize: "10px" }}
                      >
                        Rank team {id}
                      </small>
                    </center>
                  </div>

                  <div className="col-sm-4 col-4">
                    <center>
                      <Link
                        to={"/statistikteam/" + pp2.lawan}
                        style={{ textDecoration: "none" }}
                      >
                        {/* <Complogoteamrank2 team={pp2.lawan} /> */}
                        <Logo team={pp2.lawan} />
                        <label
                          className="fw-bold text-white"
                          style={{ fontSize: "10px" }}
                        >
                          {pp2.lawan}
                        </label>
                      </Link>
                    </center>
                  </div>
                </div>
                <hr style={{ color: "white" }} />
                <div
                  class="d-flex justify-content-between fw-bold text-secondary"
                  style={{ fontSize: "12px" }}
                >
                  <small className="text-white" style={{ fontSize: "8px" }}>
                    <i className="far fa-calendar"></i> {pp2.tgl_main}
                  </small>

                  <small className="text-white" style={{ fontSize: "8px" }}>
                    <i className="far fa-clock"></i> {pp2.jam_mulai} WIB
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
