import React from "react";

export default function Compheader() {
  return (
    <div>
      <div
        className="card bg-danger text-white"
        style={{ borderRadius: "0px", height: "200px" }}
      >
        <div className="container mt-4">
          <h3 className="card-title">Membar karir</h3>
          {/* <p className="card-text">
            Atur jadwal member karir anda dengan waktu yang anda tentukan
          </p> */}
        </div>
      </div>
    </div>
  );
}
