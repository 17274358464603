import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export default function Notifikasi() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [booking, setBooking] = useState([]);
  const [viewnotif, setViewnotif] = useState(true);
  const [viewdata, setViewdata] = useState(false);
  const [pelunasan, setPelunasan] = useState([]);
  const [datavoucher, setDatavoucher] = useState([]);

  const date = new Date();
  let tgl = new Date();
  let format_tgl =
    tgl.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + tgl.getDate()).slice(-2);

  const cekbooking = async () => {
    try {
      const response = await axios.get(
        urlapi + "Getbooking?id=" + localStorage.getItem("id")
      );
      setBooking(response.data);
      // console.log(response.data);
    } catch (error) { }
  };
  const cekbooking2 = async () => {
    try {
      const response = await axios.get(
        urlapi + "Getbooking?id=" + localStorage.getItem("id") + "&&all=true"
      );
      setBooking(response.data);
      console.log(response.data);
    } catch (error) { }
  };

  const cekpelunasan = async () => {
    try {
      const response = await axios.get(
        urlapi + "Addpelunasan?notif=true&&user=" + localStorage.getItem("id")
      );
      setPelunasan(response.data);
    } catch (error) { }
  };

  const handle = (e) => {
    if (e == "notifikasi") {
      cekbooking();
      setViewnotif(true);
      setViewdata(false);
    } else if (e == "data" && viewdata == true) {
      cekbooking();
      setViewnotif(true);
      setViewdata(false);
    } else if (e == "data") {
      cekbooking2();
      setViewnotif(false);
      setViewdata(true);
    }
  };

  const voucher = async () => {
    try {
      const response = await axios.get(urlapi + "Voucher?iduser=" + localStorage.getItem('id') + "&&notif=1");
      // console.log(response.data);
      setDatavoucher(response.data);
    } catch (error) {
      
    }
  }

  useEffect(() => {
    cekbooking();
    cekpelunasan();
    voucher();
  }, []);
  return (
    <div className={booking == "" ? "d-none" : ""}>
      <div class="d-flex justify-content-between">
        <p
          className={viewnotif ? "fw-bold text-primary" : "fw-bold"}
          onClick={() => handle("notifikasi")}
          style={{ cursor: "pointer" }}
        >
          Notifikasi <i className="fas fa-bell"></i>
        </p>
        <p
          className={viewdata ? "fw-bold text-primary" : "fw-bold"}
          onClick={() => handle("data")}
          style={{ cursor: "pointer" }}
        >
          Lihat lebih banyak{" "}
          <i
            className={viewdata ? "fas fa-angle-down" : "fas fa-angle-right"}
          ></i>
        </p>
      </div>

      {/* {pelunasan.map((pl, index) => {
        return (
          <>
            <Link
              key={index}
              to={"/booking/data"}
              style={{ textDecoration: "none" }}
            >
              <div
                class={format_tgl >= pl.tgl ? "alert alert-warning" : "d-none"}
                role="alert"
              >
                <small>
                  Hello {localStorage.getItem("nama")}, Anda belum melakukan
                  pelunasan {pl.lapangan} Tanggal Booking {pl.tgl}, Jam Booking{" "}
                  {pl.jam_booking}
                </small>
              </div>
            </Link>
          </>
        );
      })} */}

      {datavoucher != '' ?
        <>
          <div class="alert alert-primary" role="alert">
              <small>
              Hello {localStorage.getItem('nama')}, selamat anda mendapatkan voucher gratis booking lapangan.
              <div><Link to='/voucher' style={{ textDecoration : 'none' }}>Lihat voucher anda ? </Link></div>
              </small>
          </div>
        </>
        :
        <></>
      
      }
        

      {booking.map((data) => {
        return (
          <div key={data.id}>
            <Link to={"/booking/data"} style={{ textDecoration: "none" }}>
              {data.status_pembayaran == "200" ? (
                <div class="alert alert-primary" role="alert">
                  <small>
                    Booking {data.lapangan} pada tanggal{" "}
                    <strong>{data.tgl}</strong> jam{" "}
                    <strong>{data.jam_booking} WIB</strong> Disetuji
                  </small>
                </div>
              ) : data.status_pembayaran == "400" ? (
                <div class="alert alert-danger" role="alert">
                  <small>
                    Booking {data.lapangan} pada tanggal{" "}
                    <strong>{data.tgl}</strong> jam{" "}
                    <strong>{data.jam_booking} WIB</strong> Ditolak
                  </small>
                </div>
              ) : (
                <div class="alert alert-warning" role="alert">
                  <small>
                    Booking {data.lapangan} pada tanggal{" "}
                    <strong>{data.tgl}</strong> jam{" "}
                    <strong>{data.jam_booking} WIB</strong> Menunggu Persetujuan
                  </small>
                </div>
              )}
            </Link>
          </div>
        );
      })}
      <hr />
    </div>
  );
}
