import React, { useEffect, useState } from "react";
import axios from "axios";
import Compheaderteam from "./Compheaderteam";
import Compaddpemain from "./Compaddpemain";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Compmodal from "./Compmodal";

export default function Comprankteam() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [pemain, setPemain] = useState([]);
  const [formnama, setFormnama] = useState(false);
  const [idrow, setIdrow] = useState(0);
  const [teamrank, setTeamrank] = useState([]);
  const [pagesyarat, setPagesyarat] = useState(false);
  const [gamerankteam, setGamerankteam] = useState([]);

  const mainrankteam = async () => {
    try {
      const response = await axios.get(urlapi + "Fungamerankteam");

      setGamerankteam(response.data);
    } catch (error) {
      setGamerankteam("");
    }
  };

  const resvonsive = {
    0: {
      items: 1.5,
    },
    600: {
      items: 1.5,
    },
    1000: {
      items: 2.5,
    },
  };

  const getPemain = async () => {
    try {
      const response = await axios.get(
        urlapi + "Pemainrankteam?id_user=" + localStorage.getItem("id"),
        {
          proxy: {
            host: "localhost",
            port: 3000,
          },
          timeout: 10000,
        }
      );
      //   console.log(response.data);
      setPemain(response.data);
    } catch (error) {}
  };

  const handlerow = (id) => {
    setIdrow(id);
  };

  const getteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Datateam?id_user=" + localStorage.getItem("id"),
        {
          proxy: {
            host: "localhost",
            port: 3000,
          },
          timeout: 10000,
        }
      );
      setTeamrank(response.data);
      console.log(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getPemain();
    getteam();
    mainrankteam();
  }, []);

  return (
    <div>
      <Compheaderteam />
      <div className="container">
        <br />
        <br />
        <div className="mt-2">
          <div className="card mb-2 shadow border-0">
            <div className="mx-2">
              {/* <img
                src="img/bennerrankteam3.png"
                className="img-fluid mt-2"
                alt="Responsive image"
                style={{ borderRadius: "5px" }}
              ></img> */}

              {gamerankteam != false ? (
                <>
                  {/* <div class="alert alert-primary mt-2" role="alert">
                    <small className="fw-bold" style={{ fontSize: "12px" }}>
                      <i className="fas fa-info-circle"></i> Rank team medan
                      mini soccer dibuka, daftarkan sekarang juga
                    </small>
                  </div> */}
                </>
              ) : (
                <>
                  {/* <div class="alert alert-danger mt-2" role="alert">
                    <small className="fw-bold" style={{ fontSize: "12px" }}>
                      <i className="fas fa-info-circle"></i> Rank team medan
                      mini soccer tidak tersedia, cobolah dilain waktu
                    </small>
                  </div> */}
                </>
              )}
              {/* 
              <div class="alert alert-info mt-2" role="alert">
                <small className="fw-bold" style={{ fontSize: "12px" }}>
                  <i className="fas fa-info-circle"></i> Masukan data pemain
                  anda sesui dengan data diri pemain
                </small>
              </div> */}

              {/* <div class="alert alert-info" role="alert">
                <small className="fw-bold" style={{ fontSize: "12px" }}>
                  <i className="fas fa-info-circle"></i> Team hanya dapat
                  mendaftarkan 25 pemain
                </small>
              </div> */}

              {/* <OwlCarousel
                className="owl-theme"
                loop
                margin={4}
                nav={false}
                responsive={resvonsive}
                dotsEach={false}
                dots={false}
                autoplay={false}
              >
                <div className="card border-0 shadow" id="bggradient1">
                  <div className="card-body">
                    <label className="fw-bold text-white">Play</label> <br />
                    <small className="fw-bold text-white">0</small>
                  </div>
                </div>
                <div className="card border-0 shadow" id="bggradient1">
                  <div className="card-body">
                    <label className="fw-bold text-white">Win</label> <br />
                    <small className="fw-bold text-white">0</small>
                  </div>
                </div>

                <div className="card border-0 shadow" id="bggradient1">
                  <div className="card-body">
                    <label className="fw-bold text-white">Draw</label> <br />
                    <small className="fw-bold text-white">0</small>
                  </div>
                </div>
                <div className="card border-0 shadow" id="bggradient1">
                  <div className="card-body">
                    <label className="fw-bold text-white">Lost</label> <br />
                    <small className="fw-bold text-white">0</small>
                  </div>
                </div>
                <div className="card border-0 shadow" id="bggradient1">
                  <div className="card-body">
                    <label className="fw-bold text-white">Rank</label> <br />
                    <small className="fw-bold text-white">0</small>
                  </div>
                </div>
                <div className="card border-0 shadow" id="bggradient1">
                  <div className="card-body">
                    <label className="fw-bold text-white">Winrate</label>
                    <br />
                    <small className="fw-bold text-white">0</small>
                  </div>
                </div>
              </OwlCarousel> */}
            </div>
          </div>
          <hr />
        </div>
        <Compmodal />
        <Compaddpemain />
      </div>
    </div>
  );
}
