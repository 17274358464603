import React from "react";
import Compbookingrankteam from "./Compbookingrankteam";

export default function Compbookingrankteamall() {
  return (
    <div>
      <br />
      <div className="container">
        <div className="card mt-5" id="bglistpemain" style={{ color: "white" }}>
          <div className="card-body">
            <h5>Hello {localStorage.getItem("nama")}</h5>
            <p>Ini adalah data booking rank team anda</p>
          </div>
        </div>
        <Compbookingrankteam limit="all" />
        <div className="my-5"></div>
      </div>
    </div>
  );
}
