import React from "react";
import Navbar from "../componenst/Navbar";
import Compbookingrankteam from "../componenst/Membarkarirteam/Compbookingrankteam";

export default function Databookingrankteam() {
  return (
    <div>
      <Navbar judul="Data booking rank team" aicon="true" />
      <br />
      <br />
      <br />
      <div className="container">
        <div className="card border-0" id="bggradient1">
          <div className="card-body text-white">
            <h5 className="fw-bold">Hello {localStorage.getItem("nama")}</h5>
            <small>Selamat datang di halaman data booking rank team anda</small>
          </div>
        </div>
        <hr />
        <div
          className="d-flex justify-content-between mt-3"
          style={{ fontSize: "12px" }}
        >
          <small className="fw-bold text-secondary">
            <i className="fas fa-file"></i> Data booking anda
          </small>
          <small className="fw-bold text-secondary">
            <i className="fas fa-angle-right"></i>
          </small>
        </div>
        <div className="card mt-2 mb-5">
          <div className="card-body">
            <Compbookingrankteam limit="4" gambar="true" />
          </div>
        </div>
      </div>
    </div>
  );
}
