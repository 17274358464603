import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Compjadwalrankteam({ kode_main }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [game, setGame] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi + "Gamerankteam?kode_main=" + kode_main
      );
      setGame(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <div>
      <div
        className="card mb-1 shadow text-secondary"
        style={{ border: "none" }}
      >
        <div className="card-body" style={{ fontSize: "10px" }}>
          <div className="d-flex justify-content-between">
            <small>
              <i className="fa-solid fa-shield-halved"></i> Rank Team
            </small>
            <small>
              <i className="fas fa-calendar-days"></i> {game.tgl_main}
            </small>
          </div>

          <div className="d-flex justify-content-between mt-1">
            <small>
              <i className="fas fa-futbol"></i> {game.lapangan}
            </small>
            <small>
              {" "}
              <i className="fas fa-clock"></i> {game.jam_main} WIB
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}
