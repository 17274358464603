import React from "react";
import Compprofilpemain from "../componenst/Daftarteam/Compprofilpemain";
import Navbar from "../componenst/Navbar";

export default function Profilpemainrankteam() {
  return (
    <div>
      <Navbar judul="Detail pemain" aicon="true" />
      <Compprofilpemain />
    </div>
  );
}
