import React, { useEffect, useState } from "react";
import axios from "axios";

export default function Compnama({ iduser }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [nama, setNama] = useState("");

  const getNama = async () => {
    try {
      const response = await axios.get(urlapi + "Getnama?iduser=" + iduser);
      setNama(response.data.nama);
    } catch (error) {}
  };

  useEffect(() => {
    getNama();
  }, []);
  return <>{nama}</>;
}
