import React from "react";
import CompHeader from "../componenst/Liga/CompHeader";
import Navbar from "../componenst/Navbar";
import CompLiga from "../componenst/Liga/CompLiga";

export default function Liga() {
  return (
    <div>
      <Navbar judul="Liga EOF" aicon="true" />
      <CompHeader />
      <CompLiga />
    </div>
  );
}
