import React from "react";

export default function Compusers() {
  return (
    <div>
      <center>
        <img
          src="/img/users.png"
          className="img-fluid"
          alt=""
          style={{ borderRadius: "5px", height: "20px" }}
        ></img>
        <img
          src="/img/users.png"
          className="img-fluid"
          alt=""
          style={{ borderRadius: "5px", height: "20px" }}
        ></img>
        <img
          src="/img/users.png"
          className="img-fluid"
          alt=""
          style={{ borderRadius: "5px", height: "20px" }}
        ></img>
        <label className="text-secondary fw-bold">+5</label>
      </center>
    </div>
  );
}
