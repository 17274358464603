import axios from "axios";
import React, { useEffect, useState } from "react";
import Compcardgoalassist from "../History/Compcardgoalassist";
import Compcardhasil from "./Compcardhasil";
import Compnama from "../Memberkarir/Compnama";
import Compusers from "../History/Compusers";
import { Link } from "react-router-dom";

export default function Comphasil({ kodemain }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [hasil, setHasil] = useState([]);
  const [hasil2, setHasil2] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi + "Mainmemberkarir?kodemain=" + kodemain
      );
      setHasil(response.data.data);
      setHasil2(response.data.data2);
      // console.log(response.data);
    } catch (error) {}
  };

  const Cardhasilbru = ({ kodemain, team }) => {
    const [page, setPage] = useState(false);
    const [list, setList] = useState([]);
    const [assist, setAssist] = useState([]);
    const getdata = async () => {
      try {
        const response = await axios.get(
          urlapi + "Goalmemberkarir2?kodemain=" + kodemain + "&&team=" + team
        );
        // console.log(response.data);
        setList(response.data);
      } catch (error) {}
    };

    const getassist = async () => {
      try {
        const response = await axios.get(
          urlapi + "Assistmemberkarir?kodemain=" + kodemain + "&&team=" + team
        );
        setAssist(response.data);
      } catch (error) {}
    };
    useEffect(() => {
      getdata();
      getassist();
    }, []);
    return (
      <div>
        <div className="">
          <div className="d-flex justify-content-start">
            <div>
              {list.map((data, index) => {
                return (
                  <div key={index}>
                    <div className="fw-bold" style={{ fontSize: "8px" }}>
                      <i className="far fa-futbol"></i>{" "}
                      {data.id_user == "Own goal" ? (
                        "Own goal"
                      ) : (
                        <>
                          <Compnama iduser={data.id_user} />
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div>
              {assist.map((data, index) => {
                return (
                  <div
                    key={index}
                    style={{ fontSize: "8px", marginLeft: "5px" }}
                  >
                    {data.id_user == "" ? (
                      <>({data.menit})</>
                    ) : (
                      <>
                        (
                        <Compnama iduser={data.id_user} />) ({data.menit})
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getdata();
  }, []);
  return (
    <div>
      <div>
        {hasil == null ? (
          <></>
        ) : (
          <>
            <div className="card mb-2" style={{ border: "none" }}>
              <Link
                to={
                  "/pemainmemberkarir/" +
                  hasil.kode_main +
                  "/" +
                  hasil.team +
                  "/" +
                  hasil.team_lawan
                }
                style={{ textDecoration: "none", color: "black" }}
              >
                <div
                  className="card-body shadow"
                  style={{ borderRadius: "10px" }}
                >
                  <div className="card" id="bgcardliga">
                    <div className="card-body">
                      <div class="d-flex justify-content-around">
                        <div>
                          <img
                            src={
                              hasil.team == "Merah"
                                ? "/img/merah.png"
                                : hasil.team == "Kuning"
                                ? "/img/kuning.png"
                                : hasil.team == "Hitam"
                                ? "/img/hitam.png"
                                : "/img/putih.png"
                            }
                            className="img-fluid"
                            alt=""
                            style={{ borderRadius: "5px", height: "80px" }}
                          ></img>
                          <Compusers />
                        </div>
                        <div
                          className="mt-3 text-center"
                          style={{ color: "#ffff" }}
                        >
                          <img
                            src="./img/logomms.png"
                            className="img-fluid"
                            style={{ height: "30px" }}
                          ></img>
                          <p
                            className="fw-bold text-white"
                            style={{ fontSize: "10px" }}
                          >
                            {hasil.score_team}{" "}
                            <lalbel className="text-white">VS</lalbel>{" "}
                            {hasil.score_lawan}
                          </p>
                          {/* <small>2023-01-23</small> */}
                          <img
                            src="./img/play.png"
                            className="img-fluid"
                            style={{ height: "30px" }}
                          ></img>
                        </div>

                        <div>
                          <img
                            src={
                              hasil.team_lawan == "Merah"
                                ? "/img/merah.png"
                                : hasil.team_lawan == "Kuning"
                                ? "/img/kuning.png"
                                : hasil.team_lawan == "Hitam"
                                ? "/img/hitam.png"
                                : "/img/putih.png"
                            }
                            className="img-fluid"
                            alt=""
                            style={{ borderRadius: "5px", height: "80px" }}
                          ></img>
                          <Compusers />
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div className="d-flex justify-content-between">
                    <div>
                      <Cardhasilbru
                        kodemain={hasil.kode_main}
                        team={hasil.team}
                        lawan={hasil.team_lawan}
                      />
                    </div>

                    <div>
                      <Cardhasilbru
                        kodemain={hasil.kode_main}
                        team={hasil.team_lawan}
                        lawan={hasil.team}
                      />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </>
        )}
        <div className={hasil2 == null ? "d-none" : ""}>
          <div
            className="card-body shadow mt-2"
            style={{ borderRadius: "10px" }}
          >
            {hasil2 == null ? (
              ""
            ) : (
              <>
                <Link
                  to={
                    "/pemainmemberkarir/" +
                    hasil2.kode_main +
                    "/" +
                    hasil2.team +
                    "/" +
                    hasil2.team_lawan
                  }
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div className="card" id="bgcardliga">
                    <div className="card-body">
                      <div class="d-flex justify-content-around">
                        <div>
                          <img
                            src={
                              hasil2.team == "Merah"
                                ? "/img/merah.png"
                                : hasil2.team == "Kuning"
                                ? "/img/kuning.png"
                                : hasil2.team == "Hitam"
                                ? "/img/hitam.png"
                                : "/img/putih.png"
                            }
                            className="img-fluid"
                            alt=""
                            style={{ borderRadius: "5px", height: "80px" }}
                          ></img>
                          <Compusers />
                        </div>
                        <div className="mt-3 text-center">
                          <img
                            src="./img/logomms.png"
                            className="img-fluid"
                            style={{ height: "30px" }}
                          ></img>
                          <p
                            className="fw-bold text-white"
                            style={{ fontSize: "10px" }}
                          >
                            {hasil2.score_team}
                            <lalbel className="text-white"> VS </lalbel>{" "}
                            {hasil2.score_lawan}
                          </p>
                          <img
                            src="./img/play.png"
                            className="img-fluid"
                            style={{ height: "30px" }}
                          ></img>
                          {/* <small>2023-01-23</small> */}
                        </div>

                        <div>
                          <img
                            src={
                              hasil2.team_lawan == "Merah"
                                ? "/img/merah.png"
                                : hasil2.team_lawan == "Kuning"
                                ? "/img/kuning.png"
                                : hasil2.team_lawan == "Hitam"
                                ? "/img/hitam.png"
                                : "/img/putih.png"
                            }
                            className="img-fluid"
                            alt=""
                            style={{ borderRadius: "5px", height: "80px" }}
                          ></img>
                          <Compusers />
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className="d-flex justify-content-between">
                    <div>
                      {" "}
                      <Cardhasilbru
                        kodemain={hasil2.kode_main}
                        team={hasil2.team}
                        lawan={hasil2.team_lawan}
                      />
                    </div>
                    <div>
                      <Cardhasilbru
                        kodemain={hasil2.kode_main}
                        team={hasil2.team_lawan}
                        lawan={hasil2.team}
                      />
                    </div>
                  </div>

                  {/* <Compcardgoalassist
              kodemain={hasil2.kode_main}
              team={hasil2.team}
              lawan={hasil2.team_lawan}
            /> */}
                </Link>
              </>
            )}
          </div>
        </div>
        <br />
      </div>
    </div>
  );
}
