import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Compdataplay({ main }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [play, setPlay] = useState(false);
  const [dataplay, setDataplay] = useState([]);

  const getDatamain = async () => {
    try {
      const response = await axios.get(
        urlapi + "Datamain?id_user=" + localStorage.getItem("id")
      );
      setDataplay(response.data);
      // console.log(response.data);
    } catch (error) {
      //   console.log(error.message);
    }
  };

  useEffect(() => {
    getDatamain();
  }, []);
  return (
    <div>
      <div
        className={`d-flex justify-content-between ${
          play ? "text-primary" : "text-secondary"
        }`}
        onClick={() => setPlay(!play)}
        style={{ cursor: "pointer" }}
      >
        <p>Play</p>
        <p>
          <a style={{ textDecoration: "none" }}>
            {main} Play{" "}
            <i
              className={play ? "fas fa-chevron-down" : "fas fa-chevron-right"}
            ></i>
          </a>
        </p>
      </div>
      {play ? (
        <div className="card border-primary">
          <div className="card card-body">
            {dataplay == false ? (
              <p className="text-center text-secondary">
                <i className="fas fa-trash"></i> Data Kosong
              </p>
            ) : (
              ""
            )}
            {dataplay.map((mm, index) => {
              return (
                <div key={index}>
                  <div className="d-flex justify-content-between text-primary">
                    <small className="">
                      <i className="fas fa-user"></i> {mm.team}
                    </small>
                    <small className="">
                      <i className="fas fa-shield"></i> {mm.team}
                    </small>
                    <small className="">
                      {mm.score_team} VS {mm.score_lawan}
                    </small>

                    <small className="">
                      <i className="fas fa-shield"></i> {mm.team_lawan}
                    </small>
                  </div>
                  <hr />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
