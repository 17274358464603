import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Complogoteamrank from "../Rankleague/Complogoteamrank";

export default function Compmainstatistik() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const { team } = useParams();
  const [data, setData] = useState([]);
  const [match, setMatch] = useState([]);
  const [pemain, setPemain] = useState([]);
  const [slicepemain, setSlicepemain] = useState(5);
  const [session, setSession] = useState([])
  const [pagesession, setPagesession] = useState('')

  const getdata = async () => {
    try {
      const response = await axios.get(urlapi + "Pointrankteam?team=" + team);
      //   console.log(response.data);
      setData(response.data);
    } catch (error) { }
  };

  const getpertandingan = async (ss) => {
    try {
      const response = await axios.get(urlapi + "Mainrankteam?team=" + team);
      setMatch(response.data);
    } catch (error) { }
  };

  const getpemain = async () => {
    try {
      const response = await axios.get(urlapi + "Pemainrankteam?team=" + team);
      setPemain(response.data);
    } catch (error) { }
  };

  const getsession = async () => {
    try {
      const response = await axios.get(urlapi + "Session");
      setSession(response.data)
    } catch (error) {

    }
  }

  const handlesession = (session) => {
    setPagesession(session)

  }

  useEffect(() => {
    getdata();
    getpertandingan();
    getpemain();
    getsession()
  }, []);

  return (
    <div className="container mt-2">
      <br />
      <br />
      <img
        src="/img/bennerbaru.png"
        className="img-fluid"
        style={{ borderRadius: "10px" }}
      ></img>

      <br />
      <div className="card mt-2 border-0 " id="bggradient1">
        <div className="card-body">
          <h5 className="fw-bold text-white">Statistik team {team}</h5>
          <label className="text-white">
            Anda dapat melihat statistik team lawan
          </label>
        </div>
      </div>

      <div className="card mt-2">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-4 col-4 mt-2">
              <div className="card" id="bglistwinrate">
                <div className="card-body">
                  <label className="text-secondary fw-bold">Play</label>
                  <h5 className="fw-bold">{data.play}</h5>
                </div>
              </div>
            </div>

            <div className="col-sm-4 col-4 mt-2">
              <div className="card" id="bglistwinrate">
                <div className="card-body">
                  <label className="text-secondary fw-bold">Win</label>
                  <h5 className="fw-bold">{data.win}</h5>
                </div>
              </div>
            </div>

            <div className="col-sm-4 col-4 mt-2">
              <div className="card" id="bglistwinrate">
                <div className="card-body">
                  <label className="text-secondary fw-bold">Draw</label>
                  <h5 className="fw-bold">{data.draw}</h5>
                </div>
              </div>
            </div>

            <div className="col-sm-4 col-4 mt-2">
              <div className="card" id="bglistwinrate">
                <div className="card-body">
                  <label className="text-secondary fw-bold">Lost</label>
                  <h5 className="fw-bold">{data.lost}</h5>
                </div>
              </div>
            </div>

            <div className="col-sm-4 col-4 mt-2">
              <div className="card" id="bglistwinrate">
                <div className="card-body">
                  <label className="text-secondary fw-bold">Point</label>
                  <h5 className="fw-bold">{data.point}</h5>
                </div>
              </div>
            </div>

            <div className="col-sm-4 col-4 mt-2">
              <div className="card" id="bglistwinrate">
                <div className="card-body">
                  <label className="text-secondary fw-bold">Winrate</label>
                  <h5 className="fw-bold">{data.winrate} %</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card mt-2">
        <div className="card-body">
          <label className="fw-bold">
            {" "}
            <i className="fas fa-user"></i> Daftar pemain
          </label>
          <hr />
          {pemain == false || pemain == null ? (
            <>
              <p className="text-center text-secondary">
                Pemain untuk saat ini belum terseida
              </p>
            </>
          ) : (
            <>
              {" "}
              {pemain.slice(0, slicepemain).map((data, index) => {
                return (
                  <div key={index}>
                    <Link
                      to={"/profilpemainteam/" + data.id}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          {data.foto == "" ? (
                            <>
                              <img
                                src="http://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png"
                                style={{ height: "50px" }}
                                class="img-fluid"
                                alt="Responsive image"
                              ></img>
                            </>
                          ) : (
                            <>
                              <img
                                src={data.foto}
                                style={{ height: "70px" }}
                                class="img-fluid"
                                alt="Responsive image"
                              ></img>
                            </>
                          )}

                          {/* <small className="fw-bold">{data.no_punggung}</small> */}
                          {"   "}
                          <label
                            className="text-secondary"
                            style={{ fontSize: "12px" }}
                          >
                            {data.nama}
                          </label>
                          <br></br>
                          {/* <small className="text-secondary">{data.posisi}</small> */}
                          <small
                            className="text-secondary"
                            style={{ fontSize: "10px" }}
                          >
                            NIK : {data.nik} <div>{data.posisi}</div>
                          </small>
                        </div>

                        <div>
                          <small>
                            <i className="fas fa-angle-right"></i>
                          </small>
                        </div>
                      </div>
                    </Link>

                    <hr />
                  </div>
                );
              })}
              {slicepemain > 5 ? (
                <>
                  {" "}
                  <small
                    className="text-primary fw-bold"
                    onClick={() => setSlicepemain(5)}
                    style={{ cursor: "pointer" }}
                  >
                    Tutup semua <i className="fas fa-angle-right"></i>{" "}
                  </small>
                </>
              ) : (
                <>
                  {" "}
                  <small
                    className="text-primary fw-bold"
                    onClick={() => setSlicepemain(30)}
                    style={{ cursor: "pointer" }}
                  >
                    Lihat semuanya <i className="fas fa-angle-right"></i>{" "}
                  </small>
                </>
              )}
            </>
          )}
        </div>
      </div>

      <div className="card mt-2">
        <div className="card-body">
          <label className="fw-bold">
            <i className="fas fa-futbol"></i> History match
          </label>
          <hr />

          {session.map((ss, index) => {
            return (
              <div className="card mt-2 shadow">
                <div className="card-body">
                  <div class="d-flex justify-content-between" onClick={() => handlesession(ss.session)}>
                    <small className="text-secondary fw-bold"><i className="fas fa-futbol"></i> Season {ss.session}</small>
                    <small className="text-secondary fw-bold"> Lihat {pagesession == ss.session ? <><i className="fas fa-angle-down"></i></> : <><i className="fas fa-angle-right"></i></>}
                    </small>
                  </div>

                  <br />
                  {pagesession == ss.session ? <>
                    <div>
                      {match == null || match == false ? (
                        <>
                          <div className="text-secondary text-center">
                            Match rank team {team} belum tersedia
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {match.map((data, index) => {
                        return (
                          <>
                            {data.session == ss.session ? <> <div className="card mb-2" id="bglistpemain" key={index}>
                              <div className="card-body">
                                <div class="row text-white">
                                  <div className="col-sm-4 col-4">
                                    <center>
                                      <Complogoteamrank team={data.team} />
                                      <small className="fw-bold" style={{ fontSize: "8px" }}>
                                        {data.team.toUpperCase()}
                                      </small>
                                    </center>
                                  </div>
                                  <div className="col-sm-4 col-4">
                                    <small
                                      className="fw-bold mt-3 text-warning"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {" "}
                                      <center>
                                        <img
                                          src="/img/logomms.png"
                                          class="img-fluid mt-2"
                                          alt=""
                                          style={{ height: "30px" }}
                                        ></img>
                                        <br />
                                        {data.score_team} VS {data.score_lawan}{" "}
                                      </center>
                                    </small>
                                  </div>
                                  <div className="col-sm-4 col-4">
                                    <center>
                                      {" "}
                                      <Complogoteamrank team={data.team_lawan} />
                                      <small className="fw-bold" style={{ fontSize: "8px" }}>
                                        {data.team_lawan.toUpperCase()}
                                      </small>

                                    </center>
                                  </div>
                                </div>
                              </div>
                            </div></> : ''}
                          </>

                        );
                      })}
                    </div>
                  </> : ""}

                </div>
              </div>
            )
          })}


          <div className="card"></div>


        </div>
      </div>
    </div>
  );
}
