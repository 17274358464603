import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function Comprankteam() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [page, setPage] = useState(false);
  const [rank, setRank] = useState([]);
  const [namateam, setNamateam] = useState("");
  const [img, setImg] = useState("");

  const getRank = async () => {
    try {
      const response = await axios.get(
        urlapi + "Datateam?id_user=" + localStorage.getItem("id")
      );
      setNamateam(response.data.nama_team);
      setImg(response.data.logo_team);
    } catch (error) {}
  };

  const notifupdate = () => {
    toast.success("Rank team berhasil diupdate !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const Update = async () => {
    await axios
      .post(urlapi + "Ubahdatateam", {
        id: localStorage.getItem("id"),
        nama_team: namateam,
      })
      .then((response) => {
        notifupdate();
      })
      .catch((error) => {});
  };

  const Actionupdate = () => {
    Update();
  };

  useEffect(() => {
    getRank();
  }, []);

  return (
    <div>
      <div className="card mt-2">
        <div className="card-body">
          <div
            className="d-flex justify-content-between"
            onClick={() => setPage(!page)}
          >
            <p className="fw-bold text-primary">Rank team</p>
            <p className="fw-bold text-primary">
              {page == true ? (
                <>
                  {" "}
                  <i className="fas fa-angle-down"></i>
                </>
              ) : (
                <>
                  {" "}
                  <i className="fas fa-angle-right"></i>
                </>
              )}
            </p>
          </div>
          {page == true ? (
            <>
              {" "}
              <hr />
              {namateam == "" ? (
                <>
                  <center>
                    <small className="text-center">
                      Anda belum mempunyai team, Daftarkan team anda sekarang
                      juga
                    </small>
                    <br />
                    <Link
                      to={"/daftarteamrankteam"}
                      style={{ textDecoration: "none" }}
                    >
                      <small>Daftar rank team ?</small>
                    </Link>
                  </center>
                </>
              ) : (
                <>
                  <div className="form-group">
                    <label className="fw-bold">Nama team</label>
                    <input
                      className="form-control mt-2 text-secondary"
                      onChange={(e) => setNamateam(e.target.value)}
                      value={namateam}
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label className="fw-bold mb-3">Logo team</label>
                    <br />
                    <img
                      src={img}
                      className="img-fluid"
                      alt=""
                      style={{ height: "100px" }}
                    ></img>
                  </div>
                  <div className="container">
                    <button
                      className="btn w-100 mt-3 mb-3 rounded-pill"
                      onClick={() => Actionupdate()}
                      style={{ backgroundColor: "#2b2e5a", color: "white" }}
                    >
                      Updata rank team
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
