import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";

export default function Compkartu({ kode, kode_main, team }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [kartu, setKartu] = useState([]);

  const getkartu = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Karturankteam?kode_main=" +
          kode_main +
          "&&team=" +
          team +
          "&&kode=" +
          kode
      );
      setKartu(response.data);
      //   console.log(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getkartu();
  }, []);

  return (
    <div className="mt-2">
      {kartu.map((data, index) => {
        return (
          <div className="text-center text-white" key={index}>
            <div style={{ fontSize: "6px" }}>
              <i className="fas fa-user"></i> {data.nama}
              {data.kartu == "Merah" ? (
                <>
                  {" "}
                  <small className="text-danger" style={{ fontSize: "6px" }}>
                    <i className="fas fa-mobile"></i>
                  </small>
                  {"  "}
                  <small className="text-warning">
                    {data.menit == null ? <></> : <>({data.menit})</>}
                  </small>
                </>
              ) : (
                <>
                  {" "}
                  <small className="text-warning" style={{ fontSize: "6px" }}>
                    <i className="fas fa-mobile"></i>
                  </small>{" "}
                  <small className="text-warning">
                    {data.menit == "" ? <></> : <>({data.menit})</>}
                  </small>
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
