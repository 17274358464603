import React from "react";
import Comppassword from "../componenst/Lupapassword/Comppassword";

export default function Lupapassword() {
  return (
    <div className="login">
      <Comppassword />
    </div>
  );
}
