import React from "react";
import Navbar from "../componenst/Navbar";
import Compmain from "../componenst/Checkout/Compmain";

export default function Checkout() {
  return (
    <div>
      <Navbar judul="Checkout" aicon="true" />
      <Compmain />
    </div>
  );
}
