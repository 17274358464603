import React, { useEffect } from "react";
import Navbar from "../componenst/Navbar";
import Header from "../componenst/Header";
import CardMenu from "../componenst/CardMenu";
// import Content from "../componenst/Content";
import Vanue from "../componenst/Vanue";
import Comteam from "../componenst/Comteam";
import axios from "axios";
// import Footer from "../componenst/Footer";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Loadmenu from "../skeleton/Loadmenu";
import Benner from "../componenst/Benner";
import Notifikasi from "../componenst/Notifikasi";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Home() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [show, setShow] = useState(true);
  const [team, setTeam] = useState([]);
  const navigate = useNavigate();
  const updatestatusmain = async () => {
    await axios
      .post(urlapi + "Updatestatusmain", {
        id_user: localStorage.getItem("id"),
      })
      .then((response) => {
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const getTeam = async () => {
    const response = await axios.get(
      urlapi + "Datateam?id_user=" + localStorage.getItem("id")
    );
    setTeam(response.data);
    // console.log(response.data);

    try {
    } catch (error) {
      console.log(error.message);
    }
  };
  

  useEffect(() => {
    if (localStorage.getItem("id") == null) {
      navigate("/");
    }
    updatestatusmain();
    getTeam();
  }, []);

  return (
    <div>
      <Navbar judul="Medanminisoccer" aicon="false" />
      <Header />

      <CardMenu />

      <div className="" style={{ position: "relative", bottom: "70px" }}>
        <div className="card-body">
          {/* <div className="mb-3">
            <Link to="/booking">
              <img
                src="/img/benner1.png"
                className="img-fluid"
                alt="..."
                style={{ borderRadius: "10px" }}
              ></img>
            </Link>
          </div> */}

          <Alert variant="success" onClose={() => setShow(false)} dismissible>
            <Alert.Heading>
              <b> Hello {localStorage.getItem("nama")} ! </b>
            </Alert.Heading>
            <p>
              Selamat datang di medan mini soccer, temukan lawan bermain anda di
              medan mini soccer
            </p>
          </Alert>

          {localStorage.getItem('tamu') != null ? <>
            
          <Alert variant="warning" onClose={() => setShow(false)} dismissible>
            <Alert.Heading>
              <b> Anda dalam mode tamu ! </b>
            </Alert.Heading>
            <p>
                Aktivitas yang anda lakukan didalam aplikasi ini dengan mode tamu dibatasi & se waktu-waktu bisa saja hilang, silahkan buat akun medan mini soccer anda sekarang.
                <br />
               <Link to='/register' style={{ textDecoration : 'none' }}>Buat akun anda sekarang</Link>
            </p>
          </Alert>
          </> : <></>}
          <Link to='/booking' style={{ textDecoration : 'none' }}>
          <img src="/img/vc2.webp" className="img-fluid mb-3" alt="" style={{ borderRadius : '10px' }}></img>
        </Link>
          {team.status == 1 ? (
            <>
              <Alert
                variant="primary"
                onClose={() => setShow(false)}
                dismissible
              >
                <Alert.Heading>
                  <b>Hello {localStorage.getItem("nama")} !</b>
                </Alert.Heading>
                {team.pool == "" ? (
                  <p>Team anda untuk saat ini belum masuk di pool rank team</p>
                ) : (
                  <>
                    <label>
                      Team anda sudah masuk di pool rank team silahkan lihat
                      pool rank team anda
                    </label>
                    <Link
                      to="/datateamrankteam"
                      className=""
                      style={{ textDecoration: "none" }}
                    >
                      Lihat pool rank team ?
                    </Link>
                  </>
                )}
              </Alert>
            </>
          ) : (
            <></>
          )}

          <img
            src="/img/rl2.png"
            className="img-fluid mt-2"
            alt=""
            style={{ borderRadius: "10px" }}
          ></img>
          <hr />
          <Notifikasi />
          <Vanue />
          <hr />
          {/* <Comteam /> */}
          <Benner />
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
