import React, { useEffect, useState } from "react";
import Compheaderprofilpemain from "./Compheaderprofilpemain";
import axios from "axios";
import { useParams } from "react-router-dom";
import Complogoteamrank from "../Rankleague/Complogoteamrank";

export default function Compprofilpemain() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const [pemain, setPemain] = useState([]);
  const [statistik, setStatistik] = useState([]);
  const [goal, setGoal] = useState([]);
  const [match, setMatch] = useState([]);

  const getPemain = async () => {
    try {
      const response = await axios.get(
        urlapi + "Pemainrankteam?idpemain=" + id
      );
      setPemain(response.data);
    } catch (error) {}
  };

  const getStatistik = async () => {
    try {
      const response = await axios.get(urlapi + "Pointrankteam?idpemain=" + id);
      //   console.log(response.data);
      setStatistik(response.data);
    } catch (error) {}
  };

  const getGoal = async () => {
    try {
      const response = await axios.get(
        urlapi + "Topscorerankteam?idpemain=" + id
      );
      //   console.log(response.data);
      setGoal(response.data);
    } catch (error) {}
  };
  const getpertandingan = async () => {
    try {
      const response = await axios.get(urlapi + "Mainrankteam?idpemain=" + id);
      setMatch(response.data);
      //   console.log(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getPemain();
    getStatistik();
    getGoal();
    getpertandingan();
  }, []);

  return (
    <div>
      <Compheaderprofilpemain
        nama={pemain.nama}
        posisi={pemain.posisi}
        no={pemain.no_punggung}
        foto={pemain.foto}
      />
      <div className="container mt-5 mb-5">
        <div className="card fw-bold">
          <div className="card-header">
            <small className="fw-bold">Detail Pemain</small>
          </div>
          <div className="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <small className="text-secondary">Negara </small>
              </div>
              <div>
                <img
                  src="/img/bendera.png"
                  className="img-fluid"
                  alt=""
                  style={{ height: "20px", border: "1px solid" }}
                />{" "}
                <small className="text-secondary"> Indonesia</small>
              </div>
            </div>
            <hr />
            <div class="d-flex justify-content-between">
              <div>
                <small className="text-secondary">Nama</small>
              </div>
              <div>
                <small className="text-secondary">{pemain.nama}</small>
              </div>
            </div>
            <hr />
            <div class="d-flex justify-content-between">
              <div>
                <small className="text-secondary">Nickname</small>
              </div>
              <div>
                <small className="text-secondary">{pemain.nickname}</small>
              </div>
            </div>
            <hr />
            <div class="d-flex justify-content-between">
              <div>
                <small className="text-secondary">Posisi</small>
              </div>
              <div>
                <small className="text-secondary">{pemain.posisi}</small>
              </div>
            </div>
            <hr />
            <div class="d-flex justify-content-between">
              <div>
                <small className="text-secondary">Team</small>
              </div>
              <div>
                <small className="text-secondary">{pemain.team}</small>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-header">
            <small className="fw-bold">Statistik Pemain</small>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6 col-6">
                <div className="card" id="bglistwinrate">
                  <div className="card-body">
                    <h5 className="fw-bold text-secondary">Play</h5>
                    <p className="fw-bold text-danger">{statistik.play}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-6">
                <div className="card" id="bglistwinrate">
                  <div className="card-body">
                    <h5 className="fw-bold text-secondary">Win</h5>
                    <p className="fw-bold text-danger">{statistik.win}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-6 mt-1">
                <div className="card" id="bglistwinrate">
                  <div className="card-body">
                    <h5 className="fw-bold text-secondary">Lost</h5>
                    <p className="fw-bold text-danger">{statistik.lost}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-6 mt-1">
                <div className="card" id="bglistwinrate">
                  <div className="card-body">
                    <h5 className="fw-bold text-secondary">Draw</h5>
                    <p className="fw-bold text-danger">{statistik.draw}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-6 mt-1">
                <div className="card" id="bglistwinrate">
                  <div className="card-body">
                    <h5 className="fw-bold text-secondary">
                      {pemain.posisi == "Penjaga gawang" ? "Kebobolan" : "Goal"}
                    </h5>
                    <p className="fw-bold text-danger">{goal.jml}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-header">
            <small className="fw-bold"> Match Team</small>
          </div>
          <div className="card-body">
            {match == null || match == false ? (
              <>
                <div className="text-secondary text-center">
                  Match team belum tersedia
                </div>
              </>
            ) : (
              ""
            )}
            {match.map((data, index) => {
              return (
                <div className="card mt-2" id="bglistpemain" key={index}>
                  <div className="card-body">
                    <div class="row">
                      <div
                        className="col-sm-4 col-4 fw-bold text-white"
                        style={{ fontSize: "12px" }}
                      >
                        {data.team}
                      </div>
                      <div className="text-center fw-bold text-warning col-sm-4 col-4">
                        <img
                          src="/img/logomms.png"
                          class="img-fluid"
                          style={{ height: "20px" }}
                        ></img>
                        <br />
                        {data.score_team} VS {data.score_lawan}{" "}
                      </div>
                      <div
                        className="text col-sm-4 col-4 fw-bold text-white"
                        style={{ textAlign: "right", fontSize: "12px" }}
                      >
                        {data.team_lawan}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
