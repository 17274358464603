import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import Compusers from "./Compusers";
import { Link } from "react-router-dom";
import Compcardgoalassist from "./Compcardgoalassist";
import Compgoalasist from "./Compgoalasist";
import Compgoal from "../Comphasilranksolo/Compgoal";

export default function Compmain() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [history, setHistory] = useState([]);

  const getData = async () => {
    try {
      const response = await axios.get(
        urlapi + "Mainmemberkarir?id_user=" + localStorage.getItem("id")
      );
      setHistory(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const resvonsive = {
    0: {
      items: 1.5,
    },
    600: {
      items: 1.5,
    },
    1000: {
      items: 1.5,
    },
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className=" mt-5">
        <br></br>
        <div className="container">
          <OwlCarousel
            className="owl-theme"
            loop
            margin={4}
            nav={false}
            responsive={resvonsive}
            dotsEach
            autoplay
          >
            <img
              src="/img/bennerbooking.png"
              className="img-fluid"
              alt=""
              style={{ borderRadius: "5px" }}
            ></img>
            <img
              src="/img/dp.png"
              className="img-fluid"
              alt=""
              style={{ borderRadius: "5px" }}
            ></img>
          </OwlCarousel>
        </div>
        <div className="container">
          <div className="card shadow my-3" style={{ border: "none" }}>
            <div className="card-body">
              <h5 className="fw-bold" style={{ color: "#2b2e5a" }}>
                History rank solo anda
              </h5>
              Hello {localStorage.getItem("nama")}, ini adalah history rank solo
              anda
            </div>
          </div>
        </div>

        <div
          className={
            history == null || history == false ? "card mb-4 shadow" : "d-none"
          }
          style={{ border: "none" }}
        >
          <div className="card-body">
            <div>
              <center>
                <img
                  src="/img/bin.png"
                  className="img-fluid"
                  alt=""
                  style={{
                    borderRadius: "5px",
                    height: "100px",
                  }}
                ></img>
                <h4 className="fw-bold text-success mt-2">
                  Hello {localStorage.getItem("nama")}
                </h4>
                <p className="text-secondary" style={{ marginBottom: "200px" }}>
                  Untuk saat ini hasil history rank solo anda belum tersedia
                </p>
              </center>
            </div>
          </div>
        </div>

        <div className="container mb-5">
          {history.map((data, index) => {
            return (
              <div key={index}>
                <div className="card" id="bgcardliga">
                  <div className="card-body">
                    <div
                      className="card"
                      style={{
                        border: "3px solid white",
                        backgroundColor: "#2b2e5a",
                      }}
                    >
                      <div className="row container my-4">
                        <div className="col-sm-5 col-5">
                          <center>
                            <img
                              src={
                                data.team == "Merah"
                                  ? "/img/merah.png"
                                  : data.team == "Kuning"
                                  ? "/img/kuning.png"
                                  : data.team == "Hitam"
                                  ? "/img/hitam.png"
                                  : "/img/putih.png"
                              }
                              className="img-fluid"
                              alt=""
                              style={{
                                borderRadius: "5px",
                                height: "80px",
                              }}
                            ></img>
                            <Compusers />
                          </center>
                          <Compgoalasist
                            kodemain={data.kode_main}
                            team={data.team}
                            lawan={data.team_lawan}
                          />
                        </div>
                        <div className="col-sm-2 col-2">
                          <Link
                            to={
                              "/pemainmemberkarir/" +
                              data.kode_main +
                              "/" +
                              data.team +
                              "/" +
                              data.team_lawan
                            }
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <center>
                              <img
                                src="./img/logomms.png"
                                className="img-fluid"
                                style={{ height: "30px" }}
                              ></img>
                              <p
                                className="fw-bold text-white"
                                style={{ fontSize: "10px" }}
                              >
                                {data.score_team}{" "}
                                <lalbel className="text-white"> - </lalbel>{" "}
                                {data.score_lawan}
                              </p>
                              <img
                                src="./img/play.png"
                                className="img-fluid"
                                style={{ height: "30px" }}
                              ></img>
                            </center>
                          </Link>
                        </div>
                        <div className="col-sm-5 col-5">
                          <center>
                            <img
                              src={
                                data.team_lawan == "Merah"
                                  ? "/img/merah.png"
                                  : data.team_lawan == "Kuning"
                                  ? "/img/kuning.png"
                                  : data.team_lawan == "Hitam"
                                  ? "/img/hitam.png"
                                  : "/img/putih.png"
                              }
                              className="img-fluid"
                              alt=""
                              style={{
                                borderRadius: "5px",
                                height: "80px",
                              }}
                            ></img>
                            <Compusers />
                          </center>

                          <Compgoalasist
                            kodemain={data.kode_main}
                            team={data.team_lawan}
                            lawan={data.team}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
