import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

export default function Jambookingspesialranksolo({ kode_main, lapangan, id }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState([]);
  const [sesi, setSesi] = useState("");
  const [harga, setHarga] = useState("");
  const [jambooking, setJambooking] = useState([]);
  const [booking, setBooking] = useState([]);

  const getData = async () => {
    try {
      const response = await axios.get(
        urlapi + "Getjambookingspesialsolo?kode_main=" + kode_main
      );
      //   console.log(response.data.data);
      setData(response.data.data);
      setSesi(response.data.sesi);
    } catch (error) {}
  };

  const getJam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Getlistjammainbookingspesial2?kode_main=" + kode_main
      );
      setJambooking(response.data);
      // console.log(response.data);
    } catch (error) {}
  };

  const cekBooking = async () => {
    try {
      const response = await axios.get(
        urlapi + "Cekbookingspesial?kode_main=" + kode_main
      );
      // console.log(response.data);
      setBooking(response.data);
    } catch (error) {}
  };

  const notify = () =>
    toast.warning("Maaf. Jam booking sudah digunakan", {
      position: toast.POSITION.TOP_CENTER,
    });

  const handlealert = () => {
    if (booking.status_pembayaran == 200) {
      notify();
      <ToastContainer />;
    }
  };

  useEffect(() => {
    getData();
    getJam();
    cekBooking();
  }, []);
  return (
    <div>
      {data == null ? (
        <></>
      ) : (
        <>
          <div
            onClick={() => handlealert()}
            className={
              id == jambooking.id ? "card mt-2 border-primary" : "card mt-2"
            }
          >
            <div className="card-body">
              <div
                className="d-flex justify-content-between fw-bold"
                style={{ fontSize: "14px" }}
              >
                <small className="fw-bold">{lapangan}</small>
                <small>Booking spesial</small>
              </div>

              <br />
              <small className="fw-bold">
                {" "}
                <i className="fas fa-calendar-days"></i> {data.tgl_main}
              </small>
              <br />
              <small className="fw-bold text-danger">
                {booking.status_pembayaran == "200" ? <>Booked</> : ""}
              </small>
              <small className="fw-bold text-danger">
                {booking.status_pembayaran == "201" &&
                localStorage.getItem("id") == booking.iduser ? (
                  <>Menuggu Approve</>
                ) : (
                  ""
                )}
              </small>
              <hr />
              <div className="d-flex justify-content-between fw-bold">
                <label className="fw-bold">{jambooking.jam}</label>
                <label className="fw-bold">Rp. {jambooking.harga}</label>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
