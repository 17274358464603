import React, { useEffect, useState } from "react";
import Navbar from "../componenst/Navbar";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import Complogoteamrank from "../componenst/Rankleague/Complogoteamrank";

export default function Lainya() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [session, setSession] = useState(1);
  const [team, setTeam] = useState([]);

  const getTeam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Teamterbaik?session=" + session
      );
      setTeam(response.data);
    } catch (error) {}
  };

  // const handlesession = () => {};

  const resvonsive = {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 2,
    },
  };

  useEffect(() => {
    getTeam();
  }, []);

  return (
    <div>
      <Navbar judul="Lainya" aicon="true" />
      <div>
        <div className="card" style={{ borderRadius: "0px", border: "0px" }}>
          <div className="card-body">
            <br />
            <br />
            <div
              className="card text-white"
              id="bggradient1"
              style={{ border: "0px" }}
            >
              <div className="card-body">
                <h5 className="fw-bold">History Rank Team</h5>
                <div>Anda dapat melihat history rank team</div>
              </div>
            </div>

            <div className="card mt-2">
              <div className="card-body">
                <div
                  className="d-flex justify-content-between"
                  style={{ color: "#2b2e5a" }}
                >
                  <small className="fw-bold">5 Team Terbaik</small>
                  <small>
                    <i className="fas fa-star"></i>
                  </small>
                </div>
                <div className="mt-4">
                  <div className="row fw-bold">
                    <div className="col-sm-6 col-6 ">
                      <div className="card text-secondary">
                        <div className="card-body">
                          <small>
                            SEASION 1 <i className="fas fa-trophy"></i>
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-6">
                      <div className="card text-secondary">
                        <div className="card-body">
                          <small>
                            SEASION 2 <i className="fas fa-trophy"></i>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive mt-3">
                    <table className="table">
                      <thead>
                        <tr style={{ fontSize: "10px" }}>
                          <th scope="col">No</th>
                          <th scope="col">
                            <center>Nama</center>
                          </th>
                          <th
                            className="text-warning text-center"
                            scope="col"
                            style={{ width: "50px" }}
                          >
                            P
                          </th>
                          <th
                            className="text-success text-center"
                            scope="col"
                            style={{ width: "50px" }}
                          >
                            W
                          </th>
                          <th
                            className="text-info text-center"
                            scope="col"
                            style={{ width: "50px" }}
                          >
                            D
                          </th>
                          <th
                            className="text-danger text-center"
                            scope="col"
                            style={{ width: "50px" }}
                          >
                            L
                          </th>

                          <th
                            className="text-danger text-center"
                            scope="col"
                            style={{ width: "50px" }}
                          >
                            GF
                          </th>
                          <th
                            className="text-info text-center"
                            scope="col"
                            style={{ width: "50px" }}
                          >
                            GA
                          </th>
                          <th
                            className="text-warning text-center"
                            scope="col"
                            style={{ width: "50px" }}
                          >
                            GD
                          </th>
                          <th
                            className="text-primary text-center"
                            scope="col"
                            style={{ width: "50px" }}
                          >
                            Pts
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {team.map((data, index) => {
                          return (
                            <tr key={index} style={{ fontSize: "10px" }}>
                              <th scope="row">{index + 1}</th>
                              <td>
                                <center>
                                  <Complogoteamrank team={data.team} />
                                  {data.team}
                                </center>
                              </td>
                              {/* <td>{data.persen.substr(0, 5)}%</td> */}
                              <td className="table-warning text-center fw-bold">
                                {data.play}
                              </td>
                              <td className="table-success text-center fw-bold">
                                {data.win}
                              </td>
                              <td className="table-info text-center fw-bold">
                                {data.draw}
                              </td>
                              <td className="table-danger text-center fw-bold">
                                {data.lost}
                              </td>
                              <td className="table-info text-center fw-bold">
                                {data.goal}
                              </td>
                              <td className="table-primary text-center fw-bold">
                                {data.kebobolan}
                              </td>
                              <td className="table-warning text-center fw-bold">
                                {data.selisih_goal}
                              </td>
                              <td className="table-danger text-center fw-bold">
                                {data.point}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  {/* <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={4}
                    nav={false}
                    responsive={resvonsive}
                    dotsEach={true}
                    dots={true}
                    autoplay={true}
                  >
                    <div>
                      <div
                        style={{
                          position: "relative",
                          bottom: "-50",
                        }}
                      >
                        ererere
                      </div>
                      <img
                        src="img/cardrankteam.png"
                        style={{ borderRadius: "10px" }}
                        className="img-fluid"
                        alt="Responsive image"
                      ></img>
                    </div>
                  </OwlCarousel> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
