import React from "react";
import Navbar from "../componenst/Navbar";
import Compmaindaftarteam from "../componenst/Daftarteam/Compmaindaftarteam";
import Comprankteam from "../componenst/Daftarteam/Comprankteam";

export default function Daftarteamrankteam() {
  return (
    <div>
      <Navbar judul="Daftar team anda" aicon="true" />
      {/* <Compmaindaftarteam /> */}

      <Comprankteam />
    </div>
  );
}
