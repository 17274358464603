import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Compbookingrankteam({ limit, gambar }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [booking, setBooking] = useState([]);

  const getdata = async () => {
    if (limit == 4) {
      try {
        const response = await axios.get(
          urlapi +
            "Bookingrankteam?iduser=" +
            localStorage.getItem("id") +
            "&&limit=4"
        );
        //   console.log(response.data);
        setBooking(response.data);
      } catch (error) {}
    } else {
      try {
        const response = await axios.get(
          urlapi +
            "Bookingrankteam?iduser=" +
            localStorage.getItem("id") +
            "&&limit=all"
        );
        //   console.log(response.data);
        setBooking(response.data);
      } catch (error) {}
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <div>
      {booking == false && limit !== "all" ? (
        <>
          <center>
            {gambar == "true" ? (
              <>
                <img
                  src="img/nulldatabookingrankteam.svg"
                  style={{ height: "200px" }}
                  className="img-fluid mt-5"
                  alt="Responsive image"
                ></img>

                <div className="mb-5 mt-3">
                  <small
                    className="text-center text-secondary mb-5"
                    style={{ fontSize: "10px" }}
                  >
                    <i className="fas fa-trash"></i> Data booking rank team anda
                    masih kosong
                  </small>
                </div>
              </>
            ) : (
              <>
                <div className="">
                  <small
                    className="text-center text-secondary"
                    style={{ fontSize: "10px" }}
                  >
                    <i className="fas fa-trash"></i> Data booking rank team anda
                    masih kosong
                  </small>
                </div>
              </>
            )}
          </center>
        </>
      ) : (
        <></>
      )}
      {booking.map((data, index) => {
        return (
          <div className="card mt-2 shadow border-0" key={index}>
            <div className="card-body">
              <div
                class="d-flex justify-content-between mt-2"
                style={{ fontSize: "11px" }}
              >
                <small className="fw-bold">{data.lapangan}</small>
                {data.status_pembayaran == 200 ? (
                  <>
                    <small className="fw-bold text-success">Disetujui</small>
                  </>
                ) : data.status_pembayaran == 201 ? (
                  <>
                    <small className="fw-bold text-warning">Menunggu</small>
                  </>
                ) : (
                  (data.status_pembayaran = 400 ? (
                    <>
                      <small className="fw-bold text-danger">Ditolak</small>
                    </>
                  ) : (
                    ""
                  ))
                )}
              </div>

              <div
                class="d-flex justify-content-between mt-2"
                style={{ fontSize: "10px" }}
              >
                <div>
                  <label className="fw-bold">Tanggal main</label>
                  <br></br>
                  {data.tgl_main}
                </div>
                <div style={{ textAlign: "right" }}>
                  <label className="fw-bold">Jam main</label>
                  <br></br>
                  {data.jam_mulai} WIB
                </div>
              </div>
              <hr />
              <div
                class="d-flex justify-content-between mt-2 fw-bold"
                style={{ fontSize: "14px" }}
              >
                <small>Match</small>
                {data.lawan == "" || data.status_pembayaran == 201 ? (
                  <>
                    {" "}
                    <small>Lawan belum tersedia</small>
                  </>
                ) : (
                  <>
                    {" "}
                    <small style={{ textAlign: "right" }}>
                      {data.team} vs {data.lawan}
                      <br />
                      <Link
                        to={"/statistikteam/" + data.lawan}
                        style={{ textDecoration: "none" }}
                      >
                        <i className="fas fa-user"></i> Lihat statistik lawan{" "}
                      </Link>
                    </small>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
      {limit == "all" && booking == false ? (
        <>
          <div className="my-5">
            <center>
              <img
                src="img/trash.png"
                className="img-fluid"
                alt="..."
                style={{ height: "200px" }}
              ></img>
            </center>
            <center>
              <label className="text-center fw-bold">
                <i className="fas fa-trash"></i> Data booking rank team anda
                untuk saat ini masih kosong
              </label>
            </center>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
