import React from "react";
import Navbar from "../componenst/Navbar";
import Compmainrankleague from "../componenst/Rankleague/Compmainrankleague";

export default function Rankleague() {
  return (
    <div>
      <Navbar judul="Rank league" aicon="true" />
      <Compmainrankleague />
    </div>
  );
}
