import React from "react";
import Navbar from "../componenst/Navbar";
import Compmain from "../componenst/Checkoutrankteam/Compmain";

export default function Checkoutrankteam() {
  return (
    <div>
      <Navbar judul="Checkout rank team" aicon="true" />
      <Compmain />
    </div>
  );
}
