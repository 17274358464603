import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Compjmlpemain({ kode_main, jml }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [jmlpemain, setJmlpemain] = useState(0);

  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi + "Jumlahpemain?kode=" + kode_main + "&&jml=" + jml
      );
      //   console.log(response.data);
      setJmlpemain(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getdata();
  }, []);
  return (
    <div>
      {" "}
      <i className="fas fa-user"></i> {jmlpemain}
    </div>
  );
}
