import React from "react";
import Navbar from "../componenst/Navbar";
import Compmain from "../componenst/Hasilmemberkarir/Compmain";

export default function Hasilmemberkarir() {
  return (
    <div>
      <Navbar
        judul="Hasil rank solo
      "
        aicon="true"
      />
      <Compmain />
    </div>
  );
}
