import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Jammain from './Jammain';
import { ToastContainer, toast } from "react-toastify";
import Bookingvoucher from './Bookingvoucher';


export default function Listvoucher() {
    const urlapi = process.env.REACT_APP_BASE_URL;
    const [voucher, setVoucher] = useState([])

   
    const getdata = async () => {
        try {
            const response = await axios.get(urlapi + 'Voucher?iduser=' + localStorage.getItem('id'))
            setVoucher(response.data)
        } catch (error) {
            // console.log(error.message);
            
        }
    }


  useEffect(() => {
    getdata();
  }, []);
    
  return (
      <div>
          <div className="d-flex justify-content-between mb-3">
              <label className='fw-bold' style={{ color : '#2452B' }}>Data voucher anda</label>
              <label style={{ color: '#2452B' }}><i className="fas fa-ticket"></i></label>
          </div>

          {voucher == false || voucher == '' ?
              <>
                  <div className='' style={{ height: '500px' }}>
                      <div className='text-center mt-5'>
                          <img src="img/vc33.webp" className="img-fluid" alt="Responsive image" />
                           <small className='fw-bold' style={{color : ''}}>Untuk saat ini voucher anda belum tersedia, tingkatkan booking anda untuk mendapatkan voucher medan mini soccer</small>
                      </div>
                     
                  </div>
              </>
          
        
        : <></>}

          {voucher.map((list, index) => {
              return (
                  <div key={index}>
                      <div className='card mt-2' style={{ borderColor : '#20254B' }}>
                          <div className='card-body'>
                              <div className="d-flex justify-content-between">
                                  <h5 className='fw-bold' style={{ color: '#20254B' }}>
                                      <i className='fas fa-ticket'></i> {index+1}</h5>
                                   <h5 className='fw-bold' style={{ color : '#20254B' }}>  {list.kode_voucher}</h5>
                              </div>
                              <hr />
                              <div className="d-flex justify-content-between fw-bold">
                                  <small><i className='fas fa-calendar-day'></i> Tanggal diterima : </small>
                                  <small>{list.tgl_terima}</small>

                              </div>
                              <div className="d-flex justify-content-between mt-2 fw-bold">
                                  <small><i className='fas fa-futbol'></i> Lapangan :</small>
                                  <small>Lapangan { list.lapangan }</small>
                              </div>
                              
                            <div className="d-flex justify-content-between mt-2 fw-bold">
                                  <small><i className='fas fa-clock'></i> Waktu :</small>
                                  <small>2 Jam</small>
                              </div>
                                <hr />
                             
                                       
                                 <Jammain kode_voucher={list.kode_voucher} idlap={list.id_lap} lapangan={list.lapangan} />
                      

                               <Bookingvoucher kode_voucher={list.kode_voucher} />
                          </div>
                      </div>
                  </div>
              )
          })}

          <ToastContainer />
    </div>
  )
}
