import React from "react";
import Navbar from "../componenst/Navbar";
import Compeditpemain from "../componenst/Membarkarirteam/Compeditpemain";

export default function Editpemain() {
  return (
    <div>
      <Navbar judul="Edit pemain" aicon="true" />
      <Compeditpemain />
    </div>
  );
}
