import React from "react";
import Navbar from "../componenst/Navbar";
import Comppemain from "../componenst/Membarkarirteam/Comppemain";

export default function Pemainteam() {
  return (
    <div>
      <Navbar judul="Pemain team anda" aicon="true" />
      <Comppemain />
    </div>
  );
}
