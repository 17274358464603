import axios from "axios";
import React, { useEffect, useState } from "react";
import Compnama from "../Memberkarir/Compnama";

export default function Compkartu({ kode_main, team }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [kartu, setKartu] = useState([]);
  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi + "Karturanksolo?kode_main=" + kode_main + "&&team=" + team
      );
      setKartu(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <div>
      {kartu.map((data, index) => {
        return (
          <div key={index}>
            <label className="text-white" style={{ fontSize: "6px" }}>
              <i
                className={
                  data.kartu == "Kuning"
                    ? "fas fa-mobile-button text-warning"
                    : "fas fa-mobile-button text-danger"
                }
              ></i>{" "}
              <Compnama iduser={data.id_user} /> ({data.menit})
            </label>
          </div>
        );
      })}
    </div>
  );
}
