import React from "react";
import Compmain from "../componenst/Membarkarirteam/Compmain";
import Navbar from "../componenst/Navbar";

export default function Memberkarirteam() {
  return (
    <div>
      <Navbar judul="Rank team" aicon="true" />
      <Compmain />
    </div>
  );
}
