import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Compmatchrankteam2 from "../Comprankkompetition/Compmatchrankteam";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export default function Compmatchrankteam() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [gamerankteam, setGamerankteam] = useState([]);

  const getGame = async () => {
    try {
      const response = await axios.get(urlapi + "Fungamerankteam");

      setGamerankteam(response.data);
      // console.log(response.data);
    } catch (error) {
      setGamerankteam("");
    }
  };

  const resvonsive = {
    0: {
      items: 2,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 2,
    },
  };

  useEffect(() => {
    getGame();
  }, []);

  return (
    <div>
      <div className="">
        {gamerankteam == false ? (
          <></>
        ) : (
          <>
            {gamerankteam.map((data, index) => {
              return (
                <div key={index}>
                  <Compmatchrankteam2
                    kode_main={data.kode_main}
                    lapangan={data.lapangan}
                    tglmain={data.tgl_main}
                    jammain={data.jam_main}
                    id={index + 1}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
