import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Compwinrate({ id_user }) {
  const urlapi = process.env.REACT_APP_BASE_URL;

  const [win, setWin] = useState(0);
  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi + "Jmlwinratememberkarir?id_user=" + id_user
      );
      // console.log(response.data);
      setWin(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getdata();
  }, []);
  return (
    <div>
      <div className="container my-5">
        <center>
          <img
            src="/img/winrate.png"
            className="img-fluid"
            alt="Responsive image"
            style={{ height: "200px" }}
          ></img>
          <h5 className="mt-3 fw-bold">Winrate yang di proleh sebanyak</h5>
          <h1 className="fw-bold text-success">{win.persen} %</h1>
        </center>
      </div>
    </div>
  );
}
